import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trim } from 'ramda';
import { DataTableConstants } from '../data-table.constants';
import { MeetConstants } from '../../../meet/meet.constants';
import moment from 'moment-timezone';

@Component({
  selector: 'data-table-cell',
  templateUrl: './data-table-cell.component.html'
})
export class DataTableCellComponent {
  @Input() public row;
  @Input() public col;
  @Input() public isMobile: boolean = false;
  @Input() public mobileTitle: string = '';
  @Input() public mobileTitleBold: boolean = false;
  @Input() public useFullMobileTable: boolean = false;
  @Input() public disableDeleteIcon: boolean;
  @Input() public shouldDisableButton: boolean = false;
  @Input() public f2sToolTipText: string = DataTableConstants.f2SToolTipText;
  @Input() public isEnableSignatureMembership: boolean = false;
  @Input() public shouldDisplayIcon: boolean = true;
  @Input() public shouldDisplayLabel: boolean = true;
  @Input() public shouldDisplayMemberText: boolean = false;

  @Output() public onDeleteClick = new EventEmitter<any>();
  @Output() public onButtonClick = new EventEmitter<any>();
  @Output() public onExternalLinkClick = new EventEmitter<any>();
  @Output() public onOrderNumberClicked = new EventEmitter<any>();
  @Output() public onViewNotesClick = new EventEmitter<any>();

  public deleteIconUrl = MeetConstants.deleteIcon;
  public show: boolean = false;

  public showHelpText() {
    this.show = true;
  }

  public hideHelpText() {
    this.show = false;
  }

  public getData(): string {
    return this.row[this.col.prop] ? this.row[this.col.prop] : '';
  }

  public getDataRedFlagQuarterlyLimit(){
    return this.row['redFlaggedQuarterlyLimit'] ? this.row['redFlaggedQuarterlyLimit'] : '';
  }

  public getDate() {
    return moment.unix(this.row[this.col.prop] ? this.row[this.col.prop] : 0);
  }

  public showMemberProfile(): boolean {
    return this.row && this.row.showCustomerProfile;
  }

  public getShortenedData(maxLength): string {
    let data = this.row[this.col.prop] ? this.row[this.col.prop] : '';
    data = data && data.length > maxLength ? data.substring(0, maxLength) + '...' : data;
    return data;
  }

  public getDataArrayToList() {
    let data = this.row[this.col.prop] ? this.row[this.col.prop] : '';
    let display: string ='';
    if (data == null || data == '') {
      return display;
    }
    data.forEach((item: string) => {
      display = display + ', ' + item;
    });
    return display.slice(2);
  }

  public getLocTimezone(): string {
    return this.col.showTimezone ? this.col.showTimezone : null;
  }

  public getText(): string {
    return this.col.text ? this.col.text : '';
  }

  public getConcatData(): string {
    return this.col.rowConcatProp ? this.row[this.col.rowConcatProp] : '';
  }

  public getPreventDeletion(): boolean {
    return this.row[this.col.preventDeletion];
  }

  public getIcon(): boolean {
    if (!this.col.icon) {
      return false;
    }
    return this.col.iconProp ? this.row[this.col.iconProp] : true;
  }

  public getNumber(): number {
    return this.row[this.col.prop] ? this.row[this.col.prop] : 0;
  }

  public hasSecondStreetAddress(str) {
    return str && trim(str);
  }

  public trimData() {
    return trim(this.getData());
  }

  public getDisplayCfaOne() {
    return this.col.displayCfaOne ? this.col.displayCfaOne : this.isEnableSignatureMembership;
  }

  public getDisplayIcon() {
    return this.row[this.col.displayIconProp];
  }

  public getTruncateLimit() {
    const mobileLength = this.col.mobileTitle ? 30 - this.col.mobileTitle.length : 30;
    return DataTableConstants.mobileTitle === this.col.mobile ? mobileLength : 40;
  }

  public deleteClicked() {
    this.onDeleteClick.emit();
  }

  public buttonClicked() {
    this.onButtonClick.emit();
  }

  public externalLinkClicked() {
    this.onExternalLinkClick.emit();
  }

  public getDisplayForgot2Scan(): boolean {
    return this.row[this.col.prop];
  }

  public getStatusIcon() {
    if (this.row[this.col.prop] === 'Completed') {
      return DataTableConstants.statusIcon.completed;
    } else if (this.row[this.col.prop] === 'Scheduled') {
      return DataTableConstants.statusIcon.pending;
    } else if (this.row[this.col.prop] === 'Failed') {
      return DataTableConstants.statusIcon.failure;
    }
  }

  public getStatusIconInfo() {
    if (this.row[this.col.prop] === 'Completed') {
      return DataTableConstants.statusIconInfo.completed;
    } else if (this.row[this.col.prop] === 'Scheduled') {
      return DataTableConstants.statusIconInfo.pending;
    } else if (this.row[this.col.prop] === 'Failed') {
      return DataTableConstants.statusIconInfo.failure;
    }
  }

  public orderNumberClicked() {
    this.onOrderNumberClicked.emit();
  }

  public getOrderNumberSelectable() {
    return !!this.row[this.col.isSelectableAttrName];
  }

  public getRefundReasons(): string {
    if (this.row[this.col.prop] && this.row[this.col.prop].length > 0) {
      return this.row[this.col.prop].join('<br><br>');
    }
  }

  public getViewNotesData() {
    return this.row[this.col.prop] && this.row[this.col.prop].length > 0;
  }

  public viewNotesClicked() {
    this.onViewNotesClick.emit();
  }

  public getViewNotesLabel() {
    if (this.row) {
      if (this.row.isNoteOpen) {
        return 'Hide Notes';
      } else {
        return 'View Notes';
      }
    } else {
      return 'N/A';
    }
  }
}
