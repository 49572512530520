import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  LOAD_DISCOVER_TILES:                    type('[Discover] Load tiles'),
  LOAD_DISCOVER_TILES_SUCCESS:            type('[Discover] Load tiles success'),
  LOAD_DISCOVER_TILES_ERROR:              type('[Discover] Load tiles error'),
  LOAD_TILE_DATA:                         type('[Discover] Load single tile data'),
  LOAD_TILE_DATA_SUCCESS:                 type('[Discover] Load single tile success'),
  LOAD_TILE_DATA_ERROR:                   type('[Discover] Load single tile error'),
  LOAD_TILE_FILTERS_ERROR:                type('[Discover] Load discover filters error'),
  FAILED_CAMPAIGN_MARK_RESOLVED:          type('[Discover] Failed Campaign Mark Resolved'),
  FAILED_CAMPAIGN_MARK_RESOLVED_SUCCESS:  type('[Discover] Failed Campaign Mark Resolved Success'),
  FAILED_CAMPAIGN_MARK_RESOLVED_FAILURE:  type('[Discover] Failed Campaign Mark Resolved Failure')
};

export class LoadDiscoverTilesAction implements Action {
  public type = ActionTypes.LOAD_DISCOVER_TILES;
  constructor(public payload: any = '') { }
}

export class LoadDiscoverTilesSuccessAction implements Action {
  public type = ActionTypes.LOAD_DISCOVER_TILES_SUCCESS;
  constructor(public payload: any, public locationNumber: string) { }
}

export class LoadDiscoverTilesErrorAction implements Action {
  public type = ActionTypes.LOAD_DISCOVER_TILES_ERROR;
  constructor(public payload: any) {}
}

export class LoadTileDataAction implements Action {
  public type = ActionTypes.LOAD_TILE_DATA;
  constructor(public payload: any = '') { }
}

export class LoadTileDataSuccessAction implements Action {
  public type = ActionTypes.LOAD_TILE_DATA_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadTileDataErrorAction implements Action {
  public type = ActionTypes.LOAD_TILE_DATA_ERROR;
  constructor(public payload: any) { }
}

export class LoadTileFiltersErrorAction implements Action {
  public type = ActionTypes.LOAD_TILE_FILTERS_ERROR;
  constructor(public payload: any) { }
}

export class FailedCampaignMarkResolved implements Action {
  public type = ActionTypes.FAILED_CAMPAIGN_MARK_RESOLVED;
  constructor(public payload: any) { }
}

export class FailedCampaignMarkResolvedSuccess implements Action {
  public type = ActionTypes.FAILED_CAMPAIGN_MARK_RESOLVED_SUCCESS;
  constructor(public payload: any) { }
}

export class FailedCampaignMarkResolvedFailure implements Action {
  public type = ActionTypes.FAILED_CAMPAIGN_MARK_RESOLVED_FAILURE;
  constructor(public payload: any) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
  = LoadDiscoverTilesAction
  | LoadDiscoverTilesSuccessAction
  | LoadDiscoverTilesErrorAction
  | LoadTileDataAction
  | LoadTileDataSuccessAction
  | LoadTileDataErrorAction
  | LoadTileFiltersErrorAction
  | FailedCampaignMarkResolved
  | FailedCampaignMarkResolvedSuccess
  | FailedCampaignMarkResolvedFailure;
