import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Actions, Effect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import * as fromRoot from '../reducers';
import * as engagement from '../actions/engagement';
import * as engage from '../actions/engage';
import { UserService } from '../core/user';
import { AnalyticsService } from '../core/analytics';
import { DiscoverService } from '../discover/discover.service';
import { ErrorHandlerService } from '../core/error-handler';
import { DiscoverConstants } from '../discover/discover.constants';
import { CancellationDialogComponent } from '../discover/cancellation-dialog/cancellation-dialog.component';
import { SendConfirmationDialogComponent } from '../shared/send-confirmation-dialog/send-confirmation-dialog.component';
import { SharedConstants } from '../shared/shared.constants';
import { prop, sortBy } from 'ramda';
import { CalendarService } from '../calendar/calendar.service';
import Bugsnag from '@bugsnag/js';
import { environment } from 'spotlight-ui/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    CommunityCareMultipleEventModalComponent
} from '../shared/community-care-multiple-event-modal/community-care-multiple-event-modal.component';

@Injectable()
export class EngagementEffects {
  @Effect()
  public fetchEngagementDetails$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.START_ONE_PAGE_FLOW),
    switchMap(() => this.store.select(fromRoot.getEngagementDetailRequest).pipe(take(1))),
    switchMap((req) => this.getEngagementDetails(req).pipe(
      map((res) => new engagement.FetchEngagementDetailsSuccess(res)),
      catchError((error) => of(new engagement.ReceivedEngagementFailed(error)))
    ))
  );

  @Effect()
  public fetchThirdPartyDateRestrictionDetails$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.START_ONE_PAGE_FLOW),
    switchMap(() => this.store.select(fromRoot.getEngagementType).pipe(take(1))),
    filter((type) => type === DiscoverConstants.thirdPartyPromotion),
    switchMap(() => this.getThirdPartyDateRestrictions().pipe(
      map((res) => new engagement.FetchThirdPartyDateRestrictionSuccess(res)),
      catchError((error) => of(new engagement.ReceivedEngagementFailed(error)))
    ))
  );

  @Effect()
  public fetchCommunityCareDateRestrictionDetails$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.START_ONE_PAGE_FLOW),
    switchMap(() => this.store.select(fromRoot.getEngagementType).pipe(take(1))),
    filter((type) => type === DiscoverConstants.categoryCommunityCare),
    switchMap(() => this.store.select(fromRoot.getFeatureEnabled(SharedConstants.MultipleCommunityCareEvents)).pipe(take(1))),
    filter((enabled) => enabled),
    switchMap(() => this.getCommunityCareEventDateRestrictions().pipe(
      map((res) => new engagement.FetchCommunityCareDateRestrictionSuccess(res)),
      catchError((error) => of(new engagement.ReceivedEngagementFailed(error)))
    ))
  );

  @Effect()
  public fetchInternalPromoDateRestrictionDetails$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.START_ONE_PAGE_FLOW),
    switchMap(() => this.store.select(fromRoot.getEngagementType).pipe(take(1))),
    filter((type) => type === DiscoverConstants.promotion || type === DiscoverConstants.freeItemInCartPromotion),
    switchMap(() => this.store.select(fromRoot.getFeatureEnabled(DiscoverConstants.freeItemInCartPromotionFlag)).pipe(take(1))),
    filter((freeInCartEnabled)=>{return freeInCartEnabled}),
    switchMap(() => this.getInternalPromotionsDateRestrictions().pipe(
      concatLatestFrom(() => this.store.select(fromRoot.getSelectedLocation)),
      map(([res, location]) => new engagement.FetchInternalPromoDateRestrictionSuccess(res, location.locationNumber)),
      catchError((error) => of(new engagement.ReceivedEngagementFailed(error)))
    ))
  );

  @Effect()
  public createAutomation$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.SEND_ENGAGEMENT),
    switchMap(() => this.store.select(fromRoot.getEngagementType).pipe(take(1))),
    filter((type) => type === DiscoverConstants.automation),
    switchMap(() => this.store.select(fromRoot.getAutomationTreatRequest).pipe(take(1))),
    switchMap((eng) => this.sendAutomation(eng).pipe(
      map((res) => new engagement.CreateAutomationSuccess(res)),
      catchError(() => of(new engagement.CreateAutomationFailure('There was an error automating this campaign. Please try again later.')))
    ))
  );

  @Effect()
  public updateAutomation$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.EDIT_AUTOMATION),
    switchMap(() => this.store.select(fromRoot.getAutomationTreatRequest).pipe(take(1))),
    switchMap((eng) => this.editAutomation(eng).pipe(
      map((res) => new engagement.EditAutomationSuccess(res)),
      catchError(() => of(new engagement.EditAutomationFailure('There was an error editing this campaign. Please try again later.')))
    ))
  );

  @Effect()
  public sendReward$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.SEND_ENGAGEMENT),
    switchMap(() => this.store.select(fromRoot.getEngagementType).pipe(take(1))),
    filter((type) => type === DiscoverConstants.reward),
    switchMap(() => this.store.select(fromRoot.getOnePageRewardRequest).pipe(take(1))),
    switchMap((eng) => this.sendRewards(eng).pipe(
      map((res) => new engagement.SendRewardSuccess(res)),
      catchError(() => of(new engagement.SendFailure('There was an error sending this campaign. Please try again later.')))
    ))
  );

  @Effect()
  public sendEmail$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.SEND_ENGAGEMENT),
    switchMap(() => this.store.select(fromRoot.getEngagementType).pipe(take(1))),
    filter((type) => type === DiscoverConstants.email),
    switchMap(() => this.store.select(fromRoot.getOnePageEmailRequest).pipe(take(1))),
    switchMap((eng) => this.sendEmail(eng).pipe(
      map((res) => new engagement.SendEmailSuccess(res)),
      catchError(() => of(new engagement.SendFailure('There was an error sending this campaign. Please try again later.')))
    ))
  );

  @Effect()
  public sendPromotion$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.SEND_ENGAGEMENT),
    switchMap(() => this.store.select(fromRoot.getEngagementType).pipe(take(1))),
    filter((type) => type === DiscoverConstants.promotion),
    switchMap(() => this.store.select(fromRoot.getOnePagePromotionRequest).pipe(take(1))),
    switchMap((eng) => this.sendPromotion(eng).pipe(
      map((res) => new engagement.SendPromotionSuccess(res)),
      catchError(() => of(new engagement.SendFailure('There was an error sending this campaign. Please try again later.')))
    ))
  );

  @Effect()
  public sendThirdPartyPromotion$: Observable<Action> = this.actions$.pipe(
      ofType(engagement.ActionTypes.SEND_ENGAGEMENT),
      switchMap(() => this.store.select(fromRoot.getEngagementType).pipe(take(1))),
      filter((type) => type === DiscoverConstants.thirdPartyPromotion),
      switchMap(() => this.store.select(fromRoot.getOnePageThirdPartyPromotionRequest).pipe(take(1))),
      switchMap((eng) => this.sendThirdPartyPromotion(eng).pipe(
        map((res) => {
          return res.status === 'failed' ? new engagement.SendThirdPartyPromotionFailure(res.responseObject)
            : new engagement.SendThirdPartyPromotionSuccess(res.responseObject);
        }),
        catchError((err) => of(new engagement.SendThirdPartyPromotionFailure(err)))
      ))
  );

  @Effect()
  public sendFreeInCart$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.SEND_ENGAGEMENT),
    switchMap(() => this.store.select(fromRoot.getEngagementType).pipe(take(1))),
    filter((type) => type === DiscoverConstants.freeItemInCartPromotion),
    switchMap(() => this.store.select(fromRoot.getOnePageFreeInCartRequest).pipe(take(1))),
    switchMap((eng) => this.sendFreeInCart(eng).pipe(
      map((res) => { return res.status === 'failed' ? new engagement.SendFreeInCartFailure(res.responseObject) :
        new engagement.SendFreeInCartSuccess(res.responseObject)}),
      catchError((err) => of(new engagement.SendFreeInCartFailure(err)))
    ))
  );

  @Effect()
    public sendCommunityCareEvent$: Observable<Action> = this.actions$.pipe(
      ofType(engagement.ActionTypes.SEND_ENGAGEMENT),
      switchMap(() => this.store.select(fromRoot.getEngagementType).pipe(take(1))),
      filter((type) => type === DiscoverConstants.categoryCommunityCare),
      switchMap(() => this.store.select(fromRoot.getOnePageCommunityEventRequest).pipe(take(1))),
      switchMap((eng) => this.sendCommunityCareEvent(eng).pipe(
        map((res) => new engagement.SendCommunityCareEventSuccess(res)),
        catchError(() => of(new engagement.SendFailure('There was an error creating this Community Care Event. Please try again later.')))
      ))
    );

  @Effect()
  public stopAutomation$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.STOP_AUTOMATION),
    map((action: any) => action.payload),
    switchMap((payload) => this.ds.stopAutomationById(payload).pipe(
      concatLatestFrom(() => this.store.select(fromRoot.getSelectedLocation)),
      map(([res, location]) => new engagement.StopAutomationSuccess(payload, location.locationNumber)),
      catchError(() => of(new engagement.StopAutomationFailure('There was an error stopping this automation. Please try again later.')))
    ))
  );

  @Effect()
  public stopCommunityCareEvent$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.STOP_COMMUNITY_CARE_EVENT),
    map((action: any) => action.payload),
    switchMap((payload) => this.ds.stopCommunityCareEventById(payload).pipe(
      map(() => new engagement.StopCommunityCareEventSuccess('Community Care Event successfully stopped.')),
      catchError(() => of(new engagement.StopCommunityCareEventFailure('There was an error stopping this Community Care Event. Please try again later.')))
    ))
  );

  @Effect()
  public stopFreeInCart$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.STOP_FREE_IN_CART),
    map((action: any) => {
      let payload = action.payload
      payload.fieldId = payload.id
      payload.id = DiscoverConstants.freeItemInCartPromotion
      payload.automated = false
      return payload
    }),
    switchMap((payload) => this.ds.stopFreeItemInCartById(payload).pipe(
      concatLatestFrom(() => this.store.select(fromRoot.getSelectedLocation)),
      map(([res, location]) => new engagement.StopFreeInCartSuccess(payload, location.locationNumber)),
      catchError(() => of(new engagement.StopFreeInCartFailure('There was an error stopping this Free Item In Cart promotion. Please try again later.')))
    ))
  );

  @Effect()
  public cancelThirdPartyPromotion$: Observable<any> = this.actions$.pipe(
      ofType(engagement.ActionTypes.CANCEL_THIRD_PARTY_PROMOTION),
      map((action: any) => action.payload),
      switchMap((payload) => this.ds.stopThirdPartyPromotionById(payload).pipe(
          map((cancelEvent) => new engagement.CancelThirdPartyPromotionSuccess(cancelEvent)),
          catchError((error) => of(new engagement.CancelThirdPartyPromotionError(error))))
      )
  );

  @Effect({ dispatch: false })
  public stopAutomationFailure$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.STOP_AUTOMATION_FAILURE),
    tap(() => {
      // Only go to error page if the automation was cancelled from the one page flow
      if (this.router.url === '/discover/edit') {
        this.router.navigate(['/discover/error']);
      }
    })
  );

  @Effect({ dispatch: false })
  public openCancellationDialog$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.OPEN_CANCELLATION_DIALOG),
    map((action: any) => action.payload.tile),
    tap((tile: any) => {
      let config = new MatDialogConfig();
      config.autoFocus=false;
      if (tile.id === DiscoverConstants.bonusPoint && (tile.tileObjectId || tile.fieldId)) {
        config.data = {
          title: tile.automationCancellationTitle,
          displayText: tile.automationCancellationBody,
          id: tile.id,
          fieldId: tile.tileObjectId ? tile.tileObjectId : tile.fieldId
        };
      } else if (tile.id === DiscoverConstants.categoryCommunityCare || tile.id === DiscoverConstants.communityCareEventTileId) {
        config.data = {
          eventId: tile.tileObjectId,
          title: 'This will STOP your Community Care Event',
          displayText: 'You can activate a new event at any time through Discover.',
          id: tile.id
        };
      } else if (tile.id === DiscoverConstants.thirdPartyPromotion) {
        config.data = {
          eventId: tile.tileObjectId,
          title: 'This will STOP your Third Party Promotion',
          displayText: 'You can activate a new event at any time through Discover.',
          id: tile.id
        };
      } else if (tile.id === DiscoverConstants.freeItemInCartPromotion) {
        config.data = {
          fieldId: tile.tileObjectId,
          title: tile.automationCancellationTitle,
          displayText: tile.automationCancellationBody,
          id: tile.id
        };
      } else {
        config.data = {
          title: tile.automationCancellationTitle,
          displayText: tile.automationCancellationBody,
          id: tile.id,
          fieldId: ''
        };
      }
      this.dialog.open(CancellationDialogComponent, config);
    })
  );

  @Effect({ dispatch: false })
  public startOnePageFlow$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.START_ONE_PAGE_FLOW),
    map((action: any) => action.payload),
    tap((payload) => {
      if (payload.engagementType=== DiscoverConstants.thirdPartyPromotion) {
        this.router.navigate(['/discover/edit/edit-third-party-promotion']);
      } else if (payload.engagementType === DiscoverConstants.promotion) {
        this.router.navigate(['/discover/edit/edit-promotion-details']);
      } else if (payload.engagementType === DiscoverConstants.categoryCommunityCare) {
        this.router.navigate(['/discover/edit/edit-community-care-detail']);
      } else if (payload.engagementType === DiscoverConstants.freeItemInCartPromotion) {
        this.router.navigate(['/discover/edit/edit-free-item-in-cart-details']);
      }else {
        this.router.navigate(['/discover/edit/edit-details']);
      }
    })
  );

  @Effect({ dispatch: false })
  public goToDiscoverPage$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.GO_TO_DISCOVER_PAGE),
    tap(() => {
      this.dialog.closeAll();
      this.router.navigate(['/discover']);
    })
  );

  @Effect({ dispatch: false })
  public OpenEngageDialog: Observable<any> = this.actions$.pipe(
    ofType(
      engagement.ActionTypes.SEND_ENGAGEMENT,
      engagement.ActionTypes.EDIT_AUTOMATION,
      engagement.ActionTypes.STOP_COMMUNITY_CARE_EVENT_SUCCESS,
      engagement.ActionTypes.STOP_COMMUNITY_CARE_EVENT_FAILURE,
      engagement.ActionTypes.STOP_AUTOMATION,
      engagement.ActionTypes.STOP_FREE_IN_CART,
    ),
    map(() => this.openEngagementSendDialog())
  );

  @Effect()
  public getCalendarEventsForCommunityCare$: Observable<any> = this.actions$.pipe(
    ofType(engagement.ActionTypes.GO_TO_COMMUNITY_OVERLAPPING_ENGAGEMENT),
    switchMap(() => this.store.select(fromRoot.getEngagementCalendarPayload).pipe(take(1))),
    concatLatestFrom(() => this.store.select(fromRoot.getOverlappingEngagements)),
    filter(([engagementDates, overlappingEngagements]) =>
            overlappingEngagements.engagements.length === 0),
    switchMap(([engagementDateRange, engagements]) =>
      this.cs.getCalendarEvents(engagementDateRange).pipe(
      map((res) => new engage.CalendarEventsFound(res)),
      catchError((error) => of(new engage.CalendarEventsError(error)))
    ))
  );

  @Effect({ dispatch: false })
  public openCommunityCareMultipleEventsDialog$: Observable<any> = this.actions$.pipe(
    ofType(engagement.ActionTypes.FETCH_COMMUNITY_CARE_DATE_RESTRICTION_SUCCESS),
    switchMap(() => this.store.select(fromRoot.getEngagementCCEModalAlreadyShown).pipe(take(1))),
    switchMap((modalShown) =>  {
      return !modalShown ? this.store.select(fromRoot.getSortedAndShortenedCommunityCareEvents).pipe(take(1)) : null
    }),
    concatLatestFrom(() => this.store.select(fromRoot.getIsMobile)),
    map(([events, isMobile]) => {
      if (events && events.length > 0) {
        this.openCommunityCareMultipleEventsDialog(events, isMobile);
      }
    })
  );

  @Effect({ dispatch: false })
  public cancelThirdPartySuccess$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_SUCCESS,),
    tap(() => {
      this.dialog.closeAll();
      this.snackBar.open(
        `Promotion was successfully deleted.`,
        'Dismiss', {
          duration: 5000
        }
      );
      this.router.navigate(['/discover']);
    })
  );
  @Effect({ dispatch: false })
  public cancelThirdPartyFailure$: Observable<Action> = this.actions$.pipe(
    ofType(engagement.ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_ERROR,),
    tap(() => {
      this.dialog.closeAll();
      this.snackBar.open(
        `Promotion failed to delete. Please try again later.`,
        'Dismiss', {
          duration: 5000
        }
      );
      this.router.navigate(['/discover']);
    })
  );


  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private us: UserService,
    private ds: DiscoverService,
    private analytics: AnalyticsService,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private ehs: ErrorHandlerService,
    private cs: CalendarService,
  ) {

  }

  private sendEmail(emailReq): Observable<any> {
    this.sendAnalytics({
      flowId: emailReq.flowId
    });
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        let locationNo = loc.locationNumber;
        let url = `/api/location/${locationNo}/sendEmail`;
        return this.http.post(environment.spotlightApiUrl + url, emailReq);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => {
        Bugsnag.notify(new Error('Send Email Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  private sendRewards(rewardRequest): Observable<any> {
    this.sendAnalytics({
      flowId: rewardRequest.recommendationType
    });
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        let locationNo = loc.locationNumber;
        let url = `/api/location/${locationNo}/sendTreats`;
        return this.http.post(environment.spotlightApiUrl + url, rewardRequest);
      }),
      map((res: any) => res.responseObject),
      catchError((err) => {
        Bugsnag.notify(new Error('Send Rewards Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  private sendPromotion(promotionRequest): Observable<any> {
    let destinations = ""
    promotionRequest.destinations.forEach(des => {destinations = destinations + des +", "})
    this.sendAnalytics({
      flowId: promotionRequest.flowId,
      startTime: promotionRequest.startTime,
      startDate: promotionRequest.startDate,
      endTime: promotionRequest.endTime,
      endDate: promotionRequest.endDate,
      destinations: destinations,
      repeatOption: promotionRequest.repeatOption.repeatOptionId,
      subOption: promotionRequest.repeatOption.subOptions ? promotionRequest.repeatOption.subOptions[0].id : "",
      timeOfDay: promotionRequest.timeOfDayId
    });
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        let locationNo = loc.locationNumber;
        let url = `/api/location/${locationNo}/promotion`;
        return this.http.post(environment.spotlightApiUrl + url, promotionRequest);
      }),
      map((res: any) => {
        let automation = res.responseObject;
        automation.automated = true;
        automation.fieldId = automation.id;
        return automation;
      }),
      catchError((err) => {
        Bugsnag.notify(new Error('Send Promotion Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  private sendThirdPartyPromotion(promotionRequest): Observable<any> {
    let platforms = "";
    promotionRequest.platforms.forEach(platform => {platforms = platforms + platform + ", "})
    this.sendAnalytics({
      flowId: promotionRequest.flowId,
      startTime: promotionRequest.startTime,
      startDate: promotionRequest.startDate,
      endTime: promotionRequest.endTime,
      endDate: promotionRequest.endDate,
      reward: promotionRequest.offerTemplateId,
      platforms: platforms,
      customerType: promotionRequest.customerType ? promotionRequest.customerType[0] : "",

    });
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        let locationNo = loc.locationNumber;
        let url = `/api/location/${locationNo}/createThirdPartyPromotion`;
        return this.http.post( environment.spotlightApiUrl + url, promotionRequest);
      }),
      map((res: any) => {
        let automation = res.responseObject;
        automation.automated = true;
        automation.fieldId = automation.id;
        res.responseObject = automation
        return res;
      }),
      catchError((err) => {
        Bugsnag.notify(new Error('Send Promotion Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  private sendFreeInCart(freeInCartRequest): Observable<any> {
    let destinations = ""
    freeInCartRequest.destinationIds.forEach(des => {destinations = destinations + des +", "})
    this.sendAnalytics({
      flowId: freeInCartRequest.flowId,
      startTime: freeInCartRequest.startTime,
      startDate: freeInCartRequest.startDate,
      endTime: freeInCartRequest.endTime,
      endDate: freeInCartRequest.endDate,
      customerType: freeInCartRequest.customerType ? freeInCartRequest.customerType[0] : "",
      timeOfDay: freeInCartRequest.timeOfDayId,
      repeatOption: freeInCartRequest.repeatOption.repeatOptionId,
      subOption: freeInCartRequest.repeatOption.subOptions ? freeInCartRequest.repeatOption.subOptions[0].id : "",
      destinations: destinations,
      reward: freeInCartRequest.offerTemplateId
    });
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        let locationNo = loc.locationNumber;
        let url = `/api/location/${locationNo}/createFreeInCartPromotion`;
        return this.http.post( environment.spotlightApiUrl + url, freeInCartRequest);
      }),
      map((res: any) => {
        let automation = res.responseObject;
        automation.automated = true;
        automation.fieldId = automation.id;
        res.responseObject = automation
        return res;
      }),
      catchError((err) => {
        Bugsnag.notify(new Error('Send Free In Cart Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  private sendCommunityCareEvent(communityCareEventRequest): Observable<any> {
    let orgs = "";
    let codes = ""
    communityCareEventRequest.engagementOrganizations.forEach(org => {
      orgs = orgs + org.organizationName + ", "
      codes = codes + org.organizationCode + ", "
    } )
    this.sendAnalytics({
      flowId: communityCareEventRequest.flowId,
      startTime: communityCareEventRequest.startTime,
      startDate: communityCareEventRequest.startDate,
      endTime: communityCareEventRequest.endTime,
      endDate: communityCareEventRequest.endDate,
      orgs: orgs,
      codes: codes
    });
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        let locationNo = loc.locationNumber;
        let url = `/api/location/${locationNo}/communityCareEvent`;
        if(communityCareEventRequest.startDate == null || communityCareEventRequest.endDate == null){
          Bugsnag.notify(new Error('Send Community Care Event Failed - Invalid Dates'));
          throw new Error("error creating cce")
        }
        return this.http.post(environment.spotlightApiUrl + url, communityCareEventRequest) ;
      }),
      map((res: any) => {
        return res.responseObject;
      }),
      catchError((err) => {
        Bugsnag.notify(new Error('Send Community Care Event Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  private sendAutomation(automationReq): Observable<any> {
    this.analytics.sendMParticleEvent(
      'Automation Flow Sent',
      {
        'Flow ID': automationReq.id,
        'Include Active Treats': automationReq.includeActiveTreats,
        'Treat Validity Length': automationReq.treatValidityDays,
      }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        automationReq = { ...automationReq, locationNumber: loc.locationNumber };
        let url = `/api/location/${loc.locationNumber}/automation`;
        return this.http.post(environment.spotlightApiUrl + url, automationReq);
      }),
      map((res: any) => {
        let automation = res.responseObject;
        automation.automated = true;
        return automation;
      }),
      catchError((err) => {
        Bugsnag.notify(new Error('Send Automation Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  private editAutomation(automationReq): Observable<any> {
    this.analytics.sendMParticleEvent(
      'Automation Edited',
      {
        'Flow ID': automationReq.id,
        'Include Active Treats': automationReq.includeActiveTreats,
        'Treat Validity Length': automationReq.treatValidityDays,
      }
    );
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        automationReq = { ...automationReq, locationNumber: loc.locationNumber };
        let url = `/api/location/${loc.locationNumber}/automation`;
        return this.http.put(environment.spotlightApiUrl + url, automationReq);
      }),
      map((res: any) => {
        let automation = res.responseObject;
        automation.automated = true;
        return automation;
      }),
      catchError((err) => {
        Bugsnag.notify(new Error('Edit Automation Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  private getEngagementDetails(treatRequest): Observable<any> {
    const shouldGetAutomationDetails = treatRequest.engagementMode === DiscoverConstants.edit;
    const treatRequestCopy = treatRequest;

    if (treatRequest.engagementType === DiscoverConstants.categoryCommunityCare) {
      treatRequest = {
        'engagementType': 'promotion', 'flowId': 'bonus-point', 'campaignName': 'Bonus Point Promotion', 'emailMessage': '',
        'selectedCustomers': [], 'subject': ''
      };
    }
    let result = {
      'status': 'success',
      'responseObject': {
        'communityCaresMetaData': {
          'optInOptions': [{
            'name': 'No Code Required',
            'id': 'noCode',
            'defaultToggle': true,
            'subOpt': null
          }, {
            'name': 'Organization Code',
            'id': 'organizationCode',
            'defaultToggle': false,
            'subOpt': null
          }]
        }
      },
      'statusCode': 200,
      'sortColumnName': null
    }
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        let locationNo = loc.locationNumber;
        let url = `/api/${locationNo}/engagementDetails?automationDetails=${shouldGetAutomationDetails}`;
        return this.http.post(environment.spotlightApiUrl + url, treatRequest);
      }),
      map((res: any) => {
        if (treatRequestCopy.engagementType === DiscoverConstants.categoryCommunityCare) {
          return result.responseObject;
        } else {
          return res.responseObject;
        }
      }),
      catchError((err) => {
        Bugsnag.notify(new Error('Start One Page Flow Failed'));
        return this.ehs.handleError(err);
      })
    );
  }

  private getThirdPartyDateRestrictions(): Observable<any> {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const locationNumber = loc.locationNumber;
        const eventType = DiscoverConstants.thirdPartyEventType;
        let url = `/api/location/${locationNumber}/dateRestrictions/${eventType}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      catchError((err) => {
        Bugsnag.notify(new Error('Third Party Date Restrictions Failed to Load'));
        return this.ehs.handleError(err);
      })
    );
  }

  private getCommunityCareEventDateRestrictions(): Observable<any> {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const locationNumber = loc.locationNumber;
        const eventType = DiscoverConstants.communityCareEventType;
        let url = `/api/location/${locationNumber}/dateRestrictions/${eventType}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      catchError((err) => {
        Bugsnag.notify(new Error('Community Care Event Date Restrictions Failed to Load'));
        return this.ehs.handleError(err);
      })
    );
  }

  private getInternalPromotionsDateRestrictions(): Observable<any> {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc) => {
        const locationNumber = loc.locationNumber;
        const eventType = DiscoverConstants.internalPromotionType;
        let url = `/api/location/${locationNumber}/dateRestrictions/${eventType}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      catchError((err) => {
        Bugsnag.notify(new Error('Free In Cart Date Restrictions Failed to Load'));
        return this.ehs.handleError(err);
      })
    );
  }

  private sendAnalytics(payload) {
    this.analytics.sendMParticleEvent(
      'Discover Flow Sent',
       payload
    );
  }

  private openEngagementSendDialog() {
    let config: MatDialogConfig = {
      autoFocus: false,
      disableClose: true,
      data: { page: SharedConstants.discoverPage }
    }
    this.dialog.open(SendConfirmationDialogComponent, config);
  }

    private openCommunityCareMultipleEventsDialog(data, isMobile) {
        let configMobile: MatDialogConfig = {
            autoFocus: false,
            height: '100%',
            width: '100%',
            maxWidth: '750px',
            data: { events: data , isMobile}
        }
        let configDesktop: MatDialogConfig = {
          autoFocus: false,
          data: { events: data , isMobile}
        }
        let config = isMobile ? configMobile : configDesktop
        this.dialog.open(CommunityCareMultipleEventModalComponent, config).afterClosed().subscribe(()=>{
          this.store.dispatch(new engagement.CCEUpdateModalShown());
        });
    }

}
