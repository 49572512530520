<error-message *ngIf="(serviceNowCaseError$ | async) !== null"
               [error]="serviceNowCaseError$ | async"
               [page]="'Service Now Report'">
</error-message>
<mat-dialog-content *ngIf="!(isMobile$ | async) && !(serviceNowCaseError$ | async)" class="service-now-report__wrapper">
  <div class="service-now-report__header">
    CARES Report #{{(serviceNowCase$ | async).reportNumber}}
    <mat-icon class="service-now-report__close-report" (click)="closeReport()">close</mat-icon>
  </div>
  <div *ngIf="serviceNowCaseLoading$ | async"
       class="service-now-report__loading"
       [cfaLoading]="true">
  </div>
  <div *ngIf="!(serviceNowCaseLoading$ | async)" fxLayout="row" fxLayoutGap="10">
    <div fxFlex="30%">
      <div class="service-now-report__personal-details">
        <div>Customer: {{(serviceNowCase$ | async).customerName}}</div>
        <div>Phone: {{(serviceNowCase$ | async).customerPhoneNumber}}</div>
        <div>Email: {{(serviceNowCase$ | async).customerEmail}}</div>
      </div>
      <div class="service-now-report__report-details-container">
        <div class="service-now-report__report-details-header">Contact Instruction</div>
        <div>{{(serviceNowCase$ | async).reportContactVia}}</div>
        <div [ngClass]="{'service-now-report__red-text': (serviceNowCase$ | async).reportPriority === highPriority}">
            {{(serviceNowCase$ | async).reportPriority}}
        </div>
        <div>{{(serviceNowCase$ | async).reportContactRequiredTime}}</div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Occurred On</div>
        <div>{{(serviceNowCase$ | async).reportOccurredOn}}</div>
        <div class="service-now-report__report-details-header">Contacted On</div>
        <div>{{(serviceNowCase$ | async).reportContactedOn}}</div>
        <div class="service-now-report__report-details-header">Report Routed On</div>
        <div>{{(serviceNowCase$ | async).reportRoutedOn}}</div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Customer Contact History</div>
        <div>Prior complaints to any Chick-fil-A: {{(serviceNowCase$ | async).customerPriorComplaintsToAnyRestaurant}}</div>
        <div>Prior complaints to this Chick-fil-A:
          <!--TODO: Add link to member profile-->
          <div [ngClass]="{'service-now-report__red-text': (serviceNowCase$ | async).customerPriorComplaintsToThisRestaurant > 1}">
              {{(serviceNowCase$ | async).customerPriorComplaintsToThisRestaurant}}
              <span *ngIf="(serviceNowCase$ | async).customerPriorComplaintsToThisRestaurant > 1"> - View in Member Profile</span>
          </div>
        </div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Location Information</div>
        <div>Number: {{(serviceNowCase$ | async).locationNumber}}</div>
        <div class="service-now-report__report-details-header">Simple Order/Service Contact</div>
        <div>{{(serviceNowCase$ | async).reportOrderServiceContact}}</div>
        <div class="service-now-report__report-details-header">Simple-Service Environment Contact</div>
        <div>{{(serviceNowCase$ | async).reportServiceEnvironmentContact}}</div>
      </div>
    </div>
    <div fxFlex="70%">
      <div class="service-now-report__high-priority-details"
           *ngIf="(serviceNowCase$ | async).reportPriority === highPriority">
        <div>To: {{(serviceNowCase$ | async).reportTo}}</div>
        <div>CC: {{(serviceNowCase$ | async).reportCC}}</div>
      </div>
      <div class="service-now-report__report-history-container">
        <div class="service-now-report__report-details-header">Active Guidance for Restaurant</div>
        <!--TODO: Add logic to only show red styling if action is overdue-->
        <div class="service-now-report__red-text">{{(serviceNowCase$ | async).reportActionGuidance}}</div>
        <hr class="service-now-report__divider">
        <div fxLayout="row" fxLayoutGap="10">
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Order Placed Via</div>
            <div>{{(serviceNowCase$ | async).orderPlacedVia}}</div>
          </div>
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Order Received Via</div>
            <div>{{(serviceNowCase$ | async).orderReceivedVia}}</div>
          </div>
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Receipt?</div>
            <div>{{(serviceNowCase$ | async).receiptIncluded}}</div>
          </div>
          <div fxFlex="25%">
            <div class="service-now-report__report-details-header">Order Number</div>
            <div>{{(serviceNowCase$ | async).orderNumber}}</div>
          </div>
        </div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Customer Comments</div>
        <div>{{(serviceNowCase$ | async).customerComments}}</div>
        <hr class="service-now-report__divider">
        <div class="service-now-report__report-details-header">Approved Response Sent To Customer</div>
        <div>{{(serviceNowCase$ | async).responseSentToCustomer}}</div>
        <hr class="service-now-report__divider">
        <div fxLayout="row" fxLayoutGap="10">
          <div fxFlex="45%">
            <div class="service-now-report__report-details-header">Resolution Status</div>
            <div>{{(serviceNowCase$ | async).reportResolutionStatus}}</div>
            <div>Reasons CARES unable to resolve: {{(serviceNowCase$ | async).reportReasonUnableToResolve}}</div>
          </div>
          <div fxFlex="10%">
            <div class="service-now-report__vertical-divider"></div>
          </div>
          <div fxFlex="45%">
            <div class="service-now-report__report-details-header">Care Offer Status</div>
            <div>{{(serviceNowCase$ | async).reportOfferStatus}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!(serviceNowCaseLoading$ | async)" class="service-now-report__button-container">
    <button mat-raised-button
            class="service-now-report__acknowledge-report-button"
            *ngIf="(serviceNowCase$ | async).reportPriority === highPriority"
            [disabled]="!(serviceNowCase$ | async).canAcknowledgeReport"
            (click)="acknowledgeReport()">
      Acknowledge Report
    </button>
    <button mat-raised-button
            class="service-now-report__reassign-report-button"
            [disabled]="!(serviceNowCase$ | async).canReassignReport"
            (click)="reassignReport()">
      Reassign Report
    </button>
  </div>
</mat-dialog-content>
<mat-dialog-content *ngIf="(isMobile$ | async) && !(serviceNowCaseError$ | async)" class="service-now-report__mobile-wrapper">
  <div class="service-now-report__header">
    CARES Report #{{(serviceNowCase$ | async).reportNumber}}
    <mat-icon class="service-now-report__close-report" (click)="closeReport()">close</mat-icon>
  </div>
  <div *ngIf="serviceNowCaseLoading$ | async"
       class="service-now-report__loading"
       [cfaLoading]="true">
  </div>
  <div *ngIf="!(serviceNowCaseLoading$ | async)" class="service-now-report__mobile-body">
    <div class="service-now-report__personal-details">
      <div>Customer: {{(serviceNowCase$ | async).customerName}}</div>
      <div>Phone: {{(serviceNowCase$ | async).customerPhoneNumber}}</div>
      <div>Email: {{(serviceNowCase$ | async).customerEmail}}</div>
      <div *ngIf="(serviceNowCase$ | async).reportPriority === highPriority">
        <div>To: {{(serviceNowCase$ | async).reportTo}}</div>
        <div>CC: {{(serviceNowCase$ | async).reportCC}}</div>
      </div>
    </div>
    <div class="service-now-report__report-details-container">
      <div class="service-now-report__report-details-header">Contact Instruction</div>
      <div>{{(serviceNowCase$ | async).reportContactVia}}</div>
      <div [ngClass]="{'service-now-report__red-text': (serviceNowCase$ | async).reportPriority === highPriority}">
          {{(serviceNowCase$ | async).reportPriority}}
      </div>
      <div>{{(serviceNowCase$ | async).reportContactRequiredTime}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Occurred On</div>
      <div>{{(serviceNowCase$ | async).reportOccurredOn}}</div>
      <div class="service-now-report__report-details-header">Contacted On</div>
      <div>{{(serviceNowCase$ | async).reportContactedOn}}</div>
      <div class="service-now-report__report-details-header">Report Routed On</div>
      <div>{{(serviceNowCase$ | async).reportRoutedOn}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Customer Contact History</div>
      <div>Prior complaints to any Chick-fil-A: {{(serviceNowCase$ | async).customerPriorComplaintsToAnyRestaurant}}</div>
      <!--TODO: Add link to member profile-->
      <div>Prior complaints to this Chick-fil-A:
        <div class="service-now-report__red-text">{{(serviceNowCase$ | async).customerPriorComplaintsToThisRestaurant}} - View in Member Profile</div>
      </div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Location Information</div>
      <div>Number: {{(serviceNowCase$ | async).locationNumber}}</div>
      <div class="service-now-report__report-details-header">Simple Order/Service Contact</div>
      <div>{{(serviceNowCase$ | async).reportOrderServiceContact}}</div>
      <div class="service-now-report__report-details-header">Simple-Service Environment Contact</div>
      <div>{{(serviceNowCase$ | async).reportServiceEnvironmentContact}}</div>
    </div>
    <div class="service-now-report__report-history-container-mobile">
      <div class="service-now-report__report-details-header">Active Guidance for Restaurant</div>
      <!--TODO: Add logic to only show red styling if action is overdue-->
      <div class="service-now-report__red-text">{{(serviceNowCase$ | async).reportActionGuidance}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Order Placed Via</div>
      <div>{{(serviceNowCase$ | async).orderPlacedVia}}</div>
      <div class="service-now-report__report-details-header">Order Received Via</div>
      <div>{{(serviceNowCase$ | async).orderReceivedVia}}</div>
      <div class="service-now-report__report-details-header">Receipt?</div>
      <div>{{(serviceNowCase$ | async).receiptIncluded}}</div>
      <div class="service-now-report__report-details-header">Order Number</div>
      <div>{{(serviceNowCase$ | async).orderNumber}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Customer Comments</div>
      <div>{{(serviceNowCase$ | async).customerComments}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Approved Response Sent To Customer</div>
      <div>{{(serviceNowCase$ | async).responseSentToCustomer}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Resolution Status</div>
      <div>{{(serviceNowCase$ | async).reportResolutionStatus}}</div>
      <div>Reasons CARES unable to resolve: {{(serviceNowCase$ | async).reportReasonUnableToResolve}}</div>
      <hr class="service-now-report__divider">
      <div class="service-now-report__report-details-header">Care Offer Status</div>
      <div>{{(serviceNowCase$ | async).reportOfferStatus}}</div>
    </div>
  </div>
  <div *ngIf="!(serviceNowCaseLoading$ | async)" class="service-now-report__button-container-mobile">
    <button mat-raised-button
            class="service-now-report__acknowledge-report-button-mobile"
            *ngIf="(serviceNowCase$ | async).reportPriority === highPriority"
            [disabled]="!(serviceNowCase$ | async).canAcknowledgeReport"
            (click)="acknowledgeReport()">
      Acknowledge Report
    </button>
    <button mat-raised-button
            class="service-now-report__reassign-report-button-mobile"
            [disabled]="!(serviceNowCase$ | async).canReassignReport"
            (click)="reassignReport()">
      Reassign Report
    </button>
  </div>
</mat-dialog-content>
