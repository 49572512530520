import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  CARE_CHANGE_VIEW:                                type('[care] Care Change Care Report View'),
  CARE_SET_SELECTED_TAB_INDEX:                     type('[care] Care Set Selected Tab Index'),
  CFA_ONE_CHANGE_VIEW:                             type('[Care] Cfa One Contacts Change View'),
  CLEAR_SEARCH_TERM:                               type('[Care] Clear Search Term'),
  CLOSE_ACTION_HISTORY_NOTE:                       type('[Care] Close Action History Note'),
  CLOSE_ALL_OPEN_NOTES:                            type('[Care] Close All Open Notes'),
  CLOSE_CUSTOMER_NOTE:                             type('[Care] Close Customer Note'),
  CLOSED_CARE_CHANGE_VIEW:                         type('[care] Care Change Closed Care Report View'),
  CLOSED_CARE_CONTACTS_CHANGE_PAGE:                type('[care] Closed Care Contacts Change Page'),
  CLOSED_CARE_CONTACTS_CHANGE_PAGE_SIZE:           type('[care] Closed Care Contacts Change Page Size'),
  CLOSED_CARE_CONTACTS_CHANGE_SORT:                type('[care] Closed Care Contacts Change Sort'),
  CLOSED_CARE_CONTACTS_CLEAR_SEARCH_TERM:          type('[care] Closed Care Contacts Clear Search Term'),
  CLOSED_CARE_CONTACTS_ERROR:                      type('[care] Closed Care Contacts Error'),
  CLOSED_CARE_CONTACTS_SEARCH:                     type('[care] Closed Care Contacts Search'),
  CLOSED_CARE_CONTACTS_SEARCH_BY:                  type('[care] Closed Care Contacts Search By'),
  CLOSED_CARE_CONTACTS_SUCCESS:                    type('[care] Closed Care Contacts Success'),
  CREATE_ACTION_HISTORY_NOTE:                      type('[Care] Create Action History Note'),
  CREATE_ACTION_HISTORY_NOTE_ERROR:                type('[Care] Create Action History Note Error'),
  CREATE_ACTION_HISTORY_NOTE_SUCCESS:              type('[Care] Create Action History Note Success'),
  CREATE_CUSTOMER_NOTE:                            type('[Care] Create Customer Note'),
  CUSTOMER_NOTE_ACTION_COMPLETE:                   type('[Care] Customer Note Action Complete'),
  CUSTOMER_NOTE_ERROR:                             type('[Care] Customer Note Error'),
  DECREMENT_REWARD:                                type('[Care] Decrement Rewards'),
  DELETE_CUSTOMER_NOTE:                            type('[Care] Delete Customer Note'),
  DELETE_CUSTOMER_NOTE_SUCCESS:                    type('[Care] Delete Customer Note Success'),
  EDIT_CUSTOMER_NOTE:                              type('[Care] Edit Customer Note'),
  ERROR:                                           type('[Care] Error'),
  FETCH_TEMPLATE_ERROR:                            type('[Care] Fetch Template Error'),
  FETCH_TEMPLATE_SUCCESS:                          type('[Care] Fetch Template Success'),
  GET_ACTIVITY_HISTORY_ERROR:                      type('[Care] Get Activity History Error'),
  GET_ACTIVITY_HISTORY_SUCCESS:                    type('[Care] Get Activity History Success'),
  GET_CARE_EMAIL_DETAILS:                          type('[Care] Get Care Email Details'),
  GET_CARE_EMAIL_DETAILS_ERROR:                    type('[Care] Get Care Email Details Error'),
  GET_CARE_EMAIL_DETAILS_SUCCESS:                  type('[Care] Get Care Email Details Success'),
  GET_CARE_REPORT_DETAILS:                         type('[Care] Get Care Report Details'),
  GET_CARE_REPORT_DETAILS_ERROR:                   type('[Care] Get Care Report Details Error'),
  GET_CARE_REPORT_DETAILS_SUCCESS:                 type('[Care] Get Care Report Details Success'),
  GET_CARE_REPORTS_ERROR:                          type('[Care] Get Care Reports Error'),
  GET_CARE_REPORTS_SUCCESS:                        type('[Care] Get Care Reports Success'),
  GET_CUSTOMER_NOTES_ERROR:                        type('[care] Get Customer Notes Error'),
  GET_CUSTOMER_NOTES_SUCCESS:                      type('[care] Get Customer Notes Success'),
  GET_ELIGIBLE_FOR_RECOVERY_ERROR:                 type('[care] Get Eligible For Recovery Error'),
  GET_ELIGIBLE_FOR_RECOVERY_SUCCESS:               type('[care] Get Eligible For Recovery Success'),
  INCREMENT_REWARD:                                type('[Care] Increment Rewards'),
  LOAD_CARES_PAGE:                                 type('[Care] Load Cares Page'),
  LOAD_RECOVERED_CFA_ONE_CONTACTS_DATA:            type('[care] Load Recovered Cfa One Contacts'),
  OPEN_ACTION_HISTORY_NOTE:                        type('[Care] Open Action History Note'),
  OPEN_CUSTOMER_NOTE:                              type('[Care] Open Customer Note'),
  OPEN_SERVICE_NOW_REPORT:                         type('[care] Open Service Now Report'),
  OPEN_SERVICE_NOW_REPORT_ERROR:                   type('[care] Open Service Now Report Error'),
  OPEN_SERVICE_NOW_REPORT_SUCCESS:                 type('[care] Open Service Now Report Success'),
  PAGE_CHANGE:                                     type('[Care] Page Change'),
  PAGE_SIZE_CHANGE:                                type('[Care] Page Size Change'),
  RECOVERED_CFA_ONE_CHANGE_VIEW:                   type('[Care] Recovered Cfa One Contacts Change View'),
  RECOVERED_CFA_ONE_CONTACTS_CHANGE_PAGE:          type('[care] Recovered Cfa One Contacts Change Page'),
  RECOVERED_CFA_ONE_CONTACTS_CHANGE_PAGE_SIZE:     type('[care] Recovered Cfa One Contacts Change Page Size'),
  RECOVERED_CFA_ONE_CONTACTS_CHANGE_SORT:          type('[care] Recovered Cfa One Contacts Change Sort'),
  RECOVERED_CFA_ONE_CONTACTS_CLEAR_SEARCH_TERM:    type('[care] Recovered Cfa One Contacts Clear Search Term'),
  RECOVERED_CFA_ONE_CONTACTS_DISPLAY_ERROR_TEXT:   type('[Care] Recovered Cfa One Contacts Display Error Text'),
  RECOVERED_CFA_ONE_CONTACTS_ERROR:                type('[care] Recovered Cfa One Contacts Error'),
  RECOVERED_CFA_ONE_CONTACTS_SEARCH:               type('[care] Recovered Cfa One Contacts Search'),
  RECOVERED_CFA_ONE_CONTACTS_SEARCH_BY:            type('[care] Recovered Cfa One Contacts Search By'),
  RECOVERED_CFA_ONE_CONTACTS_SUCCESS:              type('[care] Recovered Cfa One Contacts Success'),
  REOPEN_REPORT:                                   type('[Care] Reopen Report'),
  REOPEN_REPORT_ERROR:                             type('[Care] Reopen Report Error'),
  REOPEN_REPORT_SUCCESS:                           type('[Care] Reopen Report Success'),
  SEARCH_BY:                                       type('[Care] Search By'),
  SEARCH_TERM:                                     type('[Care] Search Term'),
  SELECT_CUSTOMER:                                 type('[Care] Select Customer'),
  SELECT_REPORT:                                   type('[Care] Select Report'),
  SELECT_REWARDS:                                  type('[Care] Select Rewards'),
  SELECT_TEMPLATE:                                 type('[Care] Select Template'),
  SEND_RESPONSE:                                   type('[Care] Send Response'),
  SEND_RESPONSE_ERROR:                             type('[Care] Send Response Error'),
  SEND_RESPONSE_SUCCESS:                           type('[Care] Send Response Success'),
  SORT_CHANGE:                                     type('[Care] Sort Change'),
  SPOTLIGHT_CONTACTS_CHANGE_PAGE:                  type('[care] Spotlight Contacts Change Page'),
  SPOTLIGHT_CONTACTS_CHANGE_PAGE_SIZE:             type('[care] Spotlight Contacts Change Page Size'),
  SPOTLIGHT_CONTACTS_CHANGE_SORT:                  type('[care] Spotlight Contacts Change Sort'),
  SPOTLIGHT_CONTACTS_CLEAR_SEARCH_TERM:            type('[Care] Spotlight Contacts Clear Search Term'),
  SPOTLIGHT_CONTACTS_DISPLAY_ERROR_TEXT:           type('[Care] Spotlight Contacts Display Error Text'),
  SPOTLIGHT_CONTACTS_SEARCH:                       type('[Care] Spotlight Contacts Search'),
  SPOTLIGHT_CONTACTS_SEARCH_BY:                    type('[Care] Spotlight Contacts Search By'),
  SPOTLIGHT_CONTACTS_SEARCH_COMPLETE:              type('[Care] Spotlight Contacts Search Complete'),
  SPOTLIGHT_CONTACTS_SEARCH_ERROR:                 type('[Care] Spotlight Contacts Search Error'),
  UPDATE_ACTION_HISTORY_NOTE:                      type('[Care] Update Action History Note'),
  UPDATE_ACTION_HISTORY_NOTE_ERROR:                type('[Care] Update Action History Note Error'),
  UPDATE_ACTION_HISTORY_NOTE_SUCCESS:              type('[Care] Update Action History Note Success'),
  UPDATE_COPY:                                     type('[Care] Update Copy'),
  UPDATE_CUSTOMER_NOTE:                            type('[Care] Update Customer Note'),
  UPDATE_STATUS:                                   type('[Care] Update Status'),
  UPDATE_STATUS_ERROR:                             type('[Care] Update Status Fail'),
  UPDATE_STATUS_SUCCESS:                           type('[Care] Update Status Success'),
  UPDATE_THEME:                                    type('[Care] Update Theme'),
  VIEW_REPORT_FROM_MEET:                           type('[Care] View Report From Meet')
};

export class CareChangeCareReportView implements Action {
  public type = ActionTypes.CARE_CHANGE_VIEW;
  constructor(public payload: string) { }
}

export class CareSetSelectedTabIndex implements Action {
  public type = ActionTypes.CARE_SET_SELECTED_TAB_INDEX;
  constructor(public payload: number) { }
}

export class CfaOneChangeReportView implements Action {
  public type = ActionTypes.CFA_ONE_CHANGE_VIEW;
  constructor(public payload: string) { }
}

export class ClearSearchTerm implements Action {
  public type = ActionTypes.CLEAR_SEARCH_TERM;
  constructor(public payload: any = '') { }
}

export class CloseActionHistoryNote implements Action {
  public type = ActionTypes.CLOSE_ACTION_HISTORY_NOTE;
  constructor(public payload: any = '') { }
}

export class CloseAllOpenNotes implements Action {
  public type = ActionTypes.CLOSE_ALL_OPEN_NOTES;
  constructor(public payload: any = '') { }
}

export class CloseCustomerNote implements Action {
  public type = ActionTypes.CLOSE_CUSTOMER_NOTE;
  constructor(public payload: any = '') { }
}

export class ClosedCareChangeCareReportView implements Action {
  public type = ActionTypes.CLOSED_CARE_CHANGE_VIEW;
  constructor(public payload: string) { }
}

export class ClosedCareContactsChangePage implements Action {
  public type = ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_PAGE;
  constructor(public payload: number) { }
}

export class ClosedCareContactsChangePageSize implements Action {
  public type = ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_PAGE_SIZE;
  constructor(public payload: number) { }
}

export class ClosedCareContactsSort implements Action {
  public type = ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_SORT;
  constructor(public payload: Sort) { }
}

export class ClosedCareContactsClearSearchTerm implements Action {
  public type = ActionTypes.CLOSED_CARE_CONTACTS_CLEAR_SEARCH_TERM;
  constructor(public payload: any = '') { }
}

export class GetClosedCareContactsError implements Action {
  public type = ActionTypes.CLOSED_CARE_CONTACTS_ERROR;
  constructor(public payload: any) { }
}

export class ClosedCareContactsSearch implements Action {
  public type = ActionTypes.CLOSED_CARE_CONTACTS_SEARCH;
  constructor(public payload: any = '') { }
}

export class ClosedCareContactsSearchBy implements Action {
  public type = ActionTypes.CLOSED_CARE_CONTACTS_SEARCH_BY;
  constructor(public payload: any = '') { }
}

export class GetClosedCareContactsSuccess implements Action {
  public type = ActionTypes.CLOSED_CARE_CONTACTS_SUCCESS;
  constructor(public payload: any) { }
}

export class CreateActionHistoryNoteAction implements Action {
  public type = ActionTypes.CREATE_ACTION_HISTORY_NOTE;
  constructor(public payload: any) { }
}

export class CreateActionHistoryNoteError implements Action {
  public type = ActionTypes.CREATE_ACTION_HISTORY_NOTE_ERROR;
  constructor(public payload: any) { }
}

export class CreateActionHistoryNoteSuccess implements Action {
  public type = ActionTypes.CREATE_ACTION_HISTORY_NOTE_SUCCESS;
  constructor(public payload: any) { }
}

export class CreateCustomerNoteAction implements Action {
  public type = ActionTypes.CREATE_CUSTOMER_NOTE;
  constructor(public payload: any) { }
}

export class CustomerNoteActionComplete implements Action {
  public type = ActionTypes.CUSTOMER_NOTE_ACTION_COMPLETE;
  constructor(public payload: any) { }
}

export class CustomerNoteError implements Action {
  public type = ActionTypes.CUSTOMER_NOTE_ERROR;
  constructor(public payload: any) { }
}

export class DecrementReward implements Action {
  public type = ActionTypes.DECREMENT_REWARD;
  constructor(public payload: any) { }
}

export class DeleteCustomerNoteAction implements Action {
  public type = ActionTypes.DELETE_CUSTOMER_NOTE;
  constructor(public payload: any) { }
}

export class DeleteCustomerNoteSuccess implements Action {
  public type = ActionTypes.DELETE_CUSTOMER_NOTE_SUCCESS;
  constructor(public payload: any) { }
}

export class EditCustomerNote implements Action {
  public type = ActionTypes.EDIT_CUSTOMER_NOTE;
  constructor(public payload: any = '') { }
}

export class ErrorAction implements Action {
  public type = ActionTypes.ERROR;
  constructor(public payload: any) { }
}

export class FetchTemplateError implements Action {
  public type = ActionTypes.FETCH_TEMPLATE_ERROR;
  constructor(public payload: string) { }
}

export class FetchTemplateSuccess implements Action {
  public type = ActionTypes.FETCH_TEMPLATE_SUCCESS;
  constructor(public payload: any) { }
}

export class GetActivityHistoryError implements Action {
  public type = ActionTypes.GET_ACTIVITY_HISTORY_ERROR;
  constructor(public payload: any = '') { }
}

export class GetActivityHistorySuccess implements Action {
  public type = ActionTypes.GET_ACTIVITY_HISTORY_SUCCESS;
  constructor(public payload: any = '') { }
}

export class GetCareEmailDetails implements Action {
  public type = ActionTypes.GET_CARE_EMAIL_DETAILS;
  constructor(public payload: any = '') { }
}

export class GetCareEmailDetailsError implements Action {
  public type = ActionTypes.GET_CARE_EMAIL_DETAILS_ERROR;
  constructor(public payload: any = '') { }
}

export class GetCareEmailDetailsSuccess implements Action {
  public type = ActionTypes.GET_CARE_EMAIL_DETAILS_SUCCESS;
  constructor(public payload: any = '') { }
}

export class GetCareReportDetails implements Action {
  public type = ActionTypes.GET_CARE_REPORT_DETAILS;
  constructor(public payload: string) { }
}

export class GetCareReportDetailsError implements Action {
  public type = ActionTypes.GET_CARE_REPORT_DETAILS_ERROR;
  constructor(public payload: string) { }
}

export class GetCareReportDetailsSuccess implements Action {
  public type = ActionTypes.GET_CARE_REPORT_DETAILS_SUCCESS;
  constructor(public payload: any) { }
}

export class GetCareReportsError implements Action {
  public type = ActionTypes.GET_CARE_REPORTS_ERROR;
  constructor(public payload: string) { }
}

export class GetCareReportsSuccess implements Action {
  public type = ActionTypes.GET_CARE_REPORTS_SUCCESS;
  constructor(public payload: any) { }
}

export class GetCustomerNotesError implements Action {
  public type = ActionTypes.GET_CUSTOMER_NOTES_ERROR;
  constructor(public payload: any) { }
}

export class GetCustomerNotesSuccess implements Action {
  public type = ActionTypes.GET_CUSTOMER_NOTES_SUCCESS;
  constructor(public payload: any) { }
}

export class GetEligibleForRecoveryError implements Action {
  public type = ActionTypes.GET_ELIGIBLE_FOR_RECOVERY_ERROR;
  constructor(public payload: any) { }
}

export class GetEligibleForRecoverySuccess implements Action {
  public type = ActionTypes.GET_ELIGIBLE_FOR_RECOVERY_SUCCESS;
  constructor(public payload: any) { }
}

export class IncrementReward implements Action {
  public type = ActionTypes.INCREMENT_REWARD;
  constructor(public payload: any) { }
}

export class LoadCaresPage implements Action {
  public type = ActionTypes.LOAD_CARES_PAGE;
  constructor(public payload: any = '') { }
}

export class ShouldLoadRecoveredCfaOneContactsData implements Action {
  public type = ActionTypes.LOAD_RECOVERED_CFA_ONE_CONTACTS_DATA;
  constructor(public payload: boolean) { }
}

export class OpenActionHistoryNote implements Action {
  public type = ActionTypes.OPEN_ACTION_HISTORY_NOTE;
  constructor(public payload: any = '') { }
}

export class OpenCustomerNote implements Action {
  public type = ActionTypes.OPEN_CUSTOMER_NOTE;
  constructor(public payload: any = '') { }
}

export class PageChange implements Action {
  public type = ActionTypes.PAGE_CHANGE;
  constructor(public payload: number) { }
}

export class PageSizeChange implements Action {
  public type = ActionTypes.PAGE_SIZE_CHANGE;
  constructor(public payload: number) { }
}

export class RecoveredCfaOneChangeReportView implements Action {
  public type = ActionTypes.RECOVERED_CFA_ONE_CHANGE_VIEW;
  constructor(public payload: string) { }
}

export class RecoveredCfaOneContactsChangePage implements Action {
  public type = ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_PAGE;
  constructor(public payload: number) { }
}

export class RecoveredCfaOneContactsChangePageSize implements Action {
  public type = ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_PAGE_SIZE;
  constructor(public payload: number) { }
}

export class RecoveredCfaOneContactsSort implements Action {
  public type = ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_SORT;
  constructor(public payload: Sort) { }
}

export class RecoveredCfaOneContactsClearSearchTerm implements Action {
  public type = ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CLEAR_SEARCH_TERM;
  constructor(public payload: any = '') { }
}

export class RecoveredCfaOneContactsDisplayErrorText implements Action {
  public type = ActionTypes.RECOVERED_CFA_ONE_CONTACTS_DISPLAY_ERROR_TEXT;
  constructor(public payload: any = '') { }
}

export class GetRecoveredCfaOneContactsError implements Action {
  public type = ActionTypes.RECOVERED_CFA_ONE_CONTACTS_ERROR;
  constructor(public payload: any) { }
}

export class RecoveredCfaOneContactsSearch implements Action {
  public type = ActionTypes.RECOVERED_CFA_ONE_CONTACTS_SEARCH;
  constructor(public payload: any = '') { }
}

export class RecoveredCfaOneContactsSearchBy implements Action {
  public type = ActionTypes.RECOVERED_CFA_ONE_CONTACTS_SEARCH_BY;
  constructor(public payload: any = '') { }
}

export class GetRecoveredCfaOneContactsSuccess implements Action {
  public type = ActionTypes.RECOVERED_CFA_ONE_CONTACTS_SUCCESS;
  constructor(public payload: any) { }
}

export class ReopenReport implements Action {
  public type = ActionTypes.REOPEN_REPORT;
  constructor(public payload: any = '') { }
}

export class ReopenReportError implements Action {
  public type = ActionTypes.REOPEN_REPORT_ERROR;
  constructor(public payload: any = '') { }
}

export class ReopenReportSuccess implements Action {
  public type = ActionTypes.REOPEN_REPORT_SUCCESS;
  constructor(public payload: any = '') { }
}

export class SearchBy implements Action {
  public type = ActionTypes.SEARCH_BY;
  constructor(public payload: any = '') { }
}

export class SearchTerm implements Action {
  public type = ActionTypes.SEARCH_TERM;
  constructor(public payload: any = '') { }
}

export class SelectCustomer implements Action {
  public type = ActionTypes.SELECT_CUSTOMER;
  constructor(public payload: any) { }
}

export class SelectReport implements Action {
  public type = ActionTypes.SELECT_REPORT;
  constructor(public payload: any) { }
}

export class SelectRewards implements Action {
  public type = ActionTypes.SELECT_REWARDS;
  constructor(public payload: any[]) { }
}

export class SelectTemplate implements Action {
  public type = ActionTypes.SELECT_TEMPLATE;
  constructor(public payload: any) { }
}

export class SendResponse implements Action {
  public type = ActionTypes.SEND_RESPONSE;
  constructor(public payload: any = '') { }
}

export class SendResponseError implements Action {
  public type = ActionTypes.SEND_RESPONSE_ERROR;
  constructor(public payload: any) { }
}

export class SendResponseSuccess implements Action {
  public type = ActionTypes.SEND_RESPONSE_SUCCESS;
  constructor(public payload: any = '') { }
}

export class SortChange implements Action {
  public type = ActionTypes.SORT_CHANGE;
  constructor(public payload: any) { }
}

export class ChangePageAction implements Action {
  public type = ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_PAGE;
  constructor(public payload: number) { }
}

export class ChangePageSizeAction implements Action {
  public type = ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_PAGE_SIZE;
  constructor(public payload: number) { }
}

export class ChangeSpotlightContactsSortAction implements Action {
  public type = ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_SORT;
  constructor(public payload: Sort) { }
}

export class SpotlightContactsClearSearchTerm implements Action {
  public type = ActionTypes.SPOTLIGHT_CONTACTS_CLEAR_SEARCH_TERM;
  constructor(public payload: any = '') { }
}

export class SpotlightContactsDisplayErrorText implements Action {
  public type = ActionTypes.SPOTLIGHT_CONTACTS_DISPLAY_ERROR_TEXT;
  constructor(public payload: any = '') { }
}

export class SpotlightContactsSearch implements Action {
  public type = ActionTypes.SPOTLIGHT_CONTACTS_SEARCH;
  constructor(public payload: any = '') { }
}

export class SpotlightContactsSearchByAction implements Action {
  public type = ActionTypes.SPOTLIGHT_CONTACTS_SEARCH_BY;
  constructor(public payload: any = '') { }
}

export class SpotlightContactsSearchComplete implements Action {
  public type = ActionTypes.SPOTLIGHT_CONTACTS_SEARCH_COMPLETE;
  constructor(public payload: any) { }
}

export class SpotlightContactsSearchError implements Action {
  public type = ActionTypes.SPOTLIGHT_CONTACTS_SEARCH_ERROR;
  constructor(public payload: any) { }
}

export class UpdateActionHistoryNoteAction implements Action {
  public type = ActionTypes.UPDATE_ACTION_HISTORY_NOTE;
  constructor(public payload: any) { }
}

export class UpdateActionHistoryNoteError implements Action {
  public type = ActionTypes.UPDATE_ACTION_HISTORY_NOTE_ERROR;
  constructor(public payload: any) { }
}

export class UpdateActionHistoryNoteSuccess implements Action {
  public type = ActionTypes.UPDATE_ACTION_HISTORY_NOTE_SUCCESS;
  constructor(public payload: any) { }
}

export class UpdateCopy implements Action {
  public type = ActionTypes.UPDATE_COPY;
  constructor(public payload: string) { }
}

export class UpdateCustomerNoteAction implements Action {
  public type = ActionTypes.UPDATE_CUSTOMER_NOTE;
  constructor(public payload: any) { }
}

export class UpdateStatus implements Action {
  public type = ActionTypes.UPDATE_STATUS;
  constructor(public payload: any) { }
}

export class UpdateStatusError implements Action {
  public type = ActionTypes.UPDATE_STATUS_ERROR;
  constructor(public payload: any) { }
}

export class UpdateStatusSuccess implements Action {
  public type = ActionTypes.UPDATE_STATUS_SUCCESS;
  constructor(public payload: any) { }
}

export class UpdateTheme implements Action {
  public type = ActionTypes.UPDATE_THEME;
  constructor(public payload: any = '') { }
}

export class ViewReportFromMeet implements Action {
  public type = ActionTypes.VIEW_REPORT_FROM_MEET;
  constructor(public payload: any = '') { }
}

export class OpenServiceNowReport implements Action {
  public type = ActionTypes.OPEN_SERVICE_NOW_REPORT;
  constructor(public payload: any) { }
}

export class OpenServiceNowReportError implements Action {
  public type = ActionTypes.OPEN_SERVICE_NOW_REPORT_ERROR;
  constructor(public payload: any) { }
}

export class OpenServiceNowReportSuccess implements Action {
  public type = ActionTypes.OPEN_SERVICE_NOW_REPORT_SUCCESS;
  constructor(public payload: any) { }
}

interface Sort {
  order: string;
  prop: string;
}

export type Actions
  = CareChangeCareReportView
  | CareSetSelectedTabIndex
  | CfaOneChangeReportView
  | ChangePageAction
  | ChangePageSizeAction
  | ChangeSpotlightContactsSortAction
  | ClearSearchTerm
  | CloseActionHistoryNote
  | CloseAllOpenNotes
  | CloseCustomerNote
  | ClosedCareChangeCareReportView
  | ClosedCareContactsChangePage
  | ClosedCareContactsChangePageSize
  | ClosedCareContactsClearSearchTerm
  | ClosedCareContactsSearch
  | ClosedCareContactsSearchBy
  | ClosedCareContactsSort
  | CreateActionHistoryNoteAction
  | CreateActionHistoryNoteError
  | CreateActionHistoryNoteSuccess
  | CreateCustomerNoteAction
  | CustomerNoteActionComplete
  | CustomerNoteError
  | DecrementReward
  | DeleteCustomerNoteAction
  | DeleteCustomerNoteSuccess
  | EditCustomerNote
  | ErrorAction
  | FetchTemplateError
  | FetchTemplateSuccess
  | GetActivityHistoryError
  | GetActivityHistorySuccess
  | GetCareEmailDetails
  | GetCareEmailDetailsError
  | GetCareEmailDetailsSuccess
  | GetCareReportDetails
  | GetCareReportDetailsError
  | GetCareReportDetailsSuccess
  | GetCareReportsError
  | GetCareReportsSuccess
  | GetClosedCareContactsError
  | GetClosedCareContactsSuccess
  | GetCustomerNotesError
  | GetCustomerNotesSuccess
  | GetEligibleForRecoveryError
  | GetEligibleForRecoverySuccess
  | GetRecoveredCfaOneContactsError
  | GetRecoveredCfaOneContactsSuccess
  | IncrementReward
  | LoadCaresPage
  | OpenActionHistoryNote
  | OpenCustomerNote
  | OpenServiceNowReport
  | OpenServiceNowReportError
  | OpenServiceNowReportSuccess
  | PageChange
  | PageSizeChange
  | RecoveredCfaOneChangeReportView
  | RecoveredCfaOneContactsChangePage
  | RecoveredCfaOneContactsChangePageSize
  | RecoveredCfaOneContactsClearSearchTerm
  | RecoveredCfaOneContactsDisplayErrorText
  | RecoveredCfaOneContactsSearch
  | RecoveredCfaOneContactsSearchBy
  | RecoveredCfaOneContactsSort
  | ReopenReport
  | ReopenReportError
  | ReopenReportSuccess
  | SearchBy
  | SearchTerm
  | SelectCustomer
  | SelectReport
  | SelectRewards
  | SelectTemplate
  | SendResponse
  | SendResponseError
  | SendResponseSuccess
  | ShouldLoadRecoveredCfaOneContactsData
  | SortChange
  | SpotlightContactsClearSearchTerm
  | SpotlightContactsDisplayErrorText
  | SpotlightContactsSearch
  | SpotlightContactsSearchByAction
  | SpotlightContactsSearchComplete
  | SpotlightContactsSearchError
  | UpdateActionHistoryNoteAction
  | UpdateActionHistoryNoteError
  | UpdateActionHistoryNoteSuccess
  | UpdateCopy
  | UpdateCustomerNoteAction
  | UpdateStatus
  | UpdateStatusError
  | UpdateStatusSuccess
  | UpdateTheme
  | ViewReportFromMeet;
