import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  CARES_FETCH:                                type('[Profile] Fetch CARES data'),
  CEM_FETCH:                                  type('[Profile] Fetch CEM data'),
  CUSTOMER_PROFILE_FETCH:                     type('[Profile] Fetch all Profile data'),
  FOUND_DATA:                                 type('[Profile] Found Profile data'),
  ERROR:                                      type('[Profile] Error'),
  FETCH_CATERING_DATA:                        type('[Profile] Fetch catering data'),
  FETCH_TRANSACTION_DATA:                     type('[Profile] Fetch transaction data'),
  FETCH_TRANSACTION_STATS:                    type('[Profile] Fetch transaction stats'),
  FETCH_TRANSACTION_HISTORY:                  type('[Profile] Fetch transaction history'),
  FETCH_TRANSACTION_HISTORY_SUCCESS:          type('[Profile] Fetch transaction history success'),
  FETCH_OFFERS_SUCCESS:                       type('[Profile] Fetch offers success'),
  FETCH_OFFERS_ERROR:                         type('[Profile] Fetch offers error'),
  FETCH_MEMBERSHIP_SUCCESS:                   type('[Profile] Fetch membership success'),
  FETCH_RECOVER_EMAIL_DETAILS:                type('[Profile] Fetch recover email details'),
  OPEN_NOTE:                                  type('[Profile] Open note'),
  CLOSE_NOTE:                                 type('[Profile] Close note'),
  CREATE_NOTE:                                type('[Profile] Create note'),
  EDIT_NOTE:                                  type('[Profile] Edit note'),
  UPDATE_NOTE:                                type('[Profile] Update note'),
  DELETE_NOTE:                                type('[Profile] Delete note'),
  DELETE_NOTE_SUCCESS:                        type('[Profile] Delete note success'),
  NOTE_ERROR:                                 type('[Profile] Note Error'),
  NOTE_ACTION_COMPLETE:                       type('[Profile] Note action complete'),
  DOORDASH_RECOVERY_FETCH:                    type('[Profile] Fetch Door Dash Recovery Data'),
  UPDATE_SORTED_TRANSACTION_DETAILS_DATA:     type('[profile] Update Sorted Transaction Details Data'),
  DOORDASH_RECOVERY_FETCH_SUCCESS:            type('[Profile] Fetch Door Dash Recovery Data Success')
};

export class FetchCaresDataAction implements Action {
  public type = ActionTypes.CARES_FETCH;
  constructor(public payload: any) { }
}

export class FetchCemDataAction implements Action {
  public type = ActionTypes.CEM_FETCH;
  constructor(public payload: any) { }
}

export class FetchCustomerProfileAction implements Action {
  public type = ActionTypes.CUSTOMER_PROFILE_FETCH;
  constructor(public payload: any) { }
}

export class FoundDataAction implements Action {
  public type = ActionTypes.FOUND_DATA;
  constructor(public payload: any) { }
}

export class ErrorAction implements Action {
  public type = ActionTypes.ERROR;
  constructor(public payload: any) { }
}

export class FetchCateringData implements Action {
  public type = ActionTypes.FETCH_CATERING_DATA;
  constructor(public payload: any) { }
}

export class FetchTransactionData implements Action {
  public type = ActionTypes.FETCH_TRANSACTION_DATA;
  constructor(public payload: any) { }
}

export class FetchTransactionStats implements Action {
  public type = ActionTypes.FETCH_TRANSACTION_STATS;
  constructor(public payload: any) { }
}

export class FetchTransactionHistory implements Action {
  public type = ActionTypes.FETCH_TRANSACTION_HISTORY;
  constructor(public payload: any) { }
}

export class FetchTransactionHistorySuccess implements Action {
  public type = ActionTypes.FETCH_TRANSACTION_HISTORY_SUCCESS;
  constructor(public payload: any) { }
}

export class FetchOffersSuccess implements Action {
  public type = ActionTypes.FETCH_OFFERS_SUCCESS;
  constructor(public payload: any) { }
}

export class FetchMembershipSuccess implements Action {
  public type = ActionTypes.FETCH_MEMBERSHIP_SUCCESS;
  constructor(public payload: any) { }
}

export class FetchRecoverEmailDetails implements Action {
  public type = ActionTypes.FETCH_RECOVER_EMAIL_DETAILS;
  constructor(public payload: any) { }
}

export class OpenNoteAction implements Action {
  public type = ActionTypes.OPEN_NOTE;
  constructor(public payload: any) { }
}

export class CloseNoteAction implements Action {
  public type = ActionTypes.CLOSE_NOTE;
  constructor(public payload: any) { }
}

export class CreateNoteAction implements Action {
  public type = ActionTypes.CREATE_NOTE;
  constructor(public payload: any) { }
}

export class EditNoteAction implements Action {
  public type = ActionTypes.EDIT_NOTE;
  constructor(public payload: any) { }
}

export class UpdateNoteAction implements Action {
  public type = ActionTypes.UPDATE_NOTE;
  constructor(public payload: any) { }
}

export class DeleteNoteAction implements Action {
  public type = ActionTypes.DELETE_NOTE;
  constructor(public payload: any) { }
}

export class DeleteNoteSuccess implements Action {
  public type = ActionTypes.DELETE_NOTE_SUCCESS;
  constructor(public payload: any) { }
}

export class NoteErrorAction implements Action {
  public type = ActionTypes.NOTE_ERROR;
  constructor(public payload: any) { }
}

export class NoteCompleteAction implements Action {
  public type = ActionTypes.NOTE_ACTION_COMPLETE;
  constructor(public payload: any) { }
}

export class FetchDoorDashRecoveryData implements Action {
  public type = ActionTypes.DOORDASH_RECOVERY_FETCH;
  constructor(public payload: any) { }
}

export class FetchDoorDashRecoveryDataSuccess implements Action {
  public type = ActionTypes.DOORDASH_RECOVERY_FETCH_SUCCESS;
  constructor(public payload: any) { }
}

export class UpdateSortedTransactionDetailsData implements Action {
  public type = ActionTypes.UPDATE_SORTED_TRANSACTION_DETAILS_DATA;
  constructor(public payload: any) { }
}
export type Actions
  = FetchCaresDataAction
  | FetchCemDataAction
  | FetchCustomerProfileAction
  | FoundDataAction
  | ErrorAction
  | FetchCateringData
  | FetchTransactionData
  | FetchTransactionStats
  | FetchTransactionHistory
  | FetchTransactionHistorySuccess
  | FetchOffersSuccess
  | FetchMembershipSuccess
  | FetchRecoverEmailDetails
  | OpenNoteAction
  | CloseNoteAction
  | CreateNoteAction
  | EditNoteAction
  | UpdateNoteAction
  | DeleteNoteAction
  | DeleteNoteSuccess
  | NoteErrorAction
  | NoteCompleteAction
  | FetchDoorDashRecoveryData
  | UpdateSortedTransactionDetailsData
  | FetchDoorDashRecoveryDataSuccess;
