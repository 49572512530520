export class UnderstandConstants {
  public static defaultSettingsCategories(): any[] {
    return ['trends'] ;
  }

  public static get errorText(): string {
    return 'There was an error loading the Understand tiles. Please try again later.';
  }

  public static get RecoveryTypeFilterTitle(): string {
    return 'Recovery Type';
  }

  public static get RecoveryTypeFilterRewardsRaw(): string {
    return 'REFUND_TYPE_REWARD';
  }

  public static get RecoveryTypeFilterRewardsDisplay(): string {
    return 'Reward(s)';
  }

  public static get RecoveryTypeFilterPointsRaw(): string {
    return 'REFUND_TYPE_POINTS';
  }

  public static get RecoveryTypeFilterPointsDisplay(): string {
    return 'Points';
  }
}
