import { Action } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
  FETCH_CALENDAR_EVENTS:                      type('[Calendar] Fetch Calendar Events'),
  FETCH_CALENDAR_EVENTS_SUCCESS:              type('[Calendar] Fetch Calendar Events Success'),
  FETCH_CALENDAR_EVENTS_FAILURE:              type('[Calendar] Fetch Calendar Events Failure'),
  FETCH_REWARD_DETAILS:                       type('[Calendar] Fetch Reward Details'),
  FETCH_REWARD_DETAILS_SUCCESS:               type('[Calendar] Fetch Reward Details Success'),
  FETCH_REWARD_DETAILS_FAILURE:               type('[Calendar] Fetch Reward Details Failure'),
  FETCH_EVENT_DETAILS:                        type('[Calendar] Fetch Event Details'),
  FETCH_EVENT_DETAILS_SUCCESS:                type('[Calendar] Fetch Event Details Success'),
  FETCH_EVENT_DETAILS_FAILURE:                type('[Calendar] Fetch Event Details Failure'),
  FETCH_EMAIL_DETAILS:                        type('[Calendar] Fetch Email Details'),
  FETCH_EMAIL_DETAILS_SUCCESS:                type('[Calendar] Fetch Email Details Success'),
  FETCH_EMAIL_DETAILS_FAILURE:                type('[Calendar] Fetch Email Details Failure'),
  FETCH_BONUS_POINT_DETAILS:                  type('[Calendar] Fetch Bonus Point Details'),
  FETCH_BONUS_POINT_DETAILS_SUCCESS:          type('[Calendar] Fetch Bonus Point Details Success'),
  FETCH_BONUS_POINT_DETAILS_FAILURE:          type('[Calendar] Fetch Bonus Point Details Failure'),
  FETCH_BONUS_POINT_STATS_SUCCESS:            type('[Calendar] Fetch Bonus Point Stats Success'),
  FETCH_BONUS_POINT_STATS_FAILURE:          type('[Calendar] Fetch Bonus Point Stats Failure'),
  FETCH_COMMUNITY_CARE_DETAILS:               type('[Calendar] Fetch Community Care Details'),
  FETCH_COMMUNITY_CARE_DETAILS_SUCCESS:       type('[Calendar] Fetch Community Care Details Success'),
  FETCH_COMMUNITY_CARE_DETAILS_FAILURE:       type('[Calendar] Fetch Community Care Details Failure'),
  FETCH_THIRD_PARTY_DETAILS:                  type('[Calendar] Fetch Third Party Details'),
  FETCH_THIRD_PARTY_DETAILS_SUCCESS:          type('[Calendar] Fetch Third Party Details Success'),
  FETCH_THIRD_PARTY_DETAILS_FAILURE:          type('[Calendar] Fetch Third Party  Details Failure'),
  FETCH_FREE_ITEM_IN_CART_DETAILS:            type('[Calendar] Fetch Free Item in Cart Details'),
  FETCH_FREE_ITEM_IN_CART_DETAILS_SUCCESS:    type('[Calendar] Fetch Free Item in Cart  Details Success'),
  FETCH_FREE_ITEM_IN_CART_DETAILS_FAILURE:    type('[Calendar] Fetch Free Item in Cart  Details Failure'),
  FETCH_FREE_ITEM_IN_CART_STATS_SUCCESS:      type('[Calendar] Fetch Free Item in Cart  Stats Success'),
  FETCH_FREE_ITEM_IN_CART_STATS_FAILURE:      type('[Calendar] Fetch Free Item in Cart  Stats Failure'),
  FETCH_THIRD_PARTY_STATS_SUCCESS:            type('[Calendar] Fetch Third Party Stats Success'),
  FETCH_THIRD_PARTY_STATS_FAILURE:            type('[Calendar] Fetch Third Party Stats Failure'),
  CALENDAR_DATES_CHANGED:                     type('[Calendar] Calendar Dates Changed'),
  CALENDAR_TOGGLED:                           type('[Calendar] Calendar Toggled'),
  CAMPAIGNS_TOGGLED:                          type('[Calendar] Campaigns Toggled'),
  CALENDAR_SEARCH_TERM_CHANGED:               type('[Calendar] Calendar Search Term Changed'),
  CALENDAR_SEARCH_CRITERIA_CHANGED:           type('[Calendar] Calendar Search Criteria Changed'),
  CALENDAR_CLEAR_SEARCH:                      type('[Calendar] calendar Clear Search'),
  CALENDAR_SWITCH_LOCATIONS:                  type('[Calendar] Calendar Switch Locations'),
  FETCH_AUTOMATED_REWARD_DETAILS:             type('[Calendar] Fetch Automated Reward Details'),
  FETCH_AUTOMATED_REWARD_DETAILS_SUCCESS:     type('[Calendar] Fetch Automated Reward Details Success'),
  FETCH_AUTOMATED_REWARD_DETAILS_FAILURE:     type('[Calendar] Fetch Automated Reward Details Failure')
};

export class FetchCalendarEvents implements Action {
    public type = ActionTypes.FETCH_CALENDAR_EVENTS;
    constructor(public payload: any = '') { }
}

export class FetchCalendarEventsSuccess implements Action {
    public type = ActionTypes.FETCH_CALENDAR_EVENTS_SUCCESS;
    constructor(public payload: any = '', public locationNumber: string) { }
}

export class FetchCalendarEventsFailure implements Action {
    public type = ActionTypes.FETCH_CALENDAR_EVENTS_FAILURE;
    constructor(public payload: any = '') { }
}

export class FetchRewardDetails implements Action {
    public type = ActionTypes.FETCH_REWARD_DETAILS;
    constructor(public payload: any = '') { }
}

export class FetchRewardDetailsSuccess implements Action {
    public type = ActionTypes.FETCH_REWARD_DETAILS_SUCCESS;
    constructor(public payload: any = '') { }
}

export class FetchRewardDetailsFailure implements Action {
    public type = ActionTypes.FETCH_REWARD_DETAILS_FAILURE;
    constructor(public payload: any = '') { }
}

export class FetchAutomatedRewardDetails implements Action {
    public type = ActionTypes.FETCH_AUTOMATED_REWARD_DETAILS;
    constructor(public payload: any = '') { }
}

export class FetchAutomatedRewardDetailsSuccess implements Action {
    public type = ActionTypes.FETCH_AUTOMATED_REWARD_DETAILS_SUCCESS;
    constructor(public payload: any = '') { }
}

export class FetchAutomatedRewardDetailsFailure implements Action {
    public type = ActionTypes.FETCH_AUTOMATED_REWARD_DETAILS_FAILURE;
    constructor(public payload: any = '') { }
}

export class FetchEventDetails implements Action {
    public type = ActionTypes.FETCH_EVENT_DETAILS;
    constructor(public payload: any = '') { }
}

export class FetchEventDetailsSuccess implements Action {
    public type = ActionTypes.FETCH_EVENT_DETAILS_SUCCESS;
    constructor(public payload: any = '') { }
}

export class FetchEventDetailsFailure implements Action {
    public type = ActionTypes.FETCH_EVENT_DETAILS_FAILURE;
    constructor(public payload: any = '') { }
}

export class FetchEmailDetails implements Action {
    public type = ActionTypes.FETCH_EMAIL_DETAILS;
    constructor(public payload: any = '') { }
}

export class FetchEmailDetailsSuccess implements Action {
    public type = ActionTypes.FETCH_EMAIL_DETAILS_SUCCESS;
    constructor(public payload: any = '') { }
}

export class FetchEmailDetailsFailure implements Action {
    public type = ActionTypes.FETCH_EMAIL_DETAILS_FAILURE;
    constructor(public payload: any = '') { }
}

export class FetchBonusPointDetails implements Action {
    public type = ActionTypes.FETCH_BONUS_POINT_DETAILS;
    constructor(public payload: any = '') { }
}

export class FetchBonusPointDetailsSuccess implements Action {
    public type = ActionTypes.FETCH_BONUS_POINT_DETAILS_SUCCESS;
    constructor(public payload: any = '') { }
}

export class FetchBonusPointDetailsFailure implements Action {
    public type = ActionTypes.FETCH_BONUS_POINT_DETAILS_FAILURE;
    constructor(public payload: any = '') { }
}

export class FetchBonusPointStatsSuccess implements Action {
  public type = ActionTypes.FETCH_BONUS_POINT_STATS_SUCCESS;
  constructor(public payload: any = '') { }
}

export class FetchBonusPointStatsFailure implements Action {
  public type = ActionTypes.FETCH_BONUS_POINT_STATS_FAILURE;
  constructor(public payload: any = '') { }
}

export class FetchCommunityCareDetails implements Action {
  public type = ActionTypes.FETCH_COMMUNITY_CARE_DETAILS;
  constructor(public payload: any = '') { }
}

export class FetchCommunityCareDetailsSuccess implements Action {
  public type = ActionTypes.FETCH_COMMUNITY_CARE_DETAILS_SUCCESS;
  constructor(public payload: any = '') { }
}

export class FetchCommunityCareDetailsFailure implements Action {
  public type = ActionTypes.FETCH_COMMUNITY_CARE_DETAILS_FAILURE;
  constructor(public payload: any = '') { }
}

export class FetchThirdPartyDetails implements Action {
  public type = ActionTypes.FETCH_THIRD_PARTY_DETAILS;
  constructor(public payload: any = '') { }
}

export class FetchThirdPartyDetailsSuccess implements Action {
  public type = ActionTypes.FETCH_THIRD_PARTY_DETAILS_SUCCESS;
  constructor(public payload: any = '') { }
}

export class FetchThirdPartyDetailsFailure implements Action {
  public type = ActionTypes.FETCH_THIRD_PARTY_DETAILS_FAILURE;
  constructor(public payload: any = '') { }
}

export class FetchFreeItemInCartDetails implements Action {
    public type = ActionTypes.FETCH_FREE_ITEM_IN_CART_DETAILS;
    constructor(public payload: any = '') { }
}

export class FetchFreeItemInCartDetailsSuccess implements Action {
    public type = ActionTypes.FETCH_FREE_ITEM_IN_CART_DETAILS_SUCCESS;
    constructor(public payload: any = '') { }
}

export class FetchFreeItemInCartDetailsFailure implements Action {
    public type = ActionTypes.FETCH_FREE_ITEM_IN_CART_DETAILS_FAILURE;

    constructor(public payload: any = '') {
    }
}

export class FetchFreeItemInCartStatsSuccess implements Action {
    public type = ActionTypes.FETCH_FREE_ITEM_IN_CART_STATS_SUCCESS;
    constructor(public payload: any = '') { }
}

export class FetchFreeItemInCartStatsFailure implements Action {
    public type = ActionTypes.FETCH_FREE_ITEM_IN_CART_STATS_FAILURE;

    constructor(public payload: any = '') {
    }
}
export class FetchThirdPartyStatsSuccess implements Action {
  public type = ActionTypes.FETCH_THIRD_PARTY_STATS_SUCCESS;
  constructor(public payload: any = '') { }
}

export class FetchThirdPartyStatsFailure implements Action {
  public type = ActionTypes.FETCH_THIRD_PARTY_STATS_FAILURE;
  constructor(public payload: any = '') { }
}

export class CalendarDatesChanged implements Action {
    public type = ActionTypes.CALENDAR_DATES_CHANGED;
    constructor(public payload: any = '') { }
}

export class CalendarToggled implements Action {
    public type = ActionTypes.CALENDAR_TOGGLED;
    constructor(public payload) { }
}

export class CampaignsToggled implements Action {
    public type = ActionTypes.CAMPAIGNS_TOGGLED;
    constructor(public payload: any = '') { }
}

export class CalendarSearchTermChanged implements Action {
    public type = ActionTypes.CALENDAR_SEARCH_TERM_CHANGED;
    constructor(public payload: any = '') { }
}

export class CalendarSearchCriteriaChanged implements Action {
    public type = ActionTypes.CALENDAR_SEARCH_CRITERIA_CHANGED;
    constructor(public payload: any = '') { }
}

export class CalendarClearSearch implements Action {
    public type = ActionTypes.CALENDAR_CLEAR_SEARCH;
    constructor(public payload: any = '') { }
}

export class CalendarSwitchLocations implements Action {
  public type = ActionTypes.CALENDAR_SWITCH_LOCATIONS;
  constructor(public payload: any = '') { }
}
export type Actions
  = FetchCalendarEvents
  | FetchCalendarEventsSuccess
  | FetchCalendarEventsFailure
  | FetchRewardDetails
  | FetchRewardDetailsSuccess
  | FetchRewardDetailsFailure
  | FetchEventDetails
  | FetchEventDetailsSuccess
  | FetchEventDetailsFailure
  | FetchEmailDetails
  | FetchEmailDetailsSuccess
  | FetchEmailDetailsFailure
  | FetchBonusPointDetails
  | FetchBonusPointDetailsSuccess
  | FetchBonusPointDetailsFailure
  | FetchBonusPointStatsSuccess
  | FetchBonusPointStatsFailure
  | FetchCommunityCareDetails
  | FetchCommunityCareDetailsSuccess
  | FetchCommunityCareDetailsFailure
  | FetchThirdPartyDetails
  | FetchThirdPartyDetailsSuccess
  | FetchThirdPartyDetailsFailure
  | FetchFreeItemInCartDetails
  | FetchFreeItemInCartDetailsSuccess
  | FetchFreeItemInCartDetailsFailure
  | CalendarDatesChanged
  | CalendarToggled
  | CampaignsToggled
  | CalendarSearchTermChanged
  | CalendarSearchCriteriaChanged
  | CalendarClearSearch
  | CalendarSwitchLocations
  | FetchAutomatedRewardDetails
  | FetchAutomatedRewardDetailsSuccess
  | FetchAutomatedRewardDetailsFailure
  | FetchThirdPartyStatsSuccess
  | FetchThirdPartyStatsFailure
  | FetchFreeItemInCartStatsSuccess
  | FetchFreeItemInCartStatsFailure;
