export class AppConstants {
  public static get extraSmall(): string {
    return 'xs';
  }

  public static get small(): string {
    return 'sm';
  }

  public static get feedbackUrl(): string {
    return 'https://forms.office.com/r/9ifweW89NV';
  }

  public static get localFaviconUrl(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/Location_Marker.png';
  }

  public static get qaFaviconUrl(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/QA_Favicon_Icon_Black.svg';
  }

  public static get productionFaviconUrl(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/CFA_Favicon_Red_Square.svg';
  }

  public static get genericApiError(): string {
    return 'Something went wrong. Please check your internet connection and try again later.';
  }

  public static get rewardTimeoutError(): string {
    return 'Oh no, it looks like your session has timed out. ' +
      'Check calendar in five minutes to verify if the reward was sent out before resending.';
  }

  public static get genericTimeoutError(): string {
    return 'Oh no, it looks like your session has timed out. Please try again.';
  }

  public static get signatureMembership(): string {
    return 'signatureMembership';
  }

  public static get enableCaresOVCC(): string {
    return 'enableCaresOVCC';
  }

  public static get calendarSearchFeature(): string {
    return 'calendarSearch';
  }

  public static get meetPage(): string {
    return 'meet';
  }

  public static get engagePage(): string {
    return 'engage';
  }

  public static get digitalRefundsFlag(): string {
    return 'digitalRefund';
  }

  public static get maintenanceFlag(): string {
    return 'maintenanceSpotlight';
  }
}
