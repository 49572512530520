export interface Promotion {
  id: string;
  title: string;
  type: string;
  startDate: number;
  endDate: number;
  status: string;
}

export function setCachedPromotions(pendingPromotions: Promotion[], initialSize: number, locationNumber: string): void {
  let cacheName = 'pendingPromotions:' + locationNumber
  if (pendingPromotions.length < initialSize) {
    if (pendingPromotions.length > 0) {
      localStorage.setItem(cacheName, JSON.stringify(pendingPromotions))
    } else {
      localStorage.removeItem(cacheName)
    }
  }
}
