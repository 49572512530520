<mat-chip-list *ngIf="customerFilter && customerFilter.length > 0"
               class="additional-customer-filter-chips__chip-list">
  <filter-chip *ngFor="let customer of customerFilter | slice : startFromIndex : customerFilter.length > 60 ? 60 : customerFilter.length"
               [title]="customer.fullName"
               [isMobile]="isMobile"
               (click)="removeClicked.emit(customer)">
  </filter-chip>
</mat-chip-list>
<div *ngIf="customerFilter && customerFilter.length === 0"
     class="additional-customer-filter-chips__no-customers-msg">
     <h4>{{noCustomersAvailableMsg}}</h4>
     <h5>Please select another saved group.</h5>
</div>
<div *ngIf="(isSelectedSavedGroupLarge && customerFilter.length > 0) || customerFilter.length > 60"
     class="additional-customer-filter-chips__max-customers-msg"
     [ngClass]="{'additional-customer-filter-chips__mb-max-customers-msg' : isMobile}">
  {{maxCustomersMessage}}
</div>
