import * as discover from '../actions/discover';
import * as engagement from '../actions/engagement';
import * as user from '../actions/user';
import { DiscoverConstants } from '../discover/discover.constants';
import * as R from 'ramda';
import { Promotion, setCachedPromotions } from '../models/cache/promotion.model'

export interface State {
  deliveryEnabled: boolean;
  error: string;
  failedCampaign: any;
  loading: boolean;
  tiles: {
    [key: string]: DiscoverTile
  };
}

export const initialState: State = {
  deliveryEnabled: false,
  error: null,
  failedCampaign: null,
  loading: true,
  tiles: {}
};

export function reducer(
  state: State = initialState,
  action: discover.Actions | engagement.Actions | user.Actions
) {
  switch (action.type) {
    case discover.ActionTypes.FAILED_CAMPAIGN_MARK_RESOLVED: {
      const failedCampaign = action.payload;
      return { ...state, failedCampaign };
    }

    case discover.ActionTypes.FAILED_CAMPAIGN_MARK_RESOLVED_FAILURE:
    case discover.ActionTypes.LOAD_TILE_DATA:
    case discover.ActionTypes.LOAD_TILE_DATA_ERROR:
    case discover.ActionTypes.LOAD_TILE_DATA_SUCCESS:
    case engagement.ActionTypes.CREATE_AUTOMATION_SUCCESS:
    case engagement.ActionTypes.EDIT_AUTOMATION_SUCCESS:
    case engagement.ActionTypes.SEND_EMAIL_SUCCESS:
    case engagement.ActionTypes.SEND_REWARD_SUCCESS:
    case engagement.ActionTypes.STOP_AUTOMATION_SUCCESS:
    case engagement.ActionTypes.STOP_FREE_IN_CART_SUCCESS: {
      const payload = action.payload;
      if (payload) {
        const id = payload.recommendationType || payload.id;
        let tiles = { ...state.tiles, [id]: tile(state.tiles[id], action) };

        return {
          ...state,
          tiles
        };
      } else {
        return state;
      }
    }

    case discover.ActionTypes.FAILED_CAMPAIGN_MARK_RESOLVED_SUCCESS: {
      const failedCampaign = state.failedCampaign;
      failedCampaign.markResolved = true;
      return { ...state, failedCampaign };
    }

    case discover.ActionTypes.LOAD_DISCOVER_TILES_ERROR: {
      let error = action.payload;
      return { ...state, error, loading: false };
    }

    case discover.ActionTypes.LOAD_DISCOVER_TILES_SUCCESS: {
      let tiles = action.payload;
      const deliveryEnabled = state.deliveryEnabled;

      let cacheName = 'pendingPromotions:' + action.locationNumber
      let pendingPromotions: Promotion[] = JSON.parse(localStorage.getItem(cacheName))
      pendingPromotions = pendingPromotions ? pendingPromotions : []
      let newPendingPromotions: Promotion[] = []

      pendingPromotions.forEach(promotion => {
        applyCachedPromotionsToDiscoverTile(promotion, newPendingPromotions, tiles)
      })

      setCachedPromotions(newPendingPromotions, pendingPromotions.length, action.locationNumber)

      // If delivery is not enabled for this location then remove the delivery promotion tile from displaying
      if (!deliveryEnabled) {
        let deliveryIndex = R.findIndex(R.propEq('id', DiscoverConstants.deliveryTileId))(tiles);
        tiles.splice(deliveryIndex, 1);
      }

      let setLoading = tiles.map((tile) => ({
        ...tile,
        loading: (tile.category !== DiscoverConstants.categoryCampaign &&
          tile.category !== DiscoverConstants.promotion &&
          tile.category !== DiscoverConstants.categoryCommunityCare &&
          tile.category !== DiscoverConstants.thirdPartyPromotion &&
          tile.category !== DiscoverConstants.freeItemInCartPromotion
        )
      }));
      let tilesById = R.groupBy(R.prop('id'), setLoading);
      tilesById = R.map((x) => x[0], tilesById);
      return { ...state, tiles: tilesById, loading: false, error: null };
    }

    case discover.ActionTypes.LOAD_TILE_FILTERS_ERROR: {
      const filterTiles = DiscoverConstants.filterDependentTiles;
      let errorTiles = {};
      filterTiles.map((id) => {
        let filterTile = state.tiles[id];
        if (filterTile) {
          errorTiles = { ...errorTiles, [id]: tile(state.tiles[id], action) };
        }
      });

      let tiles = { ...state.tiles, ...errorTiles };
      return {
        ...state,
        tiles
      };
    }

    case engagement.ActionTypes.SEND_FREE_IN_CART_SUCCESS:
    case engagement.ActionTypes.SEND_PROMOTION_SUCCESS: {
      const payload = action.payload;
      if (payload) {
        const id = payload.flowId;
        let tiles = { ...state.tiles, [id]: tile(state.tiles[id], action) };

        let cacheName = 'pendingPromotions:' + payload.locationNumber
        let pendingPromotions: Promotion[] = JSON.parse(localStorage.getItem(cacheName))
        const promotion: Promotion = {
          id: payload.id,
          title: payload.campaignName,
          type: payload.flowId,
          startDate: payload.startDate / 1000,
          endDate: payload.endDate / 1000,
          status: 'pendingCreation'
        }
        pendingPromotions = pendingPromotions ? pendingPromotions : []
        pendingPromotions.push(promotion)

        localStorage.setItem(cacheName, JSON.stringify(pendingPromotions))

        return {
          ...state,
          tiles
        };
      } else {
        return state;
      }
    }

    case user.ActionTypes.DISMISS_BANNER_SUCCESS: {
      let banner = action.payload;
      if (banner != DiscoverConstants.welcomeModalBanner) {
        let tiles = state.tiles;
        tiles = R.filter((tile) => tile.category !== 'banner', tiles);
        return { ...state, tiles };
      } else {
        return state;
      }
    }

    case user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS: {
      const location = action.payload;
      const deliveryEnabled = location.delivery;

      return { ...state, deliveryEnabled, loading: true };
    }

    case user.ActionTypes.USER_DATA_FOUND: {
      let userData = action.payload;
      let selectedLocation = userData.locations[0];

      return {
        ...state,
        deliveryEnabled: selectedLocation.deliveryEnabled
      };
    }

    default: {
      return state;
    }
  }
}

function applyCachedPromotionsToDiscoverTile(promotion: Promotion, newPendingPromotions: Promotion[], tiles: any[]): void {
  const tileIndex = R.findIndex(R.propEq('id', promotion.type))(tiles)
  // If cached promotion is creating and it is not in the tile tileObjectId
  // then add it and keep it in the cache until it's there
  if (promotion.status == 'pendingCreation' && tiles[tileIndex].tileObjectId == null) {
    tiles[tileIndex].tileObjectId = promotion.id
    tiles[tileIndex].automated = true
    newPendingPromotions.push(promotion)
    // If cached promotion is deleting and it is in the tile tileObjectId
    // then remove it and keep it in the cache until it isn't there
  } else if (promotion.status == 'pendingDeletion' && tiles[tileIndex].tileObjectId
    && tiles[tileIndex].tileObjectId == promotion.id) {
    delete tiles[tileIndex].tileObjectId
    tiles[tileIndex].automated = false
    newPendingPromotions.push(promotion)
  }
}

export interface DiscoverTile {
  automated?: boolean;
  automationCancellationBody?: string;
  automationCancellationTitle?: string;
  automationConfirmationText?: string;
  automationCustomerPhrase?: string;
  automationEnabled?: boolean;
  automationOptionEnabled?: boolean;
  buttonText?: string;
  buttonUrl?: string;
  category?: string;
  count: number;
  createdAt?: Date;
  customerEmailText?: string;
  customerRewardText?: string;
  dataType?: string;
  descriptionText?: {
    descriptor?: string;
    noteText?: string;
    primary?: string;
    secondary?: string;
    secondarySubText?: string;
    suggestedUseText?: string;
    summary?: string;
  };
  disableEngagement?: boolean;
  displayBanner?: boolean;
  displayImageUrl?: string;
  displayNewIcon?: boolean;
  editableCustomerSelection?: boolean;
  emailId?: string;
  enabled?: boolean;
  error?: string;
  errorText?: string;
  eventIds?: string[];
  externalUrl?: string;
  filters?: any[];
  helpText?: string;
  id?: string;
  imageUrl?: string;
  learnMore?: boolean;
  loading: boolean;
  multipleSendsEnabled?: boolean;
  order?: number;
  percentage?: number;
  rewardDisable?: boolean;
  sendEmail?: boolean;
  sendPromotion?: boolean;
  sendTreat?: boolean;
  subtitle?: string;
  tileCritical?: boolean;
  tileObjectDate?: string;
  tileObjectId?: string;
  tileText?: string;
  title?: string;
  treatIds?: string[];
  type?: string;
  updateAt?: Date;
}

const initialDiscoverTileState: DiscoverTile = {
  automated: false,
  count: 0,
  loading: true,
  type: ''
};

export function tile(
  state = initialDiscoverTileState,
  action: discover.Actions | engagement.Actions
) {
  switch (action.type) {
    case discover.ActionTypes.FAILED_CAMPAIGN_MARK_RESOLVED_FAILURE:
    case discover.ActionTypes.LOAD_TILE_DATA_ERROR: {
      const payload = action.payload;
      return { ...state, ...payload, loading: false };
    }

    case discover.ActionTypes.LOAD_TILE_DATA: {
      return { ...state, loading: true };
    }

    case discover.ActionTypes.LOAD_TILE_DATA_SUCCESS: {
      const payload = action.payload;

      if (state.type === 'stat' || state.type === 'text') {
        return { ...state, loading: false, ...payload };
      } else if (state.type === 'list') {
        return { ...state, loading: false, filters: payload.filters };
      } else {
        return state;
      }
    }

    case discover.ActionTypes.LOAD_TILE_FILTERS_ERROR: {
      const error = action.payload;
      return { ...state, error, loading: false };
    }

    case engagement.ActionTypes.CREATE_AUTOMATION_SUCCESS:
    case engagement.ActionTypes.EDIT_AUTOMATION_SUCCESS:
    case engagement.ActionTypes.SEND_EMAIL_SUCCESS:
    case engagement.ActionTypes.SEND_REWARD_SUCCESS:
    case engagement.ActionTypes.STOP_FREE_IN_CART_SUCCESS:
    case engagement.ActionTypes.STOP_AUTOMATION_SUCCESS: {
      const payload = action.payload;

      return {
        ...state,
        automated: payload.automated,
        count: payload.count,
        disableEngagement: payload.disableEngagement,
        error: null,
        loading: false
      };
    }

    case engagement.ActionTypes.SEND_PROMOTION_SUCCESS:
    case engagement.ActionTypes.SEND_FREE_IN_CART_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        automated: payload.automated,
        error: null,
        loading: false,
        tileObjectId: payload.fieldId,
        fieldId: payload.fieldId,
      };
    }

    default: {
      return state;
    }
  }
}

export const getDiscoverError = (state: State): string => state.error;

export const getDiscoverLoading = (state: State): boolean => state.loading;

export const getDiscoverTiles = (state: State): DiscoverTile[] => R.values(state.tiles);
