import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import { UserService } from '../core/user';
import { AnalyticsService } from '../core/analytics';
import { ErrorHandlerService } from '../core/error-handler';
import { UserLocation } from '../models/location.model';
import { ProfileConstants } from './customer-profile.constants';
import * as moment from 'moment-timezone';
import { environment } from 'spotlight-ui/environments/environment';

@Injectable()
export class CustomerProfileService {
  constructor(
    private http: HttpClient,
    private us: UserService,
    private ehs: ErrorHandlerService,
    private analytics: AnalyticsService
  ) { }


  public loadCateringData(id: string, req: any) {
    return this.us.getSelectedLocation().pipe(
      mergeMap((loc) => {
        let baseCustomerUrl = '/api/location/';
        let url = baseCustomerUrl + loc.locationNumber + '/cateringTransaction/history/' + id;

        return this.http.post(environment.spotlightApiUrl + url, req).pipe(
          map((res: any) => res.responseObject),
          catchError((err) => throwError(err))
        );
      })
    );
  }

  public getCustomerInformation(id) {
    return this.us.getSelectedLocation().pipe(
      mergeMap((loc: UserLocation) => {
        let baseLocationUrl = '/api/location/';
        let url = baseLocationUrl + loc.locationNumber + '/customer/' + id;
        let req = {};

        return this.http.get(environment.spotlightApiUrl + url, req).pipe(
          map((res) => res),
          catchError((err) => throwError(err))
        );
      })
    );
  }

  public loadTransactionStats(id: string, req: any) {
    return this.us.getSelectedLocation().pipe(
      mergeMap((loc) => {
        if (loc) {
          let baseCustomerUrl = '/api/location/';
          let url = baseCustomerUrl + loc.locationNumber + '/transaction/stats/' + id;

          return this.http.post(environment.spotlightApiUrl + url, req).pipe(
            map((res: any) => res.responseObject),
            catchError((error) => throwError(error))
          );
        }
      })
    );
  }

  public loadTransactionHistory(id: string, req: any) {
    return this.us.getSelectedLocation().pipe(
      mergeMap((loc) => {
        if (loc) {
          let baseCustomerUrl = '/api/location/';
          let url = baseCustomerUrl + loc.locationNumber + '/transaction/history/' + id;

          return this.http.post(environment.spotlightApiUrl + url, req).pipe(
            map((res: any) => res.responseObject),
            catchError((error) => throwError(error))
          );
        }
      })
    );
  }

  public getCEMData(id: string) {
    return this.us.getSelectedLocation().pipe(
      mergeMap((loc: UserLocation) => {
        let baseUrl = '/api/location/';
        let url = baseUrl + loc.locationNumber + '/cem/customer/' + id;
        return this.http.get(environment.spotlightApiUrl + url).pipe(
          map((res: any) => res.responseObject || {}),
          map((CEMData) => CEMData)
        );
      })
    );
  }

  public getCaresData(id: string) {
    return this.us.getSelectedLocation().pipe(
      mergeMap((loc: UserLocation) => {
        let days = ProfileConstants.defaultCareContactsDays;
        let req = {
          fromDate: moment().subtract(days, 'days').format('YYYY-MM-DD'),
          toDate:  moment().format('YYYY-MM-DD')
        };
        let baseUrl = '/api/location/';
        let url = baseUrl + loc.locationNumber + '/cares/customer/' + id;
        return this.http.post(environment.spotlightApiUrl + url, req).pipe(map((res: any) => res.responseObject || {}));
      })
    );
  }

  public getDigitalRefundsData(id: string) {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/digitalRefundDetails/${id}`;
        return this.http.get(environment.spotlightApiUrl + url).pipe(
          map((res: any) => res.responseObject),
          catchError((error) => throwError(error))
        );
      })
    );
  }

  public getActionHistory(id: string) {
    return this.us.getSelectedLocation().pipe(
      switchMap((location) => {
        const { locationNumber } = location;
        const url = `/api/location/${locationNumber}/recovery/history/${id}`;
        return this.http.get(environment.spotlightApiUrl + url).pipe(
          map((res: any) => res.responseObject),
          catchError((error) => throwError(error))
        );
      })
    );
  }

  public getRecoverEmailDetails(id: string) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/recovery/ticket/history/email/${id}`;
        return this.http.get(environment.spotlightApiUrl + url).pipe(
          map((res: any) => res.responseObject),
          catchError((error) => throwError(error))
        );
      })
    );
  }

  public createNote(note) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let baseNoteUrl = '/api/location/';
        let url = baseNoteUrl + loc.locationNumber + '/notes' + '/' + note.cfaUid;
        let req = note.note;

        return this.http.post(environment.spotlightApiUrl + url, req).pipe(
          map((res: any) => {
            this.analytics.sendMParticleEvent('Note Created', {});
            let response = { ...res.responseObject, id: note.cfaUid };
            return response || {};
          }),
          catchError((error) => {
            this.analytics.sendMParticleEvent('Create Note Failed', {});
            return this.ehs.handleError(error);
          })
        );
      })
    );
  }

  public updateNote(note) {
    let baseNoteUrl = '/api/location/';
    let url = baseNoteUrl + note.locationNumber + '/notes' + '/' + note.cfaUid + '/' + note.noteId;
    let req = note.note;

    return this.http.put(environment.spotlightApiUrl + url, req).pipe(
      map((res: any) => {
        this.analytics.sendMParticleEvent('Note Updated', {});
        let response = { ...res.responseObject, id: note.cfaUid };
        return response || {};
      }),
      catchError((error) => {
        this.analytics.sendMParticleEvent('Update Note Failed', {});
        return this.ehs.handleError(error);
      })
    );
  }

  public deleteNote(note) {
    let baseNoteUrl = '/api/location/';
    let url = baseNoteUrl + note.locationNumber + '/notes' + '/' + note.cfaUid + '/' + note.noteId;

    return this.http.delete(environment.spotlightApiUrl + url).pipe(
      map((res: any) => {
        this.analytics.sendMParticleEvent('Note Deleted', {});
        let response = { ...res.responseObject, id: note.cfaUid };
        return response || {};
      }),
      catchError((error) => {
        this.analytics.sendMParticleEvent('Delete Note Failed', {});
        return this.ehs.handleError(error);
      })
    );
  }

  public getCustomerOffers(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let baseCustomerUrl = '/api/location/';
        let url = baseCustomerUrl + loc.locationNumber + '/rewards/' + id;
        return this.http.get(environment.spotlightApiUrl + url).pipe(
          map((res: any) => res.responseObject),
          catchError((error) => throwError(error))
        );
      })
    );
  }

  public getMembershipDetails(id) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let baseCustomerUrl = '/api/location/';
        let url = baseCustomerUrl + loc.locationNumber + '/membership/' + id;
        return this.http.get(environment.spotlightApiUrl + url).pipe(
          map((res: any) => res),
          catchError((error) => throwError(error))
        );
      })
    );
  }

  public getDoorDashRecoveryData(id: string) {
    return this.us.getSelectedLocation().pipe(
      mergeMap((loc: UserLocation) => {
        let baseUrl = '/api/location/';
        let url = baseUrl + loc.locationNumber + '/doordash/recovery/' + id;

        return this.http.get(environment.spotlightApiUrl + url).pipe(
          map((res: any) => res.responseObject || []),
          catchError((error) => throwError(error))
        );
      })
    );
  }
}
