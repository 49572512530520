<div fxLayout="column" fxLayoutAlign="start space-around" class="date-range-picker__date-section-container">
  <div fxLayout="row" fxLayoutAlign="start center" class="date-range-picker__date-section-container">
      <date-picker class="date-range-picker__picker"
                   [campaignType]="campaignType"
                   [placeholder]="startDatePlaceholder"
                   [isMobile]="isMobile"
                   [date]="start"
                   [disabled]="disabled || allDaysDisabled"
                   [disabledDays]="disabledDays"
                   [disableSundays]="disableSundays"
                   [minDate]="minStart"
                   [maxDate]="maxStart"
                   [validationControl]="startDateFormControl"
                   (dateChanged)="startDateChanged($event)">
      </date-picker>
      <date-picker class="date-range-picker__picker"
                   [campaignType]="campaignType"
                   [placeholder]="endDatePlaceholder"
                   [isMobile]="isMobile"
                   [date]="endDate"
                   [disabled]="disabled || allDaysDisabled"
                   [disabledDays]="disabledDays"
                   [disableSundays]="disableSundays"
                   [minDate]="minEnd"
                   [maxDate]="maxEnd"
                   [validationControl]="endDateFormControl"
                   (dateChanged)="endDateChanged($event)">
      </date-picker>
  </div>
  <mat-error *ngIf="allDaysDisabled" class="date-range-picker__all-disabled-error">
    No available dates
  </mat-error>
</div>
