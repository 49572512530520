<mat-card [ngClass]="{'filter-chip-box__mat-card-mobile-view': isMobile}">
	<div fxLayout="row" fxLayoutAlign="space-between center">
		<div class="filter-chip-box__customer-text" *ngIf="!isMobile">
			<b>{{totalCustomers}}</b> customers available from <b>{{filterCount}}</b> applied filters
		</div>
		<div class="filter-chip-box__customer-text" *ngIf="isMobile">
			<b>{{filterCount}}</b> filters applied
		</div>
    <div *ngIf="!savedGroupBeingEdited" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="filter-chip-box__clickable-text" (click)="clearFilters.emit()">Clear Filters</div>
      <div *ngIf="displaySavedGroupActions && !isMobile" fxLayout="row" fxLayoutAlign="space-between center">
        <i [ngClass]="selectedSavedGroups.length == 0 ? 'material-icons filter-chip-box__icon' : 'material-icons filter-chip-box__disable-icon'"
           (click)="savedGroup()">favorite_border</i>
        <div [ngClass]="selectedSavedGroups.length == 0 ? 'filter-chip-box__clickable-text' : 'filter-chip-box__disable-text'"
             (click)="savedGroup()">Save This Group</div>
        <help-text *ngIf="selectedSavedGroups.length > 0" class="filter-chip-box__help-text" [helpText]="infoHelpText" [displayOnLeft]="true"></help-text>
      </div>
    </div>
    <div *ngIf="savedGroupBeingEdited" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <img alt="" class="filter-chip-box__edit-icon" src="{{editIconUrl}}" (click)="renameSavedGroup.emit()">
        <div class="filter-chip-box__clickable-text" (click)="renameSavedGroup.emit()">Rename This Group</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <img alt="" class="filter-chip-box__delete-icon" src="{{deleteIconUrl}}" (click)="deleteSavedGroup.emit(savedGroupBeingEdited)">
        <div class="filter-chip-box__clickable-text" (click)="deleteSavedGroup.emit(savedGroupBeingEdited)">Delete This Group</div>
      </div>
    </div>
  </div>
	<div [cfaLoading]="isLoading" [fxLayout]="getFlexLayout(isMobile)" fxLayoutAlign="space-between start">
		<div fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50">
			<div *ngIf="filters.length > 0 || selectedSavedGroups.length > 0" class="filter-chip-box__plain-text">Show me customers who...</div>
			<div *ngIf="filters.length > 0 || selectedSavedGroups.length > 0">
				<mat-chip-list>
					<filter-chip *ngFor="let filter of filters"
                       class="filter-chip-box__filter-chip"
                       [title]="filter.name"
                       [ngClass]="{'filter-chip-box__filter-chip-mobile': isMobile}"
                       [isMobile]="isMobile"
                       (removeClicked)="filterRemoved.emit(filter)">
					</filter-chip>
					<filter-chip *ngFor="let selectedSavedGroup of selectedSavedGroups"
                       class="filter-chip-box__filter-chip"
                       [title]="selectedSavedGroup.formattedName"
                       [isShowGroup]="true"
                       [ngClass]="{'filter-chip-box__filter-chip-mobile': isMobile}"
                       [isMobile]="isMobile"
                       (removeClicked)="savedGroupFilterRemoved.emit(selectedSavedGroup)"
                       (showGroupClicked)="showGroupClicked.emit(selectedSavedGroup)">
					</filter-chip>
				</mat-chip-list>
			</div>
		</div>
		<div fxLayout="column" fxFlex="50" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="50">
			<div *ngIf="includeCustomerFilter.length > 0" class="filter-chip-box__plain-text">And Includes:</div>
			<div *ngIf="includeCustomerFilter.length > 0">
				<mat-chip-list>
					<filter-chip *ngFor="let customer of includeCustomerFilter | slice : 0 : maxIndividualCustomer"
                       [title]="customer.fullName"
                       [isMobile]="isMobile"
                       (removeClicked)="includeCustomerFilterRemoved.emit(customer)">
					</filter-chip>
				</mat-chip-list>
				<div *ngIf="includeCustomerFilter.length > maxIndividualCustomer"
             class="filter-chip-box__clickable-text"
             (click)="moreIncludedCustomersClicked()">
                    Show Additional Customers
				</div>
			</div>
			<div *ngIf="excludeCustomerFilter.length > 0" class="filter-chip-box__plain-text">And Excludes:</div>
			<div *ngIf="excludeCustomerFilter.length > 0">
				<mat-chip-list>
					<filter-chip *ngFor="let customer of excludeCustomerFilter | slice : 0 : maxIndividualCustomer"
                       [title]="customer.fullName"
                       [isMobile]="isMobile"
                       (removeClicked)="excludeCustomerFilterRemoved.emit(customer)">
					</filter-chip>
				</mat-chip-list>
				<div *ngIf="excludeCustomerFilter.length > maxIndividualCustomer"
             class="filter-chip-box__clickable-text"
             (click)="moreExcludedCustomersClicked()">
					+{{excludeCustomerFilter.length - maxIndividualCustomer}} more
				</div>
			</div>
		</div>
	</div>
</mat-card>
