import * as calendar from '../actions/calendar';
import * as customer from '../actions/customer';
import * as engage from '../actions/engage';
import * as engagement from '../actions/engagement';
import * as meet from '../actions/meet';
import * as user from '../actions/user';
import { any, clone, find, findIndex, flatten, isEmpty, omit, pluck, propEq, prop, sortBy } from 'ramda';
import { EngageConstants } from '../engage/engage.constants';
import { MeetConstants } from '../meet/meet.constants';
import { SharedConstants } from '../shared/shared.constants';
import { TreatMetadata } from '../models/engage/treat-metadata.model';
import * as moment from 'moment-timezone';

export interface State {
  allSelectedCustomerIds: string[];
  campaignName: string;
  customerError?: string;
  customerLoading: boolean;
  customizeSortArray: any;
  defaultFilters: any[];
  defaultFiltersError: string;
  defaultFiltersLoading: boolean;
  deliveryEnabled: boolean;
  duplicateRecipients: any[];
  emailTemplates: any;
  engagementId?: string;
  error?: any;
  eventTemplates: any;
  excludedCustomers: any[];
  filterDisplayCustomerAllCustomerIds: any[];
  filterDisplayCustomerCount: number;
  filterDisplayCustomerError?: string;
  filterDisplayCustomerLimit: number;
  filterDisplayCustomerLoading: boolean;
  filterDisplayCustomerNumberOfPages: number;
  filterDisplayCustomerPage: number;
  filterDisplayCustomers: any[];
  filterDisplayCustomerSort: string;
  filterDisplayCustomerSortOrder: string;
  filterEndDate?: any;
  filterOnBetweenId: string;
  filterStartDate?: any;
  getAllCustomers?: boolean;
  holdOutRecipients: any[];
  includedCustomers: any[];
  isCompleted: boolean;
  isDynamicGroupSend: boolean;
  loading: boolean;
  mode: string;
  noRsvpHtml: string;
  offerRecipients: any[];
  overlappingEngagements: any;
  previewHtml: string;
  recipientsSearchTerm: string;
  rewardTemplates: any;
  rsvpHtml: string;
  savedFilters: any[];
  savedFiltersError: string;
  savedFiltersLoading: boolean;
  selectedBonusPointPromotion: any;
  selectedCommunityCareEvent: any;
  selectedCustomerLoading: boolean;
  selectedCustomers: any[];
  selectedDefaultFilters: any[];
  selectedEmail: any;
  selectedEvent: any;
  selectedFreeInCart:any;
  selectedIndividualIds: string[];
  selectedSavedFilters: any[];
  selectedTabIndex: number;
  selectedHtmlTabIndex: number;
  selectedTheme: string;
  selectedThirdPartyPromotion: any;
  selectedTreat: any;
  sendError?: any;
  sendLoading: boolean;
  sendProgress: any;
  shouldSetSelectedCustomerIds?: boolean;
  sort: string;
  sortOrder: string;
  stockImages: any[];
  themes: TreatMetadata[];
  thirdPartyMap: any;
  type: string;
  updated: any;
}

export const initialState: State = {
  allSelectedCustomerIds: [],
  campaignName: null,
  customizeSortArray: [{
    order: 'asc',
    prop: 'startDate'
  }, {
    order: 'desc',
    prop: 'firstName'
  }],
  customerLoading: false,
  defaultFilters: [],
  defaultFiltersError: null,
  defaultFiltersLoading: true,
  deliveryEnabled: false,
  duplicateRecipients: [],
  emailTemplates: {
    drafts: [],
    error: null,
    loading: true,
    templates: [],
    categories: EngageConstants.emailCategories
  },
  eventTemplates: {
    error: null,
    loading: true,
    templates: []
  },
  excludedCustomers: [],
  filterDisplayCustomerAllCustomerIds: [],
  filterDisplayCustomerCount: 0,
  filterDisplayCustomerLimit: 20,
  filterDisplayCustomerLoading: true,
  filterDisplayCustomerNumberOfPages: 0,
  filterDisplayCustomerPage: 0,
  filterDisplayCustomers: [],
  filterDisplayCustomerSort: 'firstName',
  filterDisplayCustomerSortOrder: 'asc',
  filterOnBetweenId: SharedConstants.onBetweenDefaultId,
  holdOutRecipients: [],
  includedCustomers: [],
  isCompleted: false,
  isDynamicGroupSend: null,
  loading: true,
  mode: '',
  noRsvpHtml: '',
  offerRecipients: [],
  overlappingEngagements: {
    engagements: [],
    error: null,
    loading: true
  },
  previewHtml: '',
  recipientsSearchTerm: '',
  rewardTemplates: {
    error: null,
    loading: true,
    ltoTemplates: [],
    templates: []
  },
  rsvpHtml: '',
  savedFilters: [],
  savedFiltersError: null,
  savedFiltersLoading: true,
  selectedBonusPointPromotion: {
    recruitmentModuleEnabled: false,
    recruitmentModuleLink: null,
    statsLoading: false
  },
  selectedCommunityCareEvent: {
    recruitmentModuleEnabled: false,
    recruitmentModuleLink: null
  },
  selectedCustomerLoading: false,
  selectedCustomers: [],
  selectedDefaultFilters: [],
  selectedEmail: {
    recruitmentModuleEnabled: false,
    recruitmentModuleLink: null,
    sendImmediate: true
  },
  selectedEvent: {
    adultsOnly: false,
    limitedReservations: false,
    maxNumberOfReservations: '',
    recruitmentModuleEnabled: false,
    recruitmentModuleLink: null,
    rsvpRequired: true,
    sendImmediate: true,
    sendNonRsvpReminder: true,
    sendReminderEmails: true,
    sendRsvpReminder: true
  },
  selectedFreeInCart: {
    recruitmentModuleEnabled: false,
    recruitmentModuleLink: null,
    statsLoading: false
  },
  selectedIndividualIds: [],
  selectedSavedFilters: [],
  selectedTabIndex: null,
  selectedHtmlTabIndex: 0,
  selectedTheme: '',
  selectedThirdPartyPromotion: {
    recruitmentModuleEnabled: false,
    recruitmentModuleLink: null,
    statsLoading: false
  },
  selectedTreat: {
    endDate: setRewardDates(moment().add('30', 'days')),
    maxDaysAllowed: 365,
    recruitmentModuleEnabled: false,
    recruitmentModuleLink: null,
    recruitmentModuleOldLink: null,
    sendOperatorImage: true,
    startDate: setRewardDates(moment())
  },
  sendLoading: false,
  sendProgress: [],
  sort: 'dateRange',
  sortOrder: 'desc',
  stockImages: [],
  themes: [],
  thirdPartyMap: {},
  type: '',
  updated: {}
};

export function getEventDatesAndTimes(eventState) {
  let {
    endDate,
    endTime,
    sendDate,
    sendImmediate,
    sendTime,
    startDate,
    startTime
  } = eventState;

  let startTimeRestriction = SharedConstants.earliestTimeOption;
  let endTimeRestriction = SharedConstants.earliestTimeOption;
  let sendTimeStartRestriction = SharedConstants.earliestTimeOption;
  let sendTimeEndRestriction = SharedConstants.latestTimeOption;
  let timeFormat = 'h:mm a';
  let dateFormat = 'YYYY-MM-DD';
  let beginningOfToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  // Set start time and start time restriction
  if (beginningOfToday.diff(startDate, 'days') === 0) {
    startTimeRestriction = getNextTimeByFifteenMinuteIncrements(0);

    // If currently selected start time is before the restriction, set it to null
    if (moment(startTime, timeFormat).isBefore(moment(startTimeRestriction, timeFormat))) {
      startTime = null;
    }
  }

  // If endDate is same day as startDate, set time restrictions
  if (startDate.diff(endDate, 'days') === 0) {
    endTimeRestriction = startTime ? startTime : startTimeRestriction;

    // If currently selected end time is before the restriction, set it to null
    if (moment(endTime, timeFormat).isBefore(moment(endTimeRestriction, timeFormat))) {
      endTime = null;
    }
  }

  // Only handle send date/time logic if it is not sendImmediate
  if (!sendImmediate && sendDate) {
    // If send date is after start date, set send date and time to null
    // If send date is same day as start date, check on time restriction
    // If send date is same day as current day, check on time restriction
    if (sendDate.diff(startDate, 'days') > 0 && eventState.mode !== EngageConstants.editMode) {
      sendDate = null;
      sendTime = null;
    } else if (moment(sendDate.format(dateFormat)).diff(moment(startDate.format(dateFormat)), 'days') === 0) {
      sendTimeEndRestriction = startTime;
      sendTimeStartRestriction = startTimeRestriction;

      // If currently selected send time is a restricted time, set to sendTimeStartRestriction
      if (sendTime
          && moment(sendTime, timeFormat).isBefore(moment(sendTimeStartRestriction, timeFormat))
          || moment(sendTime, timeFormat).isAfter(moment(sendTimeEndRestriction, timeFormat))
          && eventState.mode !== EngageConstants.editMode) {
        sendTime = sendTimeStartRestriction;
      }
    } else if (sendDate.diff(beginningOfToday, 'days') === 0) {
      sendTimeStartRestriction = getNextTimeByFifteenMinuteIncrements(0);

      // If currently selected send time is a restricted time, set to sendTimeStartRestriction
      if (sendTime
          && moment(sendTime, timeFormat).isBefore(moment(sendTimeStartRestriction, timeFormat))
          && eventState.mode !== EngageConstants.editMode) {
        sendTime = sendTimeStartRestriction;
      }
    }
  }

  return {
    endDate,
    endTime,
    endTimeRestriction,
    sendDate,
    sendTime,
    sendTimeEndRestriction,
    sendTimeStartRestriction,
    startDate,
    startTime,
    startTimeRestriction
  };
}

function getFilteredStockImages(allStockImages, changeImageId) {
  let stockImages = [];
  if (allStockImages) {
    stockImages = allStockImages.map((imageCategory) => {
      let filteredImages = imageCategory.changeImageOptions.reduce((result,changeImageOption) => {
        const imageThemeId = changeImageOption.changeImageId;
        // If the selected email doesn't have a themeId then add all available image options
        // If there is no themeId add it because it means that image isn't theme restricted
        if (!changeImageId || !imageThemeId || imageThemeId === changeImageId) {
          result.push(changeImageOption);
        }
        return result
      },[]);
      return {
        changeImageOptions: filteredImages,
        id: imageCategory.id,
        imageType: imageCategory.imageType,
        templateId: imageCategory.templateId
      }
    });
  }
  return stockImages;
}

export function getFormattedSelectedFilters(selectedFilters, displayFilters) {
  return selectedFilters.map(selectedFilter => {
    let selectedCategory = find(propEq('id', selectedFilter.parentCategory))(displayFilters);
    let subFilters = selectedCategory.subFilters;
    let selectedSubFilter = find(propEq('id', selectedFilter.id))(subFilters);

    if (selectedFilter.selectedButtonToggle) {
      selectedSubFilter.selectedButtonToggle = selectedFilter.selectedButtonToggle;
    }

    if (selectedFilter.subOptions) {
      selectedSubFilter.subOptions = selectedFilter.subOptions;
    }

    if (selectedFilter.selectedButtonChipSections) {
      selectedSubFilter.selectedButtonChipSections = selectedSubFilter.buttonChipSections.reduce((result,buttonChipSection) => {
        let selectedButtonChipSection = find(propEq('id', buttonChipSection.id))(selectedFilter.selectedButtonChipSections);
        if (selectedButtonChipSection) {
          result.push(selectedButtonChipSection);
        }
        return result;
      }, []);
    }

    // Works for both single and double sliders since highValue will just be null, as desired
    if (selectedFilter.selectedSliderValue !== null && selectedFilter.selectedSliderValue !== undefined) {
      selectedSubFilter = {
        ...selectedSubFilter,
        selectedSliderValue: selectedFilter.selectedSliderValue,
        selectedSliderHighValue: selectedFilter.selectedSliderHighValue
      };
    }

    return selectedSubFilter;
  });
}

// Gets the nearest fifteen minute increment and then adds the desired number of 15 minute intervals
export function getNextTimeByFifteenMinuteIncrements(numberOfIncrements) {
  const remainder = 15 - (moment().minute() % 15);
  const minutesToAdd = numberOfIncrements * 15 + remainder;
  return moment().add(minutesToAdd, 'minutes').format('h:mm a');
}

// Remove individual customer filters and date filters while also
// returning the appropriate values to account for them on the state
export function separateExtraFilters(selectedFilters) {
  let formattedSelectedFilters = clone(selectedFilters);
  let includedCustomers = [];
  let excludedCustomers = [];
  let filterStartDate = null;
  let filterEndDate = null;
  let filterOnBetweenId = initialState.filterOnBetweenId;

  let includeIndividualFilterIndex = findIndex(propEq('id', MeetConstants.includeFilterId))(formattedSelectedFilters)
  if (includeIndividualFilterIndex > -1) {
    includedCustomers = formattedSelectedFilters[includeIndividualFilterIndex].cfaIdList.map(id => {
      return { id, requiresCustomerDetails: true };
    });
    formattedSelectedFilters.splice(includeIndividualFilterIndex, 1);
  }

  let excludeIndividualFilterIndex = findIndex(propEq('id', MeetConstants.excludeFilterId))(formattedSelectedFilters)
  if (excludeIndividualFilterIndex > -1) {
    excludedCustomers = formattedSelectedFilters[excludeIndividualFilterIndex].cfaIdList.map(id => {
      return { id, requiresCustomerDetails: true };
    });
    formattedSelectedFilters.splice(excludeIndividualFilterIndex, 1);
  }

  let onBetweenFilterIndex = findIndex(propEq('id', SharedConstants.filtersOnBetweenId))(formattedSelectedFilters);
  if (onBetweenFilterIndex > -1) {
    filterOnBetweenId = formattedSelectedFilters[onBetweenFilterIndex].selectedOnBetweenId;
    formattedSelectedFilters.splice(onBetweenFilterIndex, 1);
  }

  let customDateSubFilterId = findIndex(propEq('id', SharedConstants.filtersCustomDateSubFilterId))(formattedSelectedFilters);
  if (customDateSubFilterId > -1) {
    filterStartDate = formattedSelectedFilters[customDateSubFilterId].startDate;
    filterEndDate = formattedSelectedFilters[customDateSubFilterId].endDate;
    formattedSelectedFilters.splice(customDateSubFilterId, 1);
  }

  return {
    formattedSelectedFilters,
    includedCustomers,
    excludedCustomers,
    filterOnBetweenId,
    filterStartDate,
    filterEndDate
  };
}

export function setRewardDates(momentDate) {
  // Add a day if the date is a Sunday
  if (momentDate && momentDate.weekday() === 0) {
    momentDate = momentDate.add('1', 'days');
  }
  return momentDate;
}

export function reducer(
  state = initialState,
  action: calendar.Actions | customer.Actions | engage.Actions | engagement.Actions | meet.Actions | user.Actions
) {
  switch (action.type) {
    case calendar.ActionTypes.FETCH_AUTOMATED_REWARD_DETAILS: {
      const payload = action.payload;
      return {
        ...state,
        batchIds: payload.batchIds,
        error: null,
        loading: true,
        mode: EngageConstants.reviewMode,
        previewHtml: '',
        type: payload.type
      };
    }

    case calendar.ActionTypes.FETCH_AUTOMATED_REWARD_DETAILS_FAILURE:
    case calendar.ActionTypes.FETCH_EMAIL_DETAILS_FAILURE:
    case calendar.ActionTypes.FETCH_EVENT_DETAILS_FAILURE:
    case calendar.ActionTypes.FETCH_FREE_ITEM_IN_CART_DETAILS_FAILURE:
    case calendar.ActionTypes.FETCH_REWARD_DETAILS_FAILURE:
    case engage.ActionTypes.FETCH_EMAIL_DRAFT_DETAILS_ERROR:
    case engage.ActionTypes.FETCH_EVENT_TEMPLATE_HTML_FAILURE:
    case engage.ActionTypes.FETCH_TEMPLATE_DETAILS_FAILURE:
    case engage.ActionTypes.GET_SELECTED_FILTER_DETAILS_ERROR: {
      const error = action.payload;
      return { ...state, error, loading: false };
    }

    case calendar.ActionTypes.FETCH_AUTOMATED_REWARD_DETAILS_SUCCESS:
    case calendar.ActionTypes.FETCH_REWARD_DETAILS_SUCCESS: {
      const calendarDetails = action.payload;
      const endDate = moment(calendarDetails.endDate);
      const endDateCountdownTimer = endDate.format('YYYY-MM-DD');
      const recipientsSearchTerm = '';
      const sendProgress = calendarDetails.sendProgress ? [...calendarDetails.sendProgress] : [];
      const startDate = moment(calendarDetails.startDate);

      // Only show countdown for rewards that are longer than 1 day and end more than 1 day away
      const longerThan1Day = moment(endDate.format('YYYY-MM-DD')).diff(moment(startDate.format('YYYY-MM-DD')), 'days') > 1;
      const moreThan1DayAway = moment(endDate.format('YYYY-MM-DD')).isAfter(moment().format('YYYY-MM-DD'));
      const showCountdownTimer = longerThan1Day && moreThan1DayAway ? '' : 'style="display: none;"';
      const selectedTreat = {
        ...calendarDetails.treats[0],
        endDateCountdownTimer,
        showCountdownTimer
      };

      return {
        ...state,
        campaignName: calendarDetails.campaignName,
        loading: false,
        recipientsSearchTerm,
        selectedTreat: {
          ...selectedTreat,
          imageURL: selectedTreat.offerImage
        },
        sendProgress,
        stockImages: []
      };
    }

    case calendar.ActionTypes.FETCH_BONUS_POINT_DETAILS: {
      const payload = action.payload;
      return {
        ...state,
        error: null,
        loading: true,
        mode: EngageConstants.reviewMode,
        previewHtml: '',
        selectedBonusPointPromotion: {
          ...state.selectedBonusPointPromotion,
          statsLoading: true
        },
        type: payload.type
      };
    }

    case calendar.ActionTypes.FETCH_BONUS_POINT_DETAILS_SUCCESS: {
      let selectedBonusPointPromotion = action.payload;
      let repeatOptions = EngageConstants.repeatOptions;
      let repeatDayOptions = EngageConstants.repeatDayOptions;

      repeatOptions.forEach((option) => {
        if (selectedBonusPointPromotion && selectedBonusPointPromotion.repeatOption) {
          option.selected = option.id === selectedBonusPointPromotion.repeatOption.repeatOptionId;
        }
      });
      repeatDayOptions.forEach((dayOption) => {
        if (selectedBonusPointPromotion && selectedBonusPointPromotion.repeatOption.subOptions) {
          dayOption.selected = dayOption.id === selectedBonusPointPromotion.repeatOption.subOptions[0].id;
        }
      });

      selectedBonusPointPromotion = {
        ...selectedBonusPointPromotion,
        createdAt: selectedBonusPointPromotion.createdAt ? selectedBonusPointPromotion.createdAt : null,
        endDate: moment(selectedBonusPointPromotion.endDate),
        repeatDayOptions,
        repeatOptionId: selectedBonusPointPromotion.repeatOption.repeatOptionId,
        repeatOptions,
        selectedDestinationIds: selectedBonusPointPromotion.destinations,
        sendDate: moment(selectedBonusPointPromotion.startDate),
        startDate: moment(selectedBonusPointPromotion.startDate),
        statsLoading: state.selectedBonusPointPromotion.statsLoading
      };

      return {
        ...state,
        campaignName: selectedBonusPointPromotion.campaignName,
        loading: false,
        recipientsSearchTerm: '',
        selectedBonusPointPromotion,
        sendProgress: initialState.sendProgress,
        stockImages: []
      };
    }

    case calendar.ActionTypes.FETCH_BONUS_POINT_STATS_FAILURE: {
      return {
        ...state,
        selectedBonusPointPromotion: {
          ...state.selectedBonusPointPromotion,
          statsLoading: false
        }
      }
    }

    case calendar.ActionTypes.FETCH_BONUS_POINT_STATS_SUCCESS: {
      const stats = action.payload;
      const selectedBonusPointPromotion = clone(state.selectedBonusPointPromotion);

      return {
        ...state,
        selectedBonusPointPromotion: {
          ...selectedBonusPointPromotion,
          stats,
          statsLoading: false
        }
      };
    }

    case calendar.ActionTypes.FETCH_COMMUNITY_CARE_DETAILS: {
      const payload = action.payload;
      return {
        ...state,
        campaignName: '',
        error: null,
        loading: true,
        mode: EngageConstants.reviewMode,
        previewHtml: '',
        type: payload.type
      };
    }

    case calendar.ActionTypes.FETCH_COMMUNITY_CARE_DETAILS_SUCCESS: {
      let selectedCommunityCareEvent = action.payload;
      let organizationStats = [];
      let totalSales = 0;
      let showCustomers = false;
      let optInOptions = EngageConstants.optInOptions;
      let selectedOptIn = selectedCommunityCareEvent && selectedCommunityCareEvent.organizations
        && selectedCommunityCareEvent.organizations[0].organizationCode != null ? 'organizationCode' : 'none';

      const endDate = moment(selectedCommunityCareEvent.endDate);
      const startDate = moment(selectedCommunityCareEvent.startDate);

      optInOptions.forEach((option) => {
        option.selected = option.id === selectedOptIn;
      });

      if (selectedCommunityCareEvent && selectedCommunityCareEvent[0]) {
        organizationStats.push(selectedCommunityCareEvent[0]);
      }
      if (selectedCommunityCareEvent && selectedCommunityCareEvent[1]) {
        organizationStats.push(selectedCommunityCareEvent[1]);
      }

      // Find which organization the customers belong to and add them to the respective organization object
      if (selectedCommunityCareEvent) {
        organizationStats.forEach((stats) => {
          const index = findIndex(propEq('organizationName', stats.orgName))(selectedCommunityCareEvent.organizations);
          if (stats.customers) {
            selectedCommunityCareEvent.organizations[index] = {
              ...selectedCommunityCareEvent.organizations[index],
              recipients: stats.customers.map(customer => {
                customer.transactionDate = moment(customer.transactionDate).format('MM/DD');
                return customer;
              }),
              totalSales: (Math.round(stats.totalSales * 100) / 100).toFixed(2).toLocaleString()
            };
            totalSales += stats.totalSales;
            showCustomers = stats.customers.length > 0 ? true : showCustomers;
          } else if (index !== -1) {
            selectedCommunityCareEvent.organizations[index] = {
              ...selectedCommunityCareEvent.organizations[index],
              recipients: [],
              totalSales: 0
            };
          }
        });
      }

      // If the CCE is in the past then always show the customer list, even if it is an empty array
      // @ts-ignore
      if (endDate.isBefore(moment())) {
        showCustomers = true;
      }

      selectedCommunityCareEvent = {
        ...selectedCommunityCareEvent,
        datesAndTimes: {
          createdAt: selectedCommunityCareEvent.createdAt ? selectedCommunityCareEvent.createdAt : null,
          endDate,
          endTime: endDate.format('h:mm a'),
          startDate,
          startTime: startDate.format('h:mm a')
        },
        optInOptions,
        selectedOptIn,
        showCustomers,
        totalSales: (Math.round(totalSales * 100) / 100).toFixed(2).toLocaleString()
      };

      return {
        ...state,
        loading: false,
        recipientsSearchTerm: '',
        selectedCommunityCareEvent,
        sendProgress: initialState.sendProgress,
        stockImages: []
      };
    }

    case calendar.ActionTypes.FETCH_EMAIL_DETAILS:
    case calendar.ActionTypes.FETCH_EVENT_DETAILS:
    case calendar.ActionTypes.FETCH_REWARD_DETAILS: {
      const payload = action.payload;
      return {
        ...state,
        error: null,
        loading: true,
        mode: EngageConstants.reviewMode,
        previewHtml: '',
        type: payload.type

      };
    }

    case calendar.ActionTypes.FETCH_EMAIL_DETAILS_SUCCESS: {
      let selectedEmail = action.payload;
      const sendProgress = selectedEmail.sendProgress ? [...selectedEmail.sendProgress] : [];

      selectedEmail = {
        ...selectedEmail,
        createdAt: selectedEmail.createdAt ? selectedEmail.createdAt : null,
        defaultSelectedTheme: selectedEmail.themeId ? selectedEmail.themeId : undefined,
        sendDate: moment(selectedEmail.startDate),
        sendTime: moment(selectedEmail.startDate).format('h:mm a'),
        startDate: moment(selectedEmail.startDate)
      };

      return {
        ...state,
        campaignName: selectedEmail.campaignName,
        loading: false,
        recipientsSearchTerm: '',
        selectedEmail,
        sendProgress,
        stockImages: []
      };
    }

    case calendar.ActionTypes.FETCH_EVENT_DETAILS_SUCCESS: {
      let selectedEvent = action.payload;
      const sendProgress = selectedEvent.sendProgress ? [...selectedEvent.sendProgress] : [];
      let totalAttending: number = 0;
      let adultsAttending: number = 0;
      let kidsAttending: number = 0;

      if (selectedEvent.acceptedRecipients && selectedEvent.acceptedRecipients.length > 0) {
        selectedEvent.acceptedRecipients.forEach((recipient) => {
          adultsAttending += recipient.adultsAttending;
          kidsAttending += recipient.kidsAttending;
        });
      }
      totalAttending = adultsAttending + kidsAttending

      selectedEvent = {
        ...selectedEvent,
        adultsAttending,
        adultsOnly: selectedEvent.adultsOnly === 'Yes',
        createdAt: selectedEvent.createdAt ? selectedEvent.createdAt : null,
        emailheadline: selectedEvent.headline,
        endDate: moment(selectedEvent.endDate),
        endTime: moment(selectedEvent.endDate).format('h:mm a'),
        kidsAttending,
        limitedReservations: selectedEvent.maxCustomers && selectedEvent.maxCustomers !== '',
        maxNumberOfReservations: selectedEvent.maxCustomers ? selectedEvent.maxCustomers : '',
        rsvpRequired: selectedEvent.rsvpRequired,
        sendDate: moment(selectedEvent.sendDate),
        sendNonRsvpReminder: selectedEvent.sendReminderForNotRSVPed,
        sendReminderEmails: selectedEvent.sendReminderForNotRSVPed || selectedEvent.sendReminderForRSVPed,
        sendRsvpReminder: selectedEvent.sendReminderForRSVPed,
        sendTime: moment(selectedEvent.sendDate).format('h:mm a'),
        startDate: moment(selectedEvent.startDate),
        startTime: moment(selectedEvent.startDate).format('h:mm a'),
        totalAttending
      };

      return {
        ...state,
        campaignName: selectedEvent.campaignName,
        loading: false,
        recipientsSearchTerm: '',
        selectedEvent,
        sendProgress,
        stockImages: [],
        themes: selectedEvent.themes
      };
    }

    case calendar.ActionTypes.FETCH_FREE_ITEM_IN_CART_DETAILS: {
      const payload = action.payload;
      return {
        ...state,
        campaignName: '',
        error: null,
        loading: true,
        mode: EngageConstants.reviewMode,
        previewHtml: '',
        selectedFreeInCart: {
          ...state.selectedFreeInCart,
          statsLoading: true
        },
        type: payload.type
      };
    }

    case calendar.ActionTypes.FETCH_FREE_ITEM_IN_CART_DETAILS_SUCCESS: {
      let [selectedFreeInCart, promotionMetaData] = action.payload;
      const destinations = promotionMetaData.freeItemInCartPromotionMetaData.destinationOptions;
      let repeatDayOptions = [];
      let repeatOptions = promotionMetaData.freeItemInCartPromotionMetaData.repeatOptions;
      let rewardList = promotionMetaData.freeItemInCartPromotionMetaData.menuItems;
      let selectedReward = { };

      rewardList.forEach((reward) => {
        if (selectedFreeInCart && selectedFreeInCart.offerTemplateId == reward.offerTemplateId) {
          selectedReward = clone(reward);
        }
      });
      repeatOptions.forEach((option) => {
        if (selectedFreeInCart && selectedFreeInCart.repeatOption) {
          option.selected = option.id === selectedFreeInCart.repeatOption.repeatOptionId;
        }
        if (option.id == SharedConstants.weeklyRepeatPromotion) {
          repeatDayOptions = option.subOptions;
          repeatDayOptions.forEach((dayOption) => {
            if (selectedFreeInCart && selectedFreeInCart.repeatOption.subOptions) {
              dayOption.selected = dayOption.id === selectedFreeInCart.repeatOption.subOptions[0].id;
            }
          });
        }
      });

      const customerTypes = promotionMetaData.freeItemInCartPromotionMetaData.customerTypes;
      let displayTypes = clone(customerTypes);
      displayTypes.forEach((type) => {
        type.selected = selectedFreeInCart.customerType.includes(type.id)
      });

      selectedFreeInCart = {
        ...selectedFreeInCart,
        createdAt: selectedFreeInCart.createdAt ? selectedFreeInCart.createdAt : null,
        customerTypes: displayTypes,
        destinations,
        endDate: moment(selectedFreeInCart.endDate),
        engagementType: EngageConstants.freeItemInCartPromotion,
        repeatOptionId: selectedFreeInCart.repeatOption.repeatOptionId,
        repeatPromotionButtons: repeatDayOptions,
        repeatThisPromotion: repeatOptions,
        rewardList: rewardList,
        selectedDestinationIdsForMultiSelect: selectedFreeInCart.destinations,
        selectedReward,
        sendDate: moment(selectedFreeInCart.startDate),
        startDate: moment(selectedFreeInCart.startDate),
        stats: state.selectedFreeInCart.stats,
        statsLoading: state.selectedFreeInCart.statsLoading,
        timeOfDayOptions: promotionMetaData.freeItemInCartPromotionMetaData.timeOfDayOptions
      };

      return {
        ...state,
        campaignName: selectedFreeInCart.campaignName,
        recipientsSearchTerm: '',
        loading: false,
        selectedFreeInCart,
        sendProgress: initialState.sendProgress,
        stockImages: []
      };
    }

    case calendar.ActionTypes.FETCH_FREE_ITEM_IN_CART_STATS_FAILURE: {
      return {
        ...state,
        selectedFreeInCart: {
          ...state.selectedFreeInCart,
          statsLoading: false
        }
      }
    }

    case calendar.ActionTypes.FETCH_FREE_ITEM_IN_CART_STATS_SUCCESS: {
      const stats = action.payload
      const selectedFreeInCart = clone(state.selectedFreeInCart);

      return {
        ...state,
        selectedFreeInCart: {
          ...selectedFreeInCart,
          stats,
          statsLoading: false
        }
      };
    }

    case calendar.ActionTypes.FETCH_THIRD_PARTY_DETAILS: {
      const payload = action.payload;

      return {
        ...state,
        campaignName: '',
        error: null,
        loading: true,
        mode: EngageConstants.reviewMode,
        previewHtml: '',
        selectedThirdPartyPromotion: {
          ...state.selectedThirdPartyPromotion,
          statsLoading: true
        },
        type: payload.type
      };
    }

    case calendar.ActionTypes.FETCH_THIRD_PARTY_DETAILS_SUCCESS: {
      let [selectedThirdPartyPromotion, promotionMetaData] = action.payload;
      let selectedPlatforms = selectedThirdPartyPromotion.createdIn;
      let customerTypes = promotionMetaData.thirdPartyPromotionMetaData.customerTypes;
      let displayTypes = clone(promotionMetaData.thirdPartyPromotionMetaData.customerTypes);
      let finalList = []

      promotionMetaData.thirdPartyPromotionMetaData.platforms.forEach(platform => {
        platform.selected = false;

        if (state.thirdPartyMap[platform.id]) {
          if (selectedPlatforms.includes(platform.id)) {
            platform.selected = true;
            //if platform is selected, set customer types to be displayed
            customerTypes.forEach(type => {
              if (type.displayFor.indexOf(platform.id) == -1) {
                let index = displayTypes.indexOf(find(propEq('displayTitle', type.displayTitle))(displayTypes))
                displayTypes.splice(index, 1)
              }
            })
          }
          finalList.push(platform);
        }
      });

      displayTypes.map(type => type.selected = selectedThirdPartyPromotion.customerType.includes(type.displayTitle.toUpperCase()));
      selectedThirdPartyPromotion.customerTypes = displayTypes;
      selectedThirdPartyPromotion.endDate = moment(selectedThirdPartyPromotion.endDate);
      selectedThirdPartyPromotion.platforms = finalList;
      selectedThirdPartyPromotion.startDate = moment(selectedThirdPartyPromotion.startDate);
      selectedThirdPartyPromotion.statsLoading = state.selectedThirdPartyPromotion.statsLoading;

      return {
        ...state,
        loading: false,
        recipientsSearchTerm: '',
        selectedThirdPartyPromotion,
        sendProgress: initialState.sendProgress,
        stockImages: []
      };
    }

    case calendar.ActionTypes.FETCH_THIRD_PARTY_STATS_FAILURE: {
      return {
        ...state,
        selectedThirdPartyPromotion: {
          ...state.selectedThirdPartyPromotion,
          statsLoading: false
        }
      }
    }

    case calendar.ActionTypes.FETCH_THIRD_PARTY_STATS_SUCCESS: {
      let statList = action.payload
      let selected3PP = clone(state.selectedThirdPartyPromotion);

      if (selected3PP.platforms) {
        selected3PP.platforms.forEach(platform => {
          const index = findIndex(propEq('platform', platform.id))(statList);
          if (index > -1) {
            statList[index].platform = platform.name;
          }
        })
      }

      return {
        ...state,
        selectedThirdPartyPromotion: {
          ...selected3PP,
          stats: statList,
          statsLoading: false
        }
      }
    }

    case customer.ActionTypes.AUTOCOMPLETE_ERROR: {
      const customerError = action.payload;
      return { ...state, customerError, customerLoading: false };
    }

    case customer.ActionTypes.AUTOCOMPLETE_SEARCH: {
      return { ...state, customerError: initialState.customerError };
    }

    case customer.ActionTypes.CUSTOMER_ERROR:
    case engage.ActionTypes.FIND_FILTER_DISPLAY_CUSTOMERS_ERROR: {
      const payload = action.payload;
      return {
        ...state,
        filterDisplayCustomerAllCustomerIds: [],
        filterDisplayCustomerCount: 0,
        filterDisplayCustomerError: payload,
        filterDisplayCustomerLoading: false,
        filterDisplayCustomerNumberOfPages: 0,
        filterDisplayCustomers: [],
        selectedCustomerLoading: false
      };
    }

    case customer.ActionTypes.CUSTOMERS_SELECTED_FOR_ENGAGE: {
      const selectedIndividualIds = action.payload;

      let includedCustomers = selectedIndividualIds.map(id => {
        return { id, requiresCustomerDetails: true };
      });

      return {
        ...state,
        allSelectedCustomerIds: selectedIndividualIds,
        includedCustomers,
        selectedCustomerLoading: true,
        selectedIndividualIds
      };
    }

    case customer.ActionTypes.FIND_CUSTOMER_COMPLETE: {
      return { ...state, filterDisplayCustomerLoading: false };
    }

    case customer.ActionTypes.SELECT_CUSTOMERS: {
      const selectedCustomers = action.payload ? action.payload : state.selectedCustomers;

      return {
        ...state,
        customerError: null,
        customerLoading: false,
        selectedCustomers
      };
    }

    case engage.ActionTypes.APPLY_FILTERS: {
      const {
        endDate,
        excludedCustomers,
        includedCustomers,
        onBetweenId,
        selectedFilters,
        selectedSavedGroups,
        startDate
      } = action.payload;

      return {
        ...state,
        allSelectedCustomerIds: state.filterDisplayCustomerAllCustomerIds,
        excludedCustomers,
        filterEndDate: endDate,
        filterOnBetweenId: onBetweenId,
        filterStartDate: startDate,
        includedCustomers,
        selectedDefaultFilters: selectedFilters,
        selectedSavedFilters: selectedSavedGroups,
        updated: { ...state.updated, customers: true }
      };
    }

    case engage.ActionTypes.CALENDAR_EVENTS_ERROR: {
      const error = action.payload;

      return {
        ...state,
        overlappingEngagements: {
          engagements: [],
          error,
          loading: false
        }
      };
    }

    case engage.ActionTypes.CALENDAR_EVENTS_FOUND: {
      const eventsGroupedByType = action.payload;
      let engagements = {}
      eventsGroupedByType.forEach(events => {
        if (events[0] && events[0].type == 'offer') {
          engagements['offer'] = engagements['offer'] ? engagements['offer'].concat(events) : events;
        } else if (events[0] && (events[0].type.includes('promotion') || events[0].type == 'bonus-point')) {
          engagements['promotion'] = engagements['promotion'] ? engagements['promotion'].concat(events) : events;
        } else {
          engagements['event'] = engagements['event'] ? engagements['event'].concat(events) : events;
        }
      })
      engagements['offer'] = engagements['offer'] ? sortBy(prop('startDate'), engagements['offer']) : [];
      engagements['promotion'] = engagements['promotion'] ? sortBy(prop('startDate'), engagements['promotion']) : [];
      engagements['event'] = engagements['event'] ? sortBy(prop('startDate'), engagements['event']) : [];

      return {
        ...state,
        overlappingEngagements: {
          engagements,
          error: null,
          loading: false
        }
      };
    }

    case engage.ActionTypes.CHANGE_FILTER_PAGE: {
      return {
        ...state,
        filterDisplayCustomerError: null,
        filterDisplayCustomerLoading: true,
        filterDisplayCustomerPage: action.payload
      };
    }

    case engage.ActionTypes.CHANGE_FILTER_PAGE_SIZE: {
      return {
        ...state,
        filterDisplayCustomerError: null,
        filterDisplayCustomerLimit: action.payload,
        filterDisplayCustomerLoading: true,
        filterDisplayCustomerPage: 0
      };
    }

    case engage.ActionTypes.CHANGE_FILTER_SORT: {
      const payload = action.payload;

      return {
        ...state,
        filterDisplayCustomerError: null,
        filterDisplayCustomerLoading: true,
        filterDisplayCustomerSort: payload.prop,
        filterDisplayCustomerSortOrder: payload.order
      };
    }

    case engage.ActionTypes.CHOOSE_NEW_REWARD_FOR_FAILED: {
      const payload = action.payload;

      const includedCustomers = payload.ids.map(id => {
        return { id, requiresCustomerDetails: true };
      });

      return {
        ...state,
        allSelectedCustomerIds: payload.ids,
        includedCustomers,
        loading: true,
        previewHtml: '',
        selectedIndividualIds: payload.ids,
        selectedTreat: {
          ...initialState.selectedTreat,
          earlierBatchId: payload.batchId,
          resentNewTreat: true
        },
        updated: { }
      };
    }

    case engage.ActionTypes.DELETE_DRAFT_AFTER_SEND_SUCCESS: {
      return {
        ...state,
        emailTemplates: initialState.emailTemplates,
        error: null,
        filterEndDate: null,
        filterOnBetweenId: initialState.filterOnBetweenId,
        filterStartDate: null,
        loading: false,
        selectedCustomers: [],
        selectedDefaultFilters: [],
        selectedIndividualIds: [],
        selectedSavedFilters: [],
        sendError: null
      };
    }

    case engage.ActionTypes.DELETE_EMAIL_DRAFT: {
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          loading: true
        }
      };
    }

    case engage.ActionTypes.DELETE_EMAIL_DRAFT_ERROR: {
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          loading: false
        }
      };
    }

    case engage.ActionTypes.DELETE_EMAIL_DRAFT_SUCCESS: {
      return {
        ...state,
        emailTemplates: initialState.emailTemplates
      };
    }

    case engage.ActionTypes.EDIT_EMAIL:
    case engage.ActionTypes.EDIT_EVENT:
    case engage.ActionTypes.SEND_DRAFT:
    case engage.ActionTypes.SEND_EMAIL:
    case engage.ActionTypes.SEND_EVENT:
    case engage.ActionTypes.SEND_REWARD: {
      return { ...state, sendLoading: true };
    }

    case engage.ActionTypes.EDIT_EMAIL_ERROR:
    case engage.ActionTypes.EDIT_EVENT_ERROR:
    case engage.ActionTypes.SEND_EMAIL_FAILURE:
    case engage.ActionTypes.SEND_EVENT_FAILURE:
    case engage.ActionTypes.SEND_REWARD_FAILURE: {
      const error = action.payload;

      return {
        ...state,
        loading: false,
        sendError: error,
        sendLoading: false
      };
    }

    case engage.ActionTypes.EDIT_EMAIL_SUCCESS:
    case engage.ActionTypes.SEND_EMAIL_SUCCESS: {
      const engagementId = action.payload;

      return {
        ...state,
        emailTemplates: initialState.emailTemplates,
        engagementId,
        error: null,
        filterEndDate: null,
        filterOnBetweenId: initialState.filterOnBetweenId,
        filterStartDate: null,
        loading: false,
        selectedCustomers: [],
        selectedDefaultFilters: [],
        selectedIndividualIds: [],
        selectedSavedFilters: [],
        sendError: null,
        sendLoading: false
      };
    }

    case engage.ActionTypes.EDIT_EVENT_SUCCESS:
    case engage.ActionTypes.SEND_EVENT_SUCCESS: {
      const payload = action.payload;

      return {
        ...state,
        engagementId: payload.eventId,
        error: null,
        eventTemplates: initialState.eventTemplates,
        filterEndDate: null,
        filterOnBetweenId: initialState.filterOnBetweenId,
        filterStartDate: null,
        loading: false,
        selectedCustomers: [],
        selectedDefaultFilters: [],
        selectedIndividualIds: [],
        selectedSavedFilters: [],
        sendError: null,
        sendLoading: false
      };
    }

    case engage.ActionTypes.FETCH_EMAIL_DRAFT_DETAILS_SUCCESS: {
      const {
        description,
        displayFilters,
        displayThemes,
        previewHtml,
        selectedFilters,
        themes
      } = action.payload;

      let {
        excludedCustomers,
        filterEndDate,
        filterOnBetweenId,
        filterStartDate,
        formattedSelectedFilters,
        includedCustomers
      } = separateExtraFilters(selectedFilters);

      formattedSelectedFilters = getFormattedSelectedFilters(formattedSelectedFilters, displayFilters);
      let defaultTheme = null;
      let stockImages = state.selectedEmail.stockImages;

      if (themes && themes.length > 0) {
        defaultTheme = find(propEq('title', state.selectedEmail.title))(themes) || themes[0];

        // The full list of images for the selected template will remain on the selectedEmail object
        // This stockImages variable will be only the images that have been filtered based on the selected theme
        const changeImageId = defaultTheme.changeImageId;
        stockImages = getFilteredStockImages(state.selectedEmail.stockImages, changeImageId);
      }

      return {
        ...state,
        excludedCustomers,
        filterEndDate,
        filterOnBetweenId,
        filterStartDate,
        includedCustomers,
        loading: false,
        previewHtml,
        selectedEmail: {
          ...defaultTheme,
          ...state.selectedEmail,
          details: description,
          displayThemes,
          subject: state.selectedEmail.subject
        },
        selectedDefaultFilters: formattedSelectedFilters,
        stockImages,
        themes
      };
    }

    case engage.ActionTypes.FETCH_EMAIL_TEMPLATES_FAILURE: {
      const error = action.payload;

      return {
        ...state,
        emailTemplates: {
          drafts: [],
          error,
          loading: false,
          templates: [],
          categories: EngageConstants.emailCategories
        }
      };
    }

    case engage.ActionTypes.FETCH_EMAIL_TEMPLATES_SUCCESS: {
      const { draftEmailTemplates, emailTemplates } = action.payload;

      return {
        ...state,
        emailTemplates: {
          drafts: draftEmailTemplates,
          error: null,
          loading: false,
          templates: emailTemplates,
          categories: EngageConstants.emailCategories
        }
      };
    }

    case engage.ActionTypes.FETCH_EVENT_TEMPLATE_HTML_SUCCESS: {
      const htmls = action.payload;

      return {
        ...state,
        ...htmls,
        loading: false
      };
    }

    case engage.ActionTypes.FETCH_EVENT_TEMPLATES_FAILURE: {
      const error = action.payload;

      return {
        ...state,
        eventTemplates: {
          error,
          loading: false,
          templates: []
        }
      };
    }

    case engage.ActionTypes.FETCH_EVENT_TEMPLATES_SUCCESS: {
      const templates = action.payload;

      return {
        ...state,
        eventTemplates: {
          error: null,
          loading: false,
          templates
        }
      };
    }

    case engage.ActionTypes.FETCH_REWARDS: {
      return {
        ...state,
        rewardTemplates: {
          ...state.rewardTemplates,
          loading: true
        }
      };
    }

    case engage.ActionTypes.FETCH_REWARDS_FAILURE: {
      const error = action.payload;

      return {
        ...state,
        rewardTemplates: {
          error,
          loading: false,
          ltoTemplates: [],
          templates: []
        }
      };
    }

    case engage.ActionTypes.FETCH_REWARDS_SUCCESS: {
      const templatesByCategory = action.payload;
      const deliveryEnabled = state.deliveryEnabled;
      let templates = []
      let ltoTemplates = [];

      templatesByCategory.forEach((item: any) => {
        if (item.categoryName === 'LTO') {
          ltoTemplates = item.rewards;
        } else {
          templates = item.rewards;
        }
      });

      // If delivery is not enabled for this location then remove the delivery reward from displaying
      if (!deliveryEnabled) {
        const deliveryIndex = findIndex(propEq('id', EngageConstants.deliveryRewardId))(templates);
        templates.splice(deliveryIndex, 1);
      }

      return {
        ...state,
        rewardTemplates: {
          error: null,
          loading: false,
          ltoTemplates,
          templates
        }
      };
    }

    case engage.ActionTypes.FETCH_TEMPLATE_DETAILS_SUCCESS: {
      let templateDetails = action.payload;
      const allStockImages = state.selectedEmail.stockImages;
      const selectedThemeId = state.selectedEmail.themeId;
      const themes = templateDetails.themes;
      let defaultTheme = null;

      if (themes && themes.length > 0) {
        // On calendar preview show the selected theme instead of the default, when possible
        // This is a new variable so older emails will not have themeId saved
        // selectedThemeId will be null here except on calendar preview
        if (selectedThemeId) {
          defaultTheme = find(propEq('themeId', selectedThemeId))(themes)
              || find(propEq('isDefaultTheme', true))(themes) || themes[0];
          templateDetails = { ...templateDetails, ...defaultTheme };
        } else {
          defaultTheme = find(propEq('isDefaultTheme', true))(themes) || themes[0];
          templateDetails = { ...templateDetails, ...defaultTheme };
        }
        // To set image for each theme if image is not available on theme selection
        themes.forEach((theme: any) => {
          if (!theme.imageUrl) {
            theme.imageUrl = theme.mobileImageUrl = templateDetails.imageUrl;
          }
        });
      }

      // Do not override the user's customized subject on calendar preview with the theme's subject
      let subject = state.selectedEmail.subject ? state.selectedEmail.subject : templateDetails.subject;
      if (state.mode !== EngageConstants.reviewMode && templateDetails.subject) {
        subject = templateDetails.subject;
      }

      // Do not override the user's selected image on calendar preview with the theme's default image
      let imageUrl = state.selectedEmail.imageUrl ? state.selectedEmail.imageUrl : templateDetails.imageUrl;
      if (state.mode !== EngageConstants.reviewMode && templateDetails.imageUrl) {
        imageUrl = templateDetails.imageUrl;
      }
      let mobileImageUrl = state.selectedEmail.mobileImageUrl ? state.selectedEmail.mobileImageUrl : templateDetails.mobileImageUrl;
      if (state.mode !== EngageConstants.reviewMode && templateDetails.mobileImageUrl) {
        mobileImageUrl = templateDetails.mobileImageUrl;
      }

      // The full list of images for the selected template will remain on the selectedEmail object
      // This stockImages variable will be only the images that have been filtered based on the default theme
      const changeImageId = templateDetails.changeImageId;
      const stockImages = getFilteredStockImages(allStockImages, changeImageId);

      return {
        ...state,
        loading: false,
        previewHtml: templateDetails.previewHtml,
        selectedEmail: {
          ...state.selectedEmail,
          ...defaultTheme,
          details: templateDetails.description,
          headerImage: defaultTheme ? defaultTheme.image : null,
          imageUrl,
          mobileHeaderImage: defaultTheme ? defaultTheme.mobileImage : null,
          mobileImageUrl,
          subject
        },
        stockImages,
        themes
      };
    }

    case engage.ActionTypes.FIND_FILTER_DISPLAY_CUSTOMERS_SUCCESS: {
      const payload = action.payload;
      let includedCustomers = clone(state.includedCustomers);

      // Merge the customer details with the ids here if required
      if (!state.getAllCustomers && any((customer) => customer.requiresCustomerDetails)(includedCustomers)) {
        includedCustomers.map((engageCustomer, index) => {
          if (engageCustomer.requiresCustomerDetails) {
            const newCustomerData = find(propEq('id', engageCustomer.id))(payload.customerProfiles);
            includedCustomers[index] = newCustomerData ? newCustomerData : includedCustomers[index];
          }
        });
      }

      const filterDisplayCustomerError = state.getAllCustomers ? state.filterDisplayCustomerError : null;
      const filterDisplayCustomerLoading = state.getAllCustomers ? state.filterDisplayCustomerLoading : false;
      const selectedCustomerLoading = state.getAllCustomers ? state.selectedCustomerLoading : false;

      let allSelectedCustomerIds = state.allSelectedCustomerIds;
      if (state.shouldSetSelectedCustomerIds && payload.allCustomerIds) {
        allSelectedCustomerIds = payload.allCustomerIds;
      }

      return {
        ...state,
        allSelectedCustomerIds,
        filterDisplayCustomerAllCustomerIds:  payload.allCustomerIds ? payload.allCustomerIds : [],
        filterDisplayCustomerCount: payload.totalElements,
        filterDisplayCustomerError,
        filterDisplayCustomerLoading,
        filterDisplayCustomerNumberOfPages: payload.numberOfPages,
        filterDisplayCustomers: payload.customerProfiles ? payload.customerProfiles : [],
        includedCustomers,
        selectedCustomerLoading,
        shouldSetSelectedCustomerIds: false
      };
    }

    case engage.ActionTypes.GET_CUSTOMERS_BY_ID_ERROR:
    case engage.ActionTypes.GET_EXCLUDED_CUSTOMERS_BY_ID_ERROR: {
      const filterDisplayCustomerError = action.payload;

      return {
        ...state,
        filterDisplayCustomerError,
        filterDisplayCustomerLoading: false,
        selectedCustomerLoading: false
      };
    }

    case engage.ActionTypes.GET_CUSTOMERS_BY_ID_SUCCESS: {
      const { customerProfiles } = action.payload;
      let includedCustomers = state.includedCustomers;

      // Only replace customer profiles that returned data
      customerProfiles.forEach((profile) => {
        const existingIndex = findIndex(propEq('id', profile.id))(includedCustomers);
        if (existingIndex > -1) {
          includedCustomers[existingIndex] = profile;
        }
      });

      return {
        ...state,
        filterDisplayCustomerError: null,
        filterDisplayCustomerLoading: false,
        getAllCustomers: false,
        includedCustomers,
        selectedCustomerLoading: false
      };
    }

    case engage.ActionTypes.GET_EXCLUDED_CUSTOMERS_BY_ID_SUCCESS: {
      const payload = action.payload;
      let excludedCustomers = clone(state.excludedCustomers);

      // Merge the customer details with the ids here if required
      excludedCustomers.map((engageCustomer, index) => {
        if (engageCustomer.requiresCustomerDetails) {
          excludedCustomers[index] = find(propEq('id', engageCustomer.id))(payload.customerProfiles);
        }
      });

      return {
        ...state,
        excludedCustomers,
        filterDisplayCustomerError: null,
        filterDisplayCustomerLoading: false,
        selectedCustomerLoading: false
      };
    }

    case engage.ActionTypes.GET_SAVED_FILTER_CUSTOMERS: {
      const filter = action.payload;
      const index = findIndex(propEq('id', filter.id))(state.savedFilters);
      let savedFilters = state.savedFilters;

      // Only set loading and error if the api call is going to be made
      if (!savedFilters[index].customerProfiles) {
        savedFilters[index].error = null;
        savedFilters[index].loading = true;
      }
      return { ...state, savedFilters };
    }

    case engage.ActionTypes.GET_SAVED_FILTER_CUSTOMERS_FAILURE: {
      const { filter, error } = action.payload;
      const index = findIndex(propEq('id', filter.id))(state.savedFilters);

      let savedFilters = state.savedFilters;
      savedFilters[index].error = error;
      savedFilters[index].loading = false;

      return { ...state, savedFilters };
    }

    case engage.ActionTypes.GET_SAVED_FILTER_CUSTOMERS_SUCCESS: {
      const filter = action.payload;
      const allCustomers = filter.allCustomers ? filter.allCustomers : [];
      const customerProfiles = filter.customerProfiles ? filter.customerProfiles : [];
      const index = findIndex(propEq('id', filter.id))(state.savedFilters);
      const numberOfCustomers = filter.numberOfCustomers;

      let savedFilters = state.savedFilters;
      savedFilters[index].allCustomers = allCustomers;
      savedFilters[index].customerProfiles = customerProfiles;
      savedFilters[index].error = null;
      savedFilters[index].loading = false;
      savedFilters[index].numberOfCustomers = numberOfCustomers;

      return { ...state, savedFilters };
    }

    case engage.ActionTypes.GET_SAVED_FILTERS: {
      return {
        ...state,
        savedFiltersError: initialState.savedFiltersError,
        savedFiltersLoading: true
      };
    }

    case engage.ActionTypes.GET_SAVED_FILTERS_FAILURE: {
      const savedFiltersError = action.payload;

      return { ...state, savedFiltersError, savedFiltersLoading: false };
    }

    case engage.ActionTypes.GET_SAVED_FILTERS_SUCCESS: {
      let savedFilters = action.payload;

      savedFilters = savedFilters.map((filter) => {
        return { ...filter, error: null, loading: false };
      });

      let selectedSavedFilters = state.selectedSavedFilters;

      // The backend only returns the ids for filters on calendar preview, so we need to load the
      // rest of the data based on the selected ids for the customer selection component
      // We also want to only run this code the first time the user goes to customer selection
      // from edit because selectedSavedFilters is an array of ids instead of objects
      if (state.mode === EngageConstants.editMode && selectedSavedFilters[0] && !selectedSavedFilters[0].id) {
        selectedSavedFilters = [];
        if (state.type === EngageConstants.event) {
          let preventDelete = (state.selectedEvent.sendDate != null) ? moment().isAfter(state.selectedEvent.sendDate) : true;
          state.selectedEvent.includedFilters.map((savedFilterId) => {
            let selectedFilter = find(propEq('id', savedFilterId))(savedFilters);
            // add preventDeletion so that user can't remove preexisting groups during edit
            selectedSavedFilters.push({ ...selectedFilter, preventDeletion: preventDelete });
          });
        } else {
          let preventDelete = (state.selectedEmail.sendDate != null) ? moment().isAfter(state.selectedEmail.sendDate) : true;
          state.selectedEmail.includedFilters.map((savedFilterId) => {
            let selectedFilter = find(propEq('id', savedFilterId))(savedFilters);
            // add preventDeletion so that user can't remove preexisting groups during edit
            selectedSavedFilters.push({ ...selectedFilter, preventDeletion: preventDelete });
          });
        }
      }

      return {
        ...state,
        savedFilters,
        savedFiltersError: null,
        savedFiltersLoading: false,
        selectedSavedFilters
      };
    }

    case engage.ActionTypes.GET_SELECTED_FILTER_DETAILS_SUCCESS: {
      const displayFilters = action.payload;
      const selectedFilters = clone(state.selectedDefaultFilters);
      const formattedSelectedFilters = getFormattedSelectedFilters(selectedFilters, displayFilters);

      return {
        ...state,
        loading: false,
        selectedDefaultFilters: formattedSelectedFilters
      };
    }

    case engage.ActionTypes.GET_SELECTED_INDIVIDUALS_DETAILS: {
      // Only want to fetch customer objects from ids if they are not already on the state
      const customerLoading = state.selectedCustomers.length !== state.selectedIndividualIds.length;

      return {
        ...state,
        customerError: initialState.customerError,
        customerLoading
      };
    }

    case engage.ActionTypes.GET_SELECTED_INDIVIDUALS_DETAILS_FAILURE: {
      const error = action.payload;

      return {
        ...state,
        customerError: error,
        customerLoading: false,
        selectedCustomers: []
      };
    }

    case engage.ActionTypes.GO_TO_EMAIL_EDIT: {
      let selectedCustomers = [];
      const allSelectedCustomerIds = clone(state.selectedEmail.cfaIds);
      const selectedDefaultFilters = clone(state.selectedEmail.selectedFilters);
      const selectedSavedFilters = clone(state.selectedEmail.selectedSavedGroups);

      const {
        excludedCustomers,
        filterEndDate,
        filterOnBetweenId,
        filterStartDate,
        formattedSelectedFilters,
        includedCustomers
      } = separateExtraFilters(selectedDefaultFilters);

      const allStockImages = state.selectedEmail.stockImages;
      const changeImageId = state.selectedEmail.changeImageId;
      const isDynamicGroupSend = state.selectedEmail.dynamicFlag;
      const isSendDateToday = moment().isSame(state.selectedEmail.sendDate, 'day');

      let sendTimeStartRestriction = SharedConstants.earliestTimeOption;
      if (!state.selectedEmail.sendImmediate && isSendDateToday) {
        sendTimeStartRestriction = getNextTimeByFifteenMinuteIncrements(0);
      }
      let preventDelete = (state.selectedEmail.sendDate != null) ? moment().isAfter(state.selectedEmail.sendDate) : true;
      if (state.selectedEmail.unOpenedEmailCustomers) {
        state.selectedEmail.unOpenedEmailCustomers.map((customer) => {
          if (state.selectedEmail.cfaIds.indexOf(customer.id) !== -1) {
            selectedCustomers.push({
              firstName: customer.firstName,
              fullName: customer.fullName,
              id: customer.id,
              lastName: customer.lastName,
              preventDeletion: preventDelete
            });
          }
        });
      }
      if (state.selectedEmail.openedEmailCustomers) {
        state.selectedEmail.openedEmailCustomers.map((customer) => {
          if (state.selectedEmail.cfaIds.indexOf(customer.id) !== -1) {
            selectedCustomers.push({
              firstName: customer.firstName,
              fullName: customer.fullName,
              id: customer.id,
              lastName: customer.lastName,
              preventDeletion: preventDelete
            });
          }
        });
      }

      // The full list of images for the selected template will remain on the selectedEmail object
      // This stockImages variable will be only the images that have been filtered based on the selected theme
      const stockImages = getFilteredStockImages(allStockImages, changeImageId);

      return {
        ...state,
        allSelectedCustomerIds,
        excludedCustomers,
        filterEndDate,
        filterOnBetweenId,
        filterStartDate,
        includedCustomers,
        isDynamicGroupSend,
        loading: !state.selectedEmail.showCustomers,
        mode: EngageConstants.editMode,
        selectedCustomers,
        selectedDefaultFilters: formattedSelectedFilters,
        selectedEmail: {
          ...state.selectedEmail,
          sendTimeStartRestriction
        },
        selectedSavedFilters,
        shouldSetSelectedCustomerIds: true,
        stockImages,
        updated: {}
      };
    }

    case engage.ActionTypes.GO_TO_ENGAGE_PAGE:
    case engage.ActionTypes.VIEW_ON_CALENDAR: {
      return {
        ...state,
        selectedEmail: initialState.selectedEmail,
        selectedEvent: initialState.selectedEvent,
        selectedTreat: initialState.selectedTreat
      };
    }

    case engage.ActionTypes.GO_TO_EVENT_EDIT: {
      let selectedCustomers = [];
      const allSelectedCustomerIds = clone(state.selectedEvent.recipientIds);
      const selectedDefaultFilters = clone(state.selectedEvent.selectedFilters);
      const selectedSavedFilters = clone(state.selectedEvent.selectedSavedGroups);

      const {
        excludedCustomers,
        filterEndDate,
        filterOnBetweenId,
        filterStartDate,
        formattedSelectedFilters,
        includedCustomers
      } = separateExtraFilters(selectedDefaultFilters);

      const isDynamicGroupSend = state.selectedEvent.dynamicFlag;
      const stockImages = state.selectedEvent.stockImages;
      let sendImmediate = state.selectedEvent.sendImmediate;
      let {
        endTimeRestriction,
        sendTimeEndRestriction,
        sendTimeStartRestriction,
        startTimeRestriction
      } = getEventDatesAndTimes(state.selectedEvent);

      // If scheduled future send date is actually in the past set it to send immediately instead
      if (!sendImmediate && moment().isSameOrAfter(state.selectedEvent.sendDate, 'day')) {
        sendImmediate = true;
      }

      const preventDelete = (state.selectedEvent.sendDate != null) ? moment().isAfter(state.selectedEvent.sendDate) : true;

      if (state.selectedEvent.acceptedRecipients) {
        state.selectedEvent.acceptedRecipients.map((currentCustomer) => {
          if (state.selectedEvent.recipientIds.indexOf(currentCustomer.id) !== -1) {
            selectedCustomers.push({
              firstName: currentCustomer.firstName,
              fullName: currentCustomer.fullName,
              id: currentCustomer.id,
              lastName: currentCustomer.lastName,
              preventDeletion: preventDelete
            });
          }
        });
      }
      if (state.selectedEvent.declinedRecipients) {
        state.selectedEvent.declinedRecipients.map((currentCustomer) => {
          if (state.selectedEvent.recipientIds.indexOf(currentCustomer.id) !== -1) {
            selectedCustomers.push({
              firstName: currentCustomer.firstName,
              fullName: currentCustomer.fullName,
              id: currentCustomer.id,
              lastName: currentCustomer.lastName,
              preventDeletion: preventDelete
            });
          }
        });
      }
      if (state.selectedEvent.noResponseRecipients) {
        state.selectedEvent.noResponseRecipients.map((currentCustomer) => {
          if (state.selectedEvent.recipientIds.indexOf(currentCustomer.id) !== -1) {
            selectedCustomers.push({
              firstName: currentCustomer.firstName,
              fullName: currentCustomer.fullName,
              id: currentCustomer.id,
              lastName: currentCustomer.lastName,
              preventDeletion: preventDelete
            });
          }
        });
      }

      return {
        ...state,
        allSelectedCustomerIds,
        excludedCustomers,
        filterEndDate,
        filterOnBetweenId,
        filterStartDate,
        includedCustomers,
        isDynamicGroupSend,
        loading: !state.selectedEvent.showCustomers,
        mode: EngageConstants.editMode,
        selectedCustomers,
        selectedDefaultFilters: formattedSelectedFilters,
        selectedEvent: {
          ...state.selectedEvent,
          endTimeRestriction,
          sendImmediate,
          sendTimeEndRestriction,
          sendTimeStartRestriction,
          startTimeRestriction
        },
        selectedSavedFilters: selectedSavedFilters ? selectedSavedFilters : [],
        shouldSetSelectedCustomerIds: true,
        stockImages,
        updated: {}
      };
    }

    case engage.ActionTypes.RECIPIENTS_SEARCH_TERM_CHANGED: {
      const recipientsSearchTerm = action.payload.recipientsSearchTerm;

      return {
        ...state,
        recipientsSearchTerm
      };
    }

    case engage.ActionTypes.REWARD_HTML_AND_METADATA_FOUND: {
      const payload = action.payload;
      const previewHtml = payload.templateHtml ? payload.templateHtml : '';
      const themes: TreatMetadata[] = payload.treatMetadata ? payload.treatMetadata : [];
      let selectedTheme = null;

      if (themes && themes.length > 0) {
        // Set preview image for each theme on theme selection
        themes.forEach((theme: any) => {
          if (!theme.imageUrl) {
            theme.imageUrl = theme.mobileImageUrl = state.selectedTreat.imageURL;
          }
        });

        // If on calendar preview, instead set the theme based on what the user selected
        if (state.selectedTreat.themeId) {
          selectedTheme = find(propEq('id', state.selectedTreat.themeId))(themes) || themes[0];
        } else {
          selectedTheme = find(propEq('isDefaultTheme', true))(themes) || themes[0];
        }
        // To keep theme, revert on back on theme selection
        state.selectedTreat.defaultSelectedTheme = selectedTheme.id;
      }

      // Do not override the user's customized subject on calendar preview with the theme's subject
      let subject = state.selectedTreat.subject;
      if (state.mode !== EngageConstants.reviewMode && selectedTheme && selectedTheme.subject) {
        subject = selectedTheme.subject;
      }

      return {
        ...state,
        loading: false,
        previewHtml,
        selectedTheme: selectedTheme ? selectedTheme.title : null,
        selectedTreat: {
          ...state.selectedTreat,
          ...selectedTheme,
          id: state.selectedTreat.id,
          subject,
          themeId: selectedTheme ? selectedTheme.id : null
        },
        themes
      };
    }

    case engage.ActionTypes.SAVE_EMAIL_DRAFT: {
      return { ...state, loading: true };
    }

    case engage.ActionTypes.SAVE_EMAIL_DRAFT_SUCCESS: {
      // Clear templates so that they will be reloaded with the correct drafts
      return {
        ...state,
        emailTemplates: initialState.emailTemplates,
        filterEndDate: null,
        filterOnBetweenId: initialState.filterOnBetweenId,
        filterStartDate: null,
        loading: false,
        selectedCustomers: [],
        selectedDefaultFilters: [],
        selectedIndividualIds: [],
        selectedSavedFilters: []
      };
    }

    case engage.ActionTypes.SELECT_ALL_CUSTOMERS: {
      const allSelectedCustomerIds = action.payload;
      const includedCustomers = allSelectedCustomerIds.map(id => {
        return { id, requiresCustomerDetails: true };
      });

      return {
        ...state,
        allSelectedCustomerIds,
        excludedCustomers: [],
        filterEndDate: null,
        filterDisplayCustomerCount: includedCustomers.length,
        filterDisplayCustomerLoading: true,
        filterOnBetweenId: initialState.filterOnBetweenId,
        filterStartDate: null,
        getAllCustomers: true,
        includedCustomers,
        selectedCustomerLoading: true,
        selectedDefaultFilters: [],
        selectedSavedFilters: []
      };
    }

    case engage.ActionTypes.SELECT_EMAIL_DRAFT: {
      let { draftObj, mode, type } = action.payload;
      // Omit that key to appear in the calendar page
      draftObj = omit(['sendStatus'], draftObj);
      draftObj = omit(['updateAt'], draftObj);
      draftObj = omit(['createdAt'], draftObj);
      mode = mode ? mode : state.mode;
      type = type ? type : state.type;

      const campaignName = draftObj.campaignName || null;
      const isDynamicGroupSend = draftObj.dynamicFlag;
      const selectedDefaultFilters = draftObj.selectedFilters;
      const selectedSavedFilters = draftObj.selectedSavedGroups;

      if (draftObj && !isEmpty(draftObj)) {
        const startDate = null;
        let sendDate = moment(draftObj.startDate);
        let sendTime = moment(draftObj.startDate).format('h:mm a');
        let fullSendDateString = `${sendDate.format('MM/DD/YYYY')} ${sendTime}`;
        let fullSendDate = moment(fullSendDateString, 'MM/DD/YYYY h:mm a');
        let isSendDateBeforeToday = fullSendDate.isBefore(moment());
        // If draft schedule time is actually in past
        let sendImmediate = isSendDateBeforeToday ? true : draftObj.sendImmediate;
        let sendTimeStartRestriction = SharedConstants.earliestTimeOption;
        let isSendDateToday = moment().isSame(sendDate, 'day');

        if (!sendImmediate && isSendDateToday) {
          sendTimeStartRestriction = getNextTimeByFifteenMinuteIncrements(0);
        }

        if (isSendDateBeforeToday) {
          sendDate = moment().add('7', 'days');
          sendTime = getNextTimeByFifteenMinuteIncrements(0);
        }
        // Change dates to Monday if they are initially set to Sunday
        if (sendDate.day() === 0) {
          sendDate = sendDate.add('1', 'days');
        }
        draftObj = {
          ...draftObj,
          emailCopy: draftObj.description,
          sendImmediate,
          sendDate,
          sendTime,
          sendTimeStartRestriction,
          startDate,
          subject: draftObj.name,
          templateName: draftObj.templateTitle
        };
      }

      return {
        ...state,
        campaignName,
        duplicateRecipients: [],
        error: null,
        getAllCustomers: true,
        isDynamicGroupSend,
        mode,
        selectedDefaultFilters,
        selectedEmail: {
          ...initialState.selectedEmail,
          ...draftObj,
        },
        selectedSavedFilters,
        shouldSetSelectedCustomerIds: true,
        type,
        updated: { template: true }
      };
    }

    case engage.ActionTypes.SELECT_ENGAGE_EMAIL: {
      let { emailObj, retainSelected, type, mode } = action.payload;
      let defaultTheme: any;
      type = type ? type : state.type;
      mode = mode ? mode : state.mode;
      const allSelectedCustomerIds = state.allSelectedCustomerIds;
      let getAllCustomers = false;

      // If allSelectedCustomerIds has ids then they have selected customers from meet and we need their profiles
      if (allSelectedCustomerIds && allSelectedCustomerIds.length > 0) {
        getAllCustomers = true;
      }

      const campaignName = emailObj.title || null;
      if (emailObj && !isEmpty(emailObj)) {
        let sendDate = moment().add('7', 'days');
        const sendTime = getNextTimeByFifteenMinuteIncrements(0);
        const sendTimeStartRestriction = SharedConstants.earliestTimeOption;
        // Change dates to Monday if they are initially set to Sunday
        if (sendDate.day() === 0) {
          sendDate = sendDate.add('1', 'days');
        }
        if (emailObj.themes && emailObj.themes.length > 0) {
          defaultTheme = find(propEq('isDefaultTheme', true))(emailObj.themes) || emailObj.themes[0];
        }
        emailObj = omit(['updateAt'], emailObj);
        emailObj = omit(['createdAt'], emailObj);
        emailObj = {
          ...emailObj,
          emailCopy: emailObj.defaultText,
          defaultEmailCopy: emailObj.defaultText,
          defaultImageUrl: emailObj.imageUrl,
          sendDate,
          sendTime,
          sendTimeStartRestriction,
          defaultSelectedTheme: defaultTheme ? defaultTheme.themeId : undefined
        };
      }

      return {
        ...state,
        allSelectedCustomerIds: retainSelected ? state.allSelectedCustomerIds : [],
        campaignName,
        customerLoading: state.customerLoading,
        duplicateRecipients: [],
        error: null,
        excludedCustomers: [],
        filterEndDate: null,
        filterOnBetweenId: initialState.filterOnBetweenId,
        filterStartDate: null,
        getAllCustomers,
        includedCustomers: retainSelected ? state.includedCustomers : [],
        isDynamicGroupSend: null,
        loading: true,
        mode,
        selectedCustomers: [],
        selectedDefaultFilters: [],
        selectedEmail: {
          ...initialState.selectedEmail,
          ...emailObj
        },
        selectedIndividualIds: retainSelected ? state.selectedIndividualIds : [],
        selectedSavedFilters: [],
        type,
        updated: { template: true }
      };
    }

    case engage.ActionTypes.SELECT_ENGAGE_EVENT: {
      let { eventObj, venue, retainSelected, type, mode } = action.payload;
      type = type ? type : state.type;
      mode = mode ? mode : state.mode;
      let defaultTheme: any;
      let themes = [];
      const selectedThemeId = state.selectedEvent.themeId;
      const campaignName = eventObj.title || null;
      const includedCustomers = retainSelected ? state.includedCustomers : [];
      const allSelectedCustomerIds = retainSelected ? state.allSelectedCustomerIds : [];
      let getAllCustomers = false;

      // If allSelectedCustomerIds has ids then they have selected customers from meet and we need their profiles
      if (allSelectedCustomerIds && allSelectedCustomerIds.length > 0) {
        getAllCustomers = true;
      }

      if (eventObj && !isEmpty(eventObj)) {
        if (eventObj.themes && eventObj.themes.length > 0 && eventObj.displayThemes) {
          themes = eventObj.themes;
          defaultTheme = find(propEq('isDefaultTheme', true))(eventObj.themes) || eventObj.themes[0];
        }

        if (themes && themes.length > 0) {
          // On calendar preview show the selected theme instead of the default, when possible
          // This is a new variable so older emails will not have themeId saved
          // selectedThemeId will be null here except on calendar preview
          if (selectedThemeId) {
            defaultTheme = find(propEq('themeId', selectedThemeId))(themes) || find(propEq('isDefaultTheme', true))(themes) || themes[0];
            eventObj = { ...eventObj, ...defaultTheme };
          } else {
            defaultTheme = find(propEq('isDefaultTheme', true))(themes) || themes[0];
            eventObj = { ...eventObj, ...defaultTheme };
          }
          // To set image for each theme if image is not available on theme selection
          themes.forEach((theme: any) => {
            if (!theme.imageUrl) {
              theme.imageUrl = theme.mobileImageUrl = eventObj.imageUrl;
            }
          });
        }

        let endDate = moment().add('7', 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        let sendDate = moment().add('7', 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        let startDate = moment().add('7', 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        // Change dates to Monday if they are initially set to Sunday
        if (startDate.day() === 0) {
          startDate = startDate.add('1', 'days');
          endDate = endDate.add('1', 'days');
          sendDate = sendDate.add('1', 'days');
        }

        eventObj = {
          ...eventObj,
          defaultEmailCopy: eventObj.defaultText,
          defaultImageUrl: eventObj.imageUrl,
          defaultSelectedTheme: defaultTheme ? defaultTheme.themeId : undefined,
          emailCopy: eventObj.defaultText,
          endDate,
          endTime: getNextTimeByFifteenMinuteIncrements(8),
          endTimeRestriction: getNextTimeByFifteenMinuteIncrements(0),
          imageUrl: eventObj.imageUrl,
          imageUrl2: eventObj.image || eventObj.imageUrl2,
          sendDate,
          sendTime: getNextTimeByFifteenMinuteIncrements(0),
          sendTimeEndRestriction: getNextTimeByFifteenMinuteIncrements(0),
          startTimeRestriction: SharedConstants.earliestTimeOption,
          sendTimeStartRestriction: SharedConstants.earliestTimeOption,
          startDate,
          startTime: getNextTimeByFifteenMinuteIncrements(0),
          title:  eventObj.title,
          venue
        };
      }
      return {
        ...state,
        allSelectedCustomerIds,
        campaignName: campaignName,
        customerLoading: state.customerLoading,
        duplicateRecipients: [],
        error: null,
        excludedCustomers: [],
        filterEndDate: null,
        filterOnBetweenId: initialState.filterOnBetweenId,
        filterStartDate: null,
        getAllCustomers,
        includedCustomers,
        isCompleted: false,
        isDynamicGroupSend: null,
        loading: true,
        mode,
        overlappingEngagements: initialState.overlappingEngagements,
        selectedCustomers: [],
        selectedDefaultFilters: [],
        selectedEvent: {
          ...initialState.selectedEvent,
          ...eventObj
        },
        selectedIndividualIds: retainSelected ? state.selectedIndividualIds : [],
        selectedSavedFilters: [],
        stockImages: eventObj.stockImages ? eventObj.stockImages : [],
        themes: themes,
        type,
        updated: { template: true }
      };
    }

    case engage.ActionTypes.SELECT_ENGAGE_REWARD: {
      let { rewardObj, retainSelected, isRewardResend, type, mode } = action.payload;
      type = type ? type : state.type;
      mode = mode ? mode : state.mode;
      const campaignName = rewardObj.offerName || null;
      const allSelectedCustomerIds = state.allSelectedCustomerIds;
      let getAllCustomers = false;

      // If allSelectedCustomerIds has ids then they have selected customers from meet and we need their profiles
      if (allSelectedCustomerIds && allSelectedCustomerIds.length > 0) {
        getAllCustomers = true;
      }

      // Create a moment out of the endDateRestriction at end of day
      // so that date-picker can handle filtering out of days
      let endDateRestriction = rewardObj.endDateRestriction ?
        moment(rewardObj.endDateRestriction).endOf('day') : null;

      // Check to make sure that the initial reward end date is not past the end date restriction
      let endDate = initialState.selectedTreat.endDate;
      endDate = endDate.isAfter(endDateRestriction) ? endDateRestriction : endDate;
      const endDateCountdownTimer = endDate.format('YYYY-MM-DD');

      return {
        ...state,
        allSelectedCustomerIds: retainSelected ? state.allSelectedCustomerIds : [],
        campaignName,
        error: null,
        excludedCustomers: [],
        filterEndDate: null,
        filterOnBetweenId: initialState.filterOnBetweenId,
        filterStartDate: null,
        getAllCustomers,
        includedCustomers: retainSelected ? state.includedCustomers : [],
        isDynamicGroupSend: null,
        loading: true,
        mode,
        overlappingEngagements: initialState.overlappingEngagements,
        selectedCustomers: [],
        selectedDefaultFilters: [],
        selectedIndividualIds: retainSelected ? state.selectedIndividualIds : [],
        selectedSavedFilters: [],
        selectedTreat: {
          ...initialState.selectedTreat,
          ...rewardObj,
          defaultEmailCopy: rewardObj.emailCopy,
          endDate,
          endDateCountdownTimer,
          endDateRestriction,
          earlierBatchId: isRewardResend ? state.selectedTreat.earlierBatchId : null,
          resentNewTreat: isRewardResend
        },
        stockImages: [],
        type,
        updated: { template: true }
      };
    }

    case engage.ActionTypes.SELECT_EVENT_END_DATE: {
      const newEndDate = action.payload;
      const eventState = { ...state.selectedEvent, endDate: newEndDate };

      const {
        endDate,
        endTime,
        endTimeRestriction
      } = getEventDatesAndTimes(eventState);

      return {
        ...state,
        overlappingEngagements: initialState.overlappingEngagements,
        selectedEvent: {
          ...state.selectedEvent,
          endDate,
          endTime,
          endTimeRestriction
        },
        updated: { ...state.updated, endDate: true }
      };
    }

    case engage.ActionTypes.SELECT_EVENT_START_DATE: {
      let newStartDate = action.payload;
      let oldStartDate
        = moment(clone(state.selectedEvent.startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      let daysChanged = newStartDate.diff(oldStartDate, 'days');
      let oldEndDate = state.selectedEvent.endDate;
      let newEndDate = moment(oldEndDate).add(daysChanged, 'days');
      let eventState = { ...state.selectedEvent, startDate: newStartDate, endDate: newEndDate, mode: state.mode };

      let {
        endDate,
        endTime,
        endTimeRestriction,
        sendDate,
        sendTime,
        sendTimeEndRestriction,
        sendTimeStartRestriction,
        startDate,
        startTime,
        startTimeRestriction
      } = getEventDatesAndTimes(eventState);

      // Set RSVP reminders if new start date causes them to not be enabled
      let sendReminderEmails = state.selectedEvent.sendReminderEmails;
      let sendRsvpReminder = state.selectedEvent.sendRsvpReminder;
      let sendNonRsvpReminder = state.selectedEvent.sendNonRsvpReminder;
      let sendImmediate = state.selectedEvent.sendImmediate;
      // RSVP reminders are based on the time between the invite sending out and the event start
      let inviteSendDate = sendImmediate ? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : sendDate;

      if (!inviteSendDate || startDate.diff(inviteSendDate, 'days') < 4) {
        sendNonRsvpReminder = false;

        if (!inviteSendDate || startDate.diff(inviteSendDate, 'days') < 2) {
          sendReminderEmails = false;
          sendRsvpReminder = false;
        }
      }

      return {
        ...state,
        overlappingEngagements: initialState.overlappingEngagements,
        selectedEvent: {
          ...state.selectedEvent,
          endDate,
          endTime,
          endTimeRestriction,
          sendDate,
          sendNonRsvpReminder,
          sendReminderEmails,
          sendRsvpReminder,
          sendTime,
          sendTimeEndRestriction,
          sendTimeStartRestriction,
          startDate,
          startTime,
          startTimeRestriction
        },
        updated: { ...state.updated, startDate: true }
      };
    }

    case engage.ActionTypes.SELECT_EVENT_START_TIME: {
      const newStartTime = action.payload;
      const eventState = { ...state.selectedEvent, startTime: newStartTime };

      let {
        endTime,
        endTimeRestriction,
        sendTime,
        sendTimeEndRestriction,
        sendTimeStartRestriction,
        startTime
      } = getEventDatesAndTimes(eventState);

      return {
        ...state,
        selectedEvent: {
          ...state.selectedEvent,
          endTime,
          endTimeRestriction,
          sendTime,
          sendTimeEndRestriction,
          sendTimeStartRestriction,
          startTime
        },
        updated: { ...state.updated, startTime: true }
      };
    }

    case engage.ActionTypes.SELECT_REWARD_DATES: {
      let { startDate, endDate, type, treatLength } = action.payload;
      let maxDaysAllowed = 365;
      let isDynamicGroupSend;
      let showCountdownTimer = '';
      let endDateCountdownTimer = endDate;
      isDynamicGroupSend = state.isDynamicGroupSend;

      // logic to handle reward date change logic
      if (type && type === 'reward') {
        const oldStartDate = state.selectedTreat.startDate;
        const endDateRestriction = state.selectedTreat.endDateRestriction;

        const startDateDayAmountChange = startDate.diff(oldStartDate, 'days');

        if (endDate) {
          // Add a day if endDate is on a Sunday
          // and then make sure that it is not past the endDateRestriction
          let maxEnd = moment().add(maxDaysAllowed, 'days');
          let end = clone(endDate).add(startDateDayAmountChange, 'days');
          end = end.isAfter(maxEnd) ? maxEnd : end;
          if (end && end.weekday() === 0) {
            end.add('1', 'days');
          }
          if (maxEnd && maxEnd.weekday() === 0) {
            maxDaysAllowed = 366;
          }

          endDate = end.isAfter(moment(endDateRestriction)) ? endDateRestriction : end;
          endDateCountdownTimer = endDate.format('YYYY-MM-DD');
        }

        if (state.isDynamicGroupSend === null && moment().add(1, 'd').isBefore(moment(startDate))) {
          isDynamicGroupSend = true;
        }

        // Only show countdown for rewards that are longer than 1 day and end more than 1 day away
        const longerThan1Day = moment(endDate.format('YYYY-MM-DD')).diff(moment(startDate.format('YYYY-MM-DD')), 'days') > 1;
        const moreThan1DayAway = moment(endDate.format('YYYY-MM-DD')).isAfter(moment().format('YYYY-MM-DD'));
        showCountdownTimer = longerThan1Day && moreThan1DayAway ? '' : 'style="display: none;"';
      }

      const selectedTreat = {
        ...state.selectedTreat,
        endDate,
        endDateCountdownTimer,
        maxDaysAllowed,
        showCountdownTimer,
        startDate,
        treatLength
      };

      return {
        ...state,
        isDynamicGroupSend,
        overlappingEngagements: initialState.overlappingEngagements,
        selectedTreat
      };
    }

    case engage.ActionTypes.SEND_DRAFT_SUCCESS: {
      let { engagementId } = action.payload;

      return {
        ...state,
        engagementId,
        sendLoading: false,
        type: EngageConstants.email
      };
    }

    case engage.ActionTypes.SEND_REWARD_SUCCESS: {
      const { batchId, duplicateRecipients, holdOutRecipients, offerRecipients } = action.payload;

      return {
        ...state,
        duplicateRecipients,
        engagementId: batchId,
        error: null,
        filterEndDate: null,
        filterOnBetweenId: initialState.filterOnBetweenId,
        filterStartDate: null,
        holdOutRecipients,
        loading: false,
        offerRecipients,
        rewardTemplates: initialState.rewardTemplates,
        selectedCustomers: [],
        selectedDefaultFilters: [],
        selectedIndividualIds: [],
        selectedSavedFilters: [],
        sendError: null,
        sendLoading: false
      };
    }

    case engage.ActionTypes.SET_GROUP_SEND_OPTION: {
      const isDynamicGroupSend = action.payload;

      return {
        ...state,
        isDynamicGroupSend
      };
    }

    case engage.ActionTypes.SET_SELECTED_DISPLAY_FILTERS: {
      return {
        ...state,
        filterDisplayCustomerError: null,
        filterDisplayCustomerLoading: true
      };
    }

    case engage.ActionTypes.SET_SELECTED_TAB_INDEX: {
      const selectedTabIndex = action.payload;

      return { ...state, selectedTabIndex };
    }

    case engage.ActionTypes.UPDATE_CAMPAIGN_NAME: {
      const campaignName = action.payload;

      return {
        ...state,
        campaignName,
        updated: {
          ...state.updated,
          campaignName: true
        }
      };
    }

    case engage.ActionTypes.UPDATE_EMAIL_SEND_DATE: {
      let sendDate = action.payload;
      let sendTimeStartRestriction = SharedConstants.earliestTimeOption;
      let sendTime = state.selectedEmail.sendTime;
      const type = state.type;

      if (type === EngageConstants.email || type === EngageConstants.draft) {
        let startOfToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        // If sendDate is current day, set sendTimeStartRestriction to be the nearest increment
        if (startOfToday.diff(sendDate, 'days') === 0) {
          sendTimeStartRestriction = getNextTimeByFifteenMinuteIncrements(0);

          // If currently selected sendTime is before the restriction, set it to restriction
          const format = 'h:mm a';
          if (moment(sendTime, format).isBefore(moment(sendTimeStartRestriction, format))) {
            sendTime = sendTimeStartRestriction;
          }
        }

        return {
          ...state,
          selectedEmail: {
            ...state.selectedEmail,
            sendDate,
            sendTime,
            sendTimeStartRestriction
          },
          updated: { ...state.updated, emailSendDate: true }
        };
      } else if (type === EngageConstants.event) {
        let eventState = { ...state.selectedEvent, sendDate };

        let {
          sendTime,
          sendTimeEndRestriction,
          sendTimeStartRestriction
        } = getEventDatesAndTimes(eventState);

        // Set RSVP reminders if new start date causes them to not be enabled
        let sendReminderEmails = state.selectedEvent.sendReminderEmails;
        let sendRsvpReminder = state.selectedEvent.sendRsvpReminder;
        let sendNonRsvpReminder = state.selectedEvent.sendNonRsvpReminder;
        let startDate = state.selectedEvent.startDate;
        let sendImmediate = state.selectedEvent.sendImmediate;
        // RSVP reminders are based on the time between the invite sending out and the event start
        let inviteSendDate = sendImmediate ? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : sendDate;

        if (startDate.diff(inviteSendDate, 'days') < 4) {
          sendNonRsvpReminder = false;

          if (startDate.diff(inviteSendDate, 'days') < 2) {
            sendReminderEmails = false;
            sendRsvpReminder = false;
          }
        }

        return {
          ...state,
          selectedEvent: {
            ...state.selectedEvent,
            sendDate,
            sendNonRsvpReminder,
            sendReminderEmails,
            sendRsvpReminder,
            sendTime,
            sendTimeEndRestriction,
            sendTimeStartRestriction
          },
          updated: { ...state.updated, emailSendDate: true }
        };
      } else {
        return state;
      }
    }

    case engage.ActionTypes.UPDATE_ENGAGEMENT: {
      const payload = action.payload;
      const type = state.type;
      const propUpdated = payload.propUpdated;
      let updated = { ...state.updated, [propUpdated]: true };

      switch (type) {
        case EngageConstants.offer: {
          if (propUpdated === EngageConstants.emailCopyProp) {
            updated[propUpdated] = (state.selectedTreat.defaultEmailCopy !== payload[propUpdated]);
          }

          return {
            ...state,
            selectedTreat: {
              ...state.selectedTreat,
              ...payload
            },
            updated
          };
        }

        case EngageConstants.email:
        case EngageConstants.draft: {
          if (propUpdated === EngageConstants.emailCopyProp) {
            updated[propUpdated] = (state.selectedEmail.defaultEmailCopy !== payload[propUpdated]);
          } else if (propUpdated === EngageConstants.imageUrlProp) {
            updated[propUpdated] = (state.selectedEmail.defaultImageUrl !== payload[propUpdated]);
            payload.defaultImageUrl = updated[propUpdated] ? payload[propUpdated] : state.selectedEmail.defaultImageUrl;
          }

          return {
            ...state,
            selectedEmail: {
              ...state.selectedEmail,
              ...payload
            },
            updated
          };
        }

        case EngageConstants.event: {
          if (propUpdated === EngageConstants.emailCopyProp) {
            updated[propUpdated] = (state.selectedEvent.defaultEmailCopy !== payload[propUpdated]);
          } else if (propUpdated === EngageConstants.imageUrlProp) {
            updated[propUpdated] = (state.selectedEvent.defaultImageUrl !== payload[propUpdated]);
            payload.defaultImageUrl = updated[propUpdated] ? payload[propUpdated] : state.selectedEvent.defaultImageUrl;
          }

          return {
            ...state,
            selectedEvent: {
              ...state.selectedEvent,
              ...payload
            },
            updated
          };
        }

        default: {
          return state;
        }
      }
    }

    case engage.ActionTypes.UPDATE_LIMITED_RESERVATIONS: {
      const limitedReservations = action.payload;

      return {
        ...state,
        selectedEvent: {
          ...state.selectedEvent,
          limitedReservations,
          maxNumberOfReservations: ''
        },
        updated: { ...state.updated, limitedReservations: true }
      };
    }

    case engage.ActionTypes.UPDATE_NON_RSVP_REMINDER: {
      const sendNonRsvpReminder = action.payload;
      let sendReminderEmails = state.selectedEvent.sendReminderEmails;

      if (!sendNonRsvpReminder && !state.selectedEvent.sendRsvpReminder) {
        sendReminderEmails = false;
      }

      return {
        ...state,
        selectedEvent: {
          ...state.selectedEvent,
          sendNonRsvpReminder,
          sendReminderEmails
        },
        updated: { ...state.updated, nonRsvpReminder: true }
      };
    }

    case engage.ActionTypes.UPDATE_RECRUITMENT_MODULE_ENABLED: {
      const { recruitmentModuleEnabled } = action.payload;
      const recruitmentModuleLink = recruitmentModuleEnabled ? EngageConstants.defaultRecruitmentModuleLink : null;
      const recruitmentModuleOldLink = recruitmentModuleLink;
      const type = state.type;

      switch (type) {
        case EngageConstants.offer: {
          return {
            ...state,
            selectedTreat: {
              ...state.selectedTreat,
              recruitmentModuleEnabled,
              recruitmentModuleLink,
              recruitmentModuleOldLink
            },
            updated: {
              ...state.updated,
              recruitmentModuleEnabled
            }
          };
        }

        case EngageConstants.event: {
          return {
            ...state,
            selectedEvent: {
              ...state.selectedEvent,
              recruitmentModuleEnabled,
              recruitmentModuleLink
            },
            updated: {
              ...state.updated,
              recruitmentModuleEnabled: true
            }
          };
        }

        case EngageConstants.email:
        case EngageConstants.draft: {
          return {
            ...state,
            selectedEmail: {
              ...state.selectedEmail,
              recruitmentModuleEnabled,
              recruitmentModuleLink
            },
            updated: {
              ...state.updated,
              recruitmentModuleEnabled: true
            }
          };
        }

        default: {
          return state;
        }
      }
    }

    case engage.ActionTypes.UPDATE_RECRUITMENT_MODULE_LINK: {
      const { recruitmentModuleLink } = action.payload;
      const type = state.type;

      switch (type) {
        case EngageConstants.offer: {
          return {
            ...state,
            selectedTreat: {
              ...state.selectedTreat,
              recruitmentModuleLink,
              recruitmentModuleOldLink: recruitmentModuleLink
            },
            updated: {
              ...state.updated,
              recruitmentModuleLink: true
            }
          };
        }

        case EngageConstants.event: {
          return {
            ...state,
            selectedEvent: {
              ...state.selectedEvent,
              recruitmentModuleLink
            },
            updated: {
              ...state.updated,
              recruitmentModuleLink: true
            }
          };
        }

        case EngageConstants.email:
        case EngageConstants.draft: {
          return {
            ...state,
            selectedEmail: {
              ...state.selectedEmail,
              recruitmentModuleLink
            },
            updated: {
              ...state.updated,
              recruitmentModuleLink: true
            }
          };
        }

        default: {
          return state;
        }
      }
    }

    case engage.ActionTypes.UPDATE_REMINDER_EMAIL_SEND: {
      const sendReminderEmails = action.payload;
      const eventSendDate = state.selectedEvent.sendDate;
      const eventStartDate = state.selectedEvent.startDate;
      const sendImmediate = state.selectedEvent.sendImmediate;
      let sendNonRsvpReminder = state.selectedEvent.sendNonRsvpReminder;
      let sendRsvpReminder;
      // RSVP reminders are based on the time between the invite sending out and the event start
      let inviteSendDate = sendImmediate ? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : eventSendDate;

      if (sendReminderEmails) {
        sendRsvpReminder = true;

        // Only set non rsvp reminder to true if it is an available option
        if (eventStartDate.diff(inviteSendDate, 'days') > 3) {
          sendNonRsvpReminder = true;
        }
      } else {
        sendRsvpReminder = false;
        sendNonRsvpReminder = false;
      }

      return {
        ...state,
        selectedEvent: {
          ...state.selectedEvent,
          sendNonRsvpReminder,
          sendReminderEmails,
          sendRsvpReminder
        },
        updated: { ...state.updated, reminderEmailSend: true }
      };
    }

    case engage.ActionTypes.UPDATE_RSVP_REMINDER: {
      let sendRsvpReminder = action.payload;
      let sendReminderEmails = state.selectedEvent.sendReminderEmails;

      if (!sendRsvpReminder && !state.selectedEvent.sendNonRsvpReminder) {
        sendReminderEmails = false;
      }

      return {
        ...state,
        selectedEvent: {
          ...state.selectedEvent,
          sendReminderEmails,
          sendRsvpReminder
        },
        updated: { ...state.updated, rsvpReminder: true }
      };
    }

    case engage.ActionTypes.UPDATE_RSVP_REQUIRED: {
      const rsvpRequired = action.payload;
      return {
        ...state,
        selectedEvent: {
          ...state.selectedEvent,
          limitedReservations: false,
          maxNumberOfReservations: '',
          rsvpRequired
        },
        updated: { ...state.updated, rsvpRequired: true }
      };
    }

    case engage.ActionTypes.UPDATE_SELECTED_CUSTOMERS_AND_FILTERS: {
      // Need to maintain both the individually selected customers and their ids separately
      const { selectedCustomers, selectedSavedFilters, selectedDefaultFilters } = action.payload;
      let selectedIndividualIds = state.selectedIndividualIds;

      // Only pull new ids if there is no customer error
      if (!state.customerError) {
        selectedIndividualIds = flatten(pluck('id', selectedCustomers));
      }

      return {
        ...state,
        selectedCustomers,
        selectedDefaultFilters,
        selectedIndividualIds,
        selectedSavedFilters,
        updated: { ...state.updated, customers: true }
      };
    }

    case engage.ActionTypes.UPDATE_SELECTED_EMAIL_CATEGORIES: {
      let updatedCategory = action.payload;
      updatedCategory.selected = !updatedCategory.selected;
      let currentCategories = clone(state.emailTemplates.categories);
      if (updatedCategory.templateCategory == EngageConstants.allEmailsCategory && updatedCategory.selected) {
        currentCategories.map(category => {
          category.selected = category.templateCategory == EngageConstants.allEmailsCategory
            || (category.templateCategory == EngageConstants.draftCategory && category.selected)
          return category;
        })
      } else if (updatedCategory.selected) {
        currentCategories.map(category => {
          if (updatedCategory.templateCategory != EngageConstants.draftCategory
            && category.templateCategory == EngageConstants.allEmailsCategory) {
            category.selected = false;
          } else if (category.templateCategory == updatedCategory. templateCategory) {
            category.selected = updatedCategory.selected
          }
          return category
        })
      }
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          categories: currentCategories
        }
      }
    }

    case engage.ActionTypes.UPDATE_SELECTED_TAB_INDEX_FOR_HTML_PREVIEW: {
      let index = action.payload;

      return {
        ...state,
        selectedHtmlTabIndex: index
      };
    }

    case engage.ActionTypes.UPDATE_SEND_IMMEDIATE: {
      const sendImmediate = action.payload;
      const type = state.type;
      let isDynamicGroupSend = null;

      if (state.mode === EngageConstants.editMode) {
        isDynamicGroupSend = state.isDynamicGroupSend;
      }

      if ((type === EngageConstants.email || type === EngageConstants.event || type === EngageConstants.draft) && !sendImmediate
          && (state.selectedSavedFilters.length > 0 || state.selectedDefaultFilters.length > 0)
          && ( state.mode !== EngageConstants.editMode)) {
        isDynamicGroupSend = true;
      }

      if (type === EngageConstants.email || type === EngageConstants.draft) {
        return {
          ...state,
          isDynamicGroupSend,
          selectedEmail: {
            ...state.selectedEmail,
            sendImmediate
          },
          updated: { ...state.updated, sendImmediate: true }
        };
      } else if (type === EngageConstants.event) {
        if (sendImmediate) {
          return {
            ...state,
            isDynamicGroupSend,
            selectedEvent: {
              ...state.selectedEvent,
              sendImmediate
            },
            updated: { ...state.updated, sendImmediate: true }
          };
        } else {
          // Default to whatever the start date and time is
          const sendTime = state.selectedEvent.startTime;
          const sendDate = state.selectedEvent.startDate;
          const sendTimeStartRestriction = state.selectedEvent.startTimeRestriction;
          const sendTimeEndRestriction = state.selectedEvent.startTime;

          return {
            ...state,
            isDynamicGroupSend,
            selectedEvent: {
              ...state.selectedEvent,
              sendDate,
              sendImmediate,
              sendNonRsvpReminder: false,
              sendReminderEmails: false,
              sendRsvpReminder: false,
              sendTime,
              sendTimeEndRestriction,
              sendTimeStartRestriction
            },
            updated: { ...state.updated, sendImmediate: true }
          };
        }
      } else {
        return state;
      }
    }

    case engage.ActionTypes.UPDATE_SEND_OPERATOR_IMAGE: {
      const sendOperatorImage = action.payload;

      return {
        ...state,
        selectedTreat: {
          ...state.selectedTreat,
          sendOperatorImage
        }
      };
    }

    case engage.ActionTypes.UPDATE_THEME: {
      const payload = action.payload;
      const propUpdated = 'theme';
      const updated = { ...state.updated, theme: true };

      switch (state.type) {
        case EngageConstants.offer: {
          const themeId = payload.id ? payload.id : null;
          const selectedTheme = payload.title;

          if (propUpdated === EngageConstants.themeProp) {
            updated[propUpdated] = (state.selectedTreat.defaultSelectedTheme !== themeId);
            payload.defaultSelectedTheme = updated[propUpdated] ? themeId : state.selectedTreat.defaultSelectedTheme;

            if (state.updated.recruitmentModuleEnabled &&
                (themeId === EngageConstants.themeSorryForTheSnag || themeId === EngageConstants.themeWeApologize)) {
              payload.recruitmentModuleEnabled = false;
              payload.recruitmentModuleLink = null;
            } else if (state.updated.recruitmentModuleEnabled) {
              payload.recruitmentModuleEnabled = true;
              payload.recruitmentModuleLink = state.selectedTreat.recruitmentModuleOldLink;
            }
          }

          const selectedTreat = {
            ...state.selectedTreat,
            ...payload,
            id: state.selectedTreat.id,
            themeId
          };

          return { ...state, selectedTheme, selectedTreat, updated };
        }

        case EngageConstants.email:
        case EngageConstants.draft: {
          const themeId = payload.themeId ? payload.themeId : null;

          if (propUpdated === EngageConstants.themeProp) {
            updated[propUpdated] = (state.selectedEmail.defaultSelectedTheme !== themeId);
            payload.defaultSelectedTheme = updated[propUpdated] ? themeId : state.selectedEmail.defaultSelectedTheme;
          }

          const selectedEmail = {
            ...state.selectedEmail,
            ...payload,
            emailCopy: payload.defaultText ? payload.defaultText : state.selectedEmail.emailCopy,
            emailheadline: !state.selectedEmail.displayDefaultHeadline && payload.emailheadline
              ? payload.emailheadline : state.selectedEmail.emailheadline,
            headerImage: payload.image,
            mobileHeaderImage: payload.mobileImage,
            themeId
          };
          const allStockImages = state.selectedEmail.stockImages;
          const changeImageId = payload.changeImageId;

          // The full list of images for the selected template will remain on the selectedEmail object
          // This stockImages variable will be only the images that have been filtered based on the selected theme
          const stockImages = getFilteredStockImages(allStockImages, changeImageId);

          return { ...state, selectedEmail, stockImages, updated };
        }

        case EngageConstants.event: {
          const themeId = payload.themeId ? payload.themeId : null;

          if (propUpdated === EngageConstants.themeProp) {
            updated[propUpdated] = (state.selectedEvent.defaultSelectedTheme !== themeId);
            payload.defaultSelectedTheme = updated[propUpdated] ? themeId : state.selectedEvent.defaultSelectedTheme;
          }

          const selectedEvent = {
            ...state.selectedEvent,
            ...payload,
            emailCopy: payload.defaultText ? payload.defaultText : state.selectedEvent.emailCopy,
            emailheadline: !state.selectedEvent.displayDefaultHeadline && payload.emailheadline
              ? payload.emailheadline : state.selectedEvent.emailheadline,
            headerImage: payload.image,
            imageUrl2: payload.image,
            mobileHeaderImageUrl: payload.mobileImage,
            themeId,
            title: payload.title
          };
          const allStockImages = state.selectedEvent.stockImages;
          const changeImageId = payload.changeImageId;

          // The full list of images for the selected template will remain on the selectedEvent object
          // This stockImages variable will be only the images that have been filtered based on the selected theme
          const stockImages = getFilteredStockImages(allStockImages, changeImageId);

          return { ...state, selectedEvent, stockImages, updated };
        }

        default: {
          return state;
        }
      }
    }

    case engagement.ActionTypes.UPDATE_ENGAGEMENT_DATES: {
      return {
        ...state,
        overlappingEngagements: initialState.overlappingEngagements,
      };
    }

    case meet.ActionTypes.ADD_INDIVIDUAL_EXCLUDE_CUSTOMER:
    case meet.ActionTypes.ADD_INDIVIDUAL_INCLUDE_CUSTOMER:
    case meet.ActionTypes.ADD_SAVED_GROUP:
    case meet.ActionTypes.ADD_SELECTED_FILTER:
    case meet.ActionTypes.CLEAR_FILTERS:
    case meet.ActionTypes.REMOVE_INDIVIDUAL_EXCLUDE_CUSTOMER:
    case meet.ActionTypes.REMOVE_INDIVIDUAL_INCLUDE_CUSTOMER:
    case meet.ActionTypes.REMOVE_SAVED_GROUP:
    case meet.ActionTypes.REMOVE_SELECTED_FILTER:
    case meet.ActionTypes.UPDATE_BUTTON_CHIP_ATTRIBUTE:
    case meet.ActionTypes.UPDATE_BUTTON_TOGGLE_ATTRIBUTE:
    case meet.ActionTypes.UPDATE_FILTER_DATE_RANGE_OPTION:
    case meet.ActionTypes.UPDATE_SLIDER_VALUE:
    case meet.ActionTypes.UPDATE_SUB_OPTION_ATTRIBUTE:
    case meet.ActionTypes.UPDATE_TWO_VARIABLE_SLIDER_VALUE: {
      return {
        ...state,
        filterDisplayCustomerError: null,
        filterDisplayCustomerLoading: true,
        filterDisplayCustomerPage: 0
      };
    }

    case meet.ActionTypes.UPDATE_FILTER_DATES: {
      const payload = action.payload;

      // Only set loading to true if both a start date and end date are selected
      const filterDisplayCustomerLoading = !!payload.dates.startDate && !!payload.dates.endDate;

      return { ...state, error: null, filterDisplayCustomerLoading };
    }

    case user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS: {
      const location = action.payload;
      const thirdPartyMap = {
        doordash: location.doordash,
        grubhub: location.grubhub,
        ubereats: location.ubereats
      };
      const deliveryEnabled = location.delivery;

      return {
        ...initialState,
        deliveryEnabled,
        selectedTabIndex: state.selectedTabIndex,
        thirdPartyMap
      };
    }

    case user.ActionTypes.USER_DATA_FOUND: {
      const userData = action.payload;
      const selectedLocation = userData.locations[0];

      return {
        ...state,
        deliveryEnabled: selectedLocation.deliveryEnabled
      };
    }

    default: {
      return state;
    }
  }
}

export const getAllSelectedCustomerIds = (state: State) => state.allSelectedCustomerIds;

export const getCampaignName = (state: State) => state.campaignName;

export const getCustomerError = (state: State) => state.customerError;

export const getCustomerLoading = (state: State) => state.customerLoading;

export const getCustomizeSortArray = (state: State) => state.customizeSortArray;

export const getDefaultFilters = (state: State) => state.defaultFilters;

export const getDefaultFiltersError = (state: State) => state.defaultFiltersError;

export const getDefaultFiltersLoading = (state: State) => state.defaultFiltersLoading;

export const getDefaultSort = (state: State) => state.sort;

export const getDefaultSortOrder = (state: State) => state.sortOrder;

export const getDrafts = (state: State) => state.emailTemplates.drafts;

export const getDuplicateRecipients = (state: State) => state.duplicateRecipients;

export const getEmails = (state: State) => state.emailTemplates.templates;

export const getEmailCategories = (state: State) => state.emailTemplates.categories;

export const getEmailsError = (state: State) => state.emailTemplates.error;

export const getEmailsLoading = (state: State) => state.emailTemplates.loading;

export const getEngagementId = (state: State) => state.engagementId;

export const getError = (state: State) => state.error;

export const getEvents = (state: State) => state.eventTemplates.templates;

export const getEventsError = (state: State) => state.eventTemplates.error;

export const getEventsLoading = (state: State) => state.eventTemplates.loading;

export const getExcludedCustomers = (state: State) => state.excludedCustomers;

export const getFilterDisplayCustomerAllCustomerIds = (state: State) => state.filterDisplayCustomerAllCustomerIds;

export const getFilterDisplayCustomerCount = (state: State) => state.filterDisplayCustomerCount;

export const getFilterDisplayCustomerError = (state: State) => state.filterDisplayCustomerError;

export const getFilterDisplayCustomerLimit = (state: State) => state.filterDisplayCustomerLimit;

export const getFilterDisplayCustomerLoading = (state: State) => state.filterDisplayCustomerLoading;

export const getFilterDisplayCustomerNumberOfPages = (state: State) => state.filterDisplayCustomerNumberOfPages;

export const getFilterDisplayCustomerPage = (state: State) => state.filterDisplayCustomerPage;

export const getFilterDisplayCustomers = (state: State) => state.filterDisplayCustomers;

export const getFilterDisplayCustomerSort = (state: State) => state.filterDisplayCustomerSort;

export const getFilterDisplayCustomerSortOrder = (state: State) => state.filterDisplayCustomerSortOrder;

export const getFilterEndDate = (state: State) => state.filterEndDate;

export const getFilterOnBetweenId = (state: State) => state.filterOnBetweenId;

export const getFilterStartDate = (state: State) => state.filterStartDate;

export const getGroupSendOption = (state: State) => state.isDynamicGroupSend;

export const getHoldoutRecipients = (state: State) => state.holdOutRecipients;

export const getIncludedCustomers = (state: State) => state.includedCustomers;

export const getIsCompleted = (state: State) => state.isCompleted;

export const getLoading = (state: State) => state.loading;

export const getMode = (state: State) => state.mode;

export const getOverlappingEngagements = (state: State) => state.overlappingEngagements;

export const getRecipientsSearchTerm = (state: State) => state.recipientsSearchTerm;

export const getRewardRecipients = (state: State) => state.offerRecipients;

export const getRewards = (state: State) => state.rewardTemplates;

export const getRewardsError = (state: State) => state.rewardTemplates.error;

export const getRewardsLoading = (state: State) => state.rewardTemplates.loading;

export const getSavedFilters = (state: State) => state.savedFilters;

export const getSavedFiltersError = (state: State) => state.savedFiltersError;

export const getSavedFiltersLoading = (state: State) => state.savedFiltersLoading;

export const getSelectedBonusPointPromotion = (state: State) => state.selectedBonusPointPromotion;

export const getSelectedCommunityCareEvent = (state: State) => state.selectedCommunityCareEvent;

export const getSelectedCustomerLoading = (state: State) => state.selectedCustomerLoading;

export const getSelectedCustomers = (state: State) => state.selectedCustomers;

export const getSelectedDefaultFilters = (state: State) => state.selectedDefaultFilters;

export const getSelectedEmail = (state: State) => state.selectedEmail;

export const getSelectedEvent = (state: State) => state.selectedEvent;

export const getSelectedFreeInCartPromotion = (state: State) => state.selectedFreeInCart;

export const getSelectedIndividualIds = (state: State) => state.selectedIndividualIds;

export const getSelectedSavedFilters = (state: State) => state.selectedSavedFilters;

export const getSelectedTabIndex = (state: State) => state.selectedTabIndex;

export const getSelectedHtmlTabIndex = (state: State) => state.selectedHtmlTabIndex;

export const getSelectedTheme = (state: State) => state.selectedTheme;

export const getSelectedThirdPartyPromotion = (state: State) => state.selectedThirdPartyPromotion;

export const getSelectedTreat = (state: State) => state.selectedTreat;

export const getSendError = (state: State) => state.sendError;

export const getSendLoading = (state: State) => state.sendLoading;

export const getStepperProgress = (state: State) => state.sendProgress;

export const getStockImages = (state: State) => state.stockImages;

export const getThemes = (state: State) => state.themes;

export const getType = (state: State) => state.type;

export const getUpdated = (state: State) => state.updated;

export const shouldGetAllCustomers = (state: State) => state.getAllCustomers;
